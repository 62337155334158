import { BB8Button } from "bb8";
import React from "react";
import clouds from "./NotFound.jpg";

const Unauthorized = (props: any) => {
  return (
    <main
      style={{
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        alignItems: "center",
        backgroundImage: `url(${clouds}`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "flex",
        flexFlow: "column wrap",
        justifyContent: "center",
        overflow: "hidden",
        width: "100%"
      }}
    >
      <h1 style={{ color: "#fff", fontSize: "150px", marginBottom: "2rem" }}>
        401
      </h1>
      <h2 style={{ color: "#fff", fontSize: "64px" }}>Unauthorized</h2>
      <p style={{ color: "#fff" }}>You are not authorized to view this page.</p>
      <p style={{ color: "#fff" }}>
        Check with your administrator if you have access to view the page you
        requested.
      </p>
      <BB8Button
        color="primary"
        variant="contained"
        onClick={() => history.back()}
      >
        Back
      </BB8Button>
    </main>
  );
};

export default Unauthorized;
