import {
  IOfferFilter,
  OffersRequestParams
} from "../../../models/OffersRequestParams";
import apiService, { IApiService } from "../../../shared/services/api.service";

import { IBulkJob } from "../../../models/BulkJob";
import { IDeleteOffersResponse } from "../../../models/IDeleteOffersResponse";
import { IGetOffersResponse } from "../../../models/IGetOffersResponse";
import { IUploadBulkOfferResponse } from "../../../models/IUploadBulkOfferResponse";
import { OffersTabs } from "../actions";
import {
  OfferStatus,
  IOfferFormModel,
  OfferDateContext
} from "../../../validation/validator";
import _, { cloneDeep } from "lodash";
import { urls } from "../../../shared/constants";
import { IGenerateContentResponse } from "../../../models/IGenerateContentResponse";
import {
  IOfferCategoryResponse,
  IOffersCounts
} from "../../../models/Category";
import { IOfferPromotionResponse } from "../../../models/Promotion";
import * as uuid from "uuid";

export class OfferService {
  private apiService: IApiService;

  constructor(apiServiceDep?: any) {
    this.apiService = apiServiceDep || apiService;
  }

  public callBulkUpdate(bulkId: any, bulkObject: any) {
    const request: Request = new Request(
      urls.offersController.updateBulkOffers + "/" + bulkId,
      {
        ...this.apiService.getDefaultRequestInit(),
        body: JSON.stringify(bulkObject),
        method: "PATCH"
      }
    );

    return this.apiService.ajax(request);
  }

  public callUploadOffer(requestBody: any): Promise<IUploadBulkOfferResponse> {
    const request: Request = new Request(urls.offersController.uploadOffers, {
      ...this.apiService.getDefaultRequestInit(),
      body: JSON.stringify(requestBody),
      method: "POST"
    });

    return this.apiService.ajax<IUploadBulkOfferResponse>(request);
  }

  public putOffer(offer: Partial<IOfferFormModel>): Promise<any> {
    const request: Request = new Request(
      urls.offersController.postOffer + "/" + offer.id,
      {
        ...this.apiService.getDefaultRequestInit(),
        body: JSON.stringify(this.modifyOffer(offer)),
        method: "PUT"
      }
    );

    return this.apiService.ajax<any>(request);
  }

  public postOffers(offer: Partial<IOfferFormModel>): Promise<any> {
    const request: Request = new Request(urls.offersController.postOffer, {
      ...this.apiService.getDefaultRequestInit(),
      body: JSON.stringify(this.modifyOffer(offer)),
      method: "POST"
    });

    return this.apiService.ajax<any>(request);
  }

  public getBulkJobs(): Promise<IBulkJob[]> {
    return this.apiService.ajax<IBulkJob[]>(urls.offersController.getBulkJobs);
  }

  public getBulkJobById(bulkId: string): Promise<IBulkJob> {
    return this.apiService.ajax<IBulkJob>(
      urls.offersController.getBulkJobs + "/" + bulkId
    );
  }

  public getOffers(
    request: IOfferFilter,
    tab?: OffersTabs
  ): Promise<IGetOffersResponse> {
    let newParams: IOfferFilter;

    switch (tab) {
      case "draft": {
        newParams = { ...request,
          status: OfferStatus.Draft,
          active: true
        };
        break;
      }
      case "changesPending": {
        newParams = { ...request,
          status: OfferStatus.Updated,
          active: true
        };
        break;
      }
      case "staged": {
        newParams = {
          ...request,
          dateContext: OfferDateContext.Staged,
          status: OfferStatus.Published,
          active: true
        };
        break;
      }
      case "live": {
        newParams = {
          ...request,
          dateContext: OfferDateContext.Live,
          status: OfferStatus.Published,
          active: true
        };
        break;
      }
      case "expired": {
        newParams = {
          ...request,
          dateContext: OfferDateContext.Expired,
          status: OfferStatus.Published
        };
        break;
      }
      case "disabled": {
        newParams = {
          ...request,
          active: false
        };
        break;
      }
      default: {
        newParams = request;
      }
    }

    // The page number is being modified while sending the request as the pagination
    // in the backend starts from the 1st index.
    const newParamsCopy = Object.assign({}, newParams);
    newParamsCopy.page = newParamsCopy.page || 0;

    return this.apiService.ajax<IGetOffersResponse>(
      urls.offersController.offers +
        "?" +
        new OffersRequestParams(newParamsCopy).toQueryString()
    );
  }

  public async getOffersTabsCounts(
    requestArg: IOfferFilter
  ): Promise<IOffersCounts> {
    const request: Request = new Request(
      urls.offersController.getOffersCounts +
        "?" +
        new OffersRequestParams(requestArg).toQueryString(),
      {
        ...this.apiService.getDefaultRequestInit()
      }
    );

    return await this.apiService.ajax<any>(request);
  }

  public bulkPublishOffers(
    offers: IOfferFormModel[]
  ): Promise<IOfferFormModel[]> {
    const body = {
      id: _.map(offers, "id").join(",")
    };

    const request: Request = new Request(
      urls.offersController.bulkPublishOffers,
      {
        ...this.apiService.getDefaultRequestInit(),
        body: JSON.stringify(body),
        method: "POST"
      }
    );

    return this.apiService.ajax<IOfferFormModel[]>(request);
  }

  public bulkSingleOffer(offer: IOfferFormModel): Promise<IOfferFormModel[]> {
    const body = {
      id: offer.id
    };

    const request: Request = new Request(urls.offersController.publishOffer, {
      ...this.apiService.getDefaultRequestInit(),
      body: JSON.stringify(body),
      method: "POST"
    });

    return this.apiService.ajax<IOfferFormModel[]>(request);
  }

  public getPreviewOffers(queryString: string): Promise<IGetOffersResponse> {
    const request: Request = new Request(
      urls.offersController.offers + queryString,
      {
        ...this.apiService.getDefaultRequestInit()
      }
    );

    return this.apiService.ajax<any>(request);
  }

  public getOfferContent(
    offer: Partial<IOfferFormModel>
  ): Promise<IGenerateContentResponse> {
    const request: Request = new Request(
      urls.offersController.generateContent,
      {
        ...this.apiService.getDefaultRequestInit(),
        body: JSON.stringify(offer),
        method: "POST"
      }
    );

    return this.apiService.ajax<any>(request);
  }

  public deleteOffers(offerIds: string[]): Promise<IDeleteOffersResponse> {
    const request: Request = new Request(urls.offersController.deleteOffers, {
      ...this.apiService.getDefaultRequestInit(),
      body: JSON.stringify(offerIds),
      method: "POST"
    });

    return this.apiService.ajax<any>(request);
  }

  public disableOffers(offerId: string): Promise<IOfferFormModel> {
    const body = {
      id: offerId
    };

    const request: Request = new Request(urls.offersController.disableOffer, {
      ...this.apiService.getDefaultRequestInit(),
      body: JSON.stringify(body),
      method: "POST"
    });

    return this.apiService.ajax<any>(request);
  }

  public enableOffers(offerId: string): Promise<IOfferFormModel> {
    const body = {
      id: offerId
    };

    const request: Request = new Request(
      urls.offersController.enableOffer,
      {
        ...this.apiService.getDefaultRequestInit(),
        body: JSON.stringify(body),
        method: "POST"
      }
    );

    return this.apiService.ajax<any>(request);
  }
  public fetchCategories(): Promise<IOfferCategoryResponse> {
    const request: Request = new Request(urls.offersController.getCategories, {
      ...this.apiService.getDefaultRequestInit(),
      method: "GET"
    });
    return this.apiService.ajax<any>(request);
  }

  public fetchPromotions(): Promise<IOfferPromotionResponse> {
    const request: Request = new Request(urls.offersController.getPromotions, {
      ...this.apiService.getDefaultRequestInit(),
      method: "GET"
    });
    return this.apiService.ajax<any>(request);
  }

  public async getOffersByIdList(
    offerIds: string[]
  ): Promise<IOfferFormModel[]> {
    const queryString = `?id=${offerIds.join(",")}`;

    return this.getPaginatedPreviewOffers(queryString);
  }

  public async getPaginatedPreviewOffers(
    queryString: string
  ): Promise<IOfferFormModel[]> {
    const allOffers: IOfferFormModel[] = [];

    const initialResponse: IGetOffersResponse = await this.getPreviewOffers(
      queryString
    );

    allOffers.push(...initialResponse.content);

    // Paginate infinitely until we get all of the content so we can display it.
    // This uses the response data (totalCount and pageSize) from the initial request
    // to calculate the amount of times it needs to make the request
    if (initialResponse.content.length < initialResponse.totalCount) {
      for (
        let page = 1;
        page < Math.ceil(initialResponse.totalCount / initialResponse.pageSize);
        page++
      ) {
        const additionalContent: IOfferFormModel[] = (
          await this.getPreviewOffers(queryString + "&page=" + page)
        ).content;

        allOffers.push(...additionalContent);
      }
    }

    return allOffers;
  }

  public async getTargetedOffers(
    requestBody: {[key:string]:string}
  ): Promise<any> {
    requestBody.correlationId = uuid.v4()

    const request: Request = new Request(urls.offersController.targetedOffers, {
      ...this.apiService.getDefaultRequestInit(),
      body: JSON.stringify(requestBody),
      method: "POST",
    });

    return this.apiService.ajax<any>(request);
  }

  private modifyOffer(offer: Partial<IOfferFormModel>) {
    let offerReference;
    if (offer.eventBasedOffer) {
      offerReference = cloneDeep(offer);

      delete offerReference.startDate;
      delete offerReference.displayDate;
      delete offerReference.endDate;
    } else {
      offerReference = offer;
    }

    return offerReference;
  }
}

export default new OfferService();
