import React from "react";
import "./EditPageLayout.scss";

class EditPageLayout extends React.Component {
  public static EntityName = (props: any) => {
    return <div className={"entity-name"}>{props.children}</div>;
  };
  public static EntityDetails = (props: any) => {
    return <div className={"entity-details"}>{props.children}</div>;
  };
  public static TabContainer = (props: any) => {
    return <div className={"tab-container"}>{props.children}</div>;
  };

  public render() {
    const { children } = this.props;
    return (
      <div className={"grid-container"}>
        {React.Children.map(children, child => {
          return child;
        })}
      </div>
    );
  }
}

export default EditPageLayout;
