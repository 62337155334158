import classNames from 'classnames'
import React from 'react'

import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const targetAlertMessageStyles = () => ({
    textRed: {
      color: "#DB141B"
    },
    textRedAnchor: {
      color: "#DB141B",
      textDecoration: "underline"
    },
    alertTitle: {
      paddingBottom: "10px"
    }
});

function TargetAlertMessage(props: any) {
  const { classes } = props;
  return (
    <div>
        <Typography
          className={classNames(classes.textRed, classes.alertTitle)}
          variant="subtitle2"
          align="left"
        >
          WAIT! Target Audience needs to be set up. Check next steps <a href="https://loyaltyone.box.com/s/ihl9rkfar8475poazagqqg9ptjm2zlad" target="_blank" className={classes.textRedAnchor}>here</a>
        </Typography>
        <Typography
          className={classes.textPrimary}
          variant="subtitle2"
          align="left"
        >
        Once you submit the offer in MET, you will receive an email from a Campaign Analyst notifying you when you are able to verify the targets for your offer within 5 business days of your submission. Once it's ready, <span className={classes.textRed}>verify the targets <a href="/offer-submission/targeted-offer-preview" target="_self" className={classes.textRedAnchor}>here.</a></span>
        </Typography>
    </div>
  )
}

export default withStyles(targetAlertMessageStyles)(TargetAlertMessage)
