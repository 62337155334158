import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Grid } from "@material-ui/core";
import { BB8MainPage } from "bb8";
import {
  IRootState,
  withAuthStateToProps,
  withAuthDispatchToProps
} from "../../../store";
import clouds from "./AlmostThere.jpg";

const Unassigned = (props: any) => {
  const { logout } = props;
  return (
    <main
      style={{
        alignItems: "center",
        backgroundImage: `url(${clouds}`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "flex",
        flexFlow: "column wrap",
        height: "60vw",
        justifyContent: "flex-start",
        paddingTop: "3em",
        overflow: "hidden",
        width: "100%"
      }}
    >
      <BB8MainPage.Padded>
        <Grid container={true} direction="column">
          <h1 className="text-color-white mb-1">Just one more step!</h1>
          <Grid item={true} md={6}>
            <p className="text-color-white line-height-spread-md">
              Please send an email to Service Desk at{" "}
              <a
                href="mailto:ServiceDesk@loyalty.com"
                className="text-color-white text-underline"
              >
                ServiceDesk@loyalty.com
              </a>{" "}
              and ask them to finish setting up your POST access. Indicate in the email that this is for POST.
            </p>
          </Grid>
        </Grid>
      </BB8MainPage.Padded>
    </main>
  );
};

const mapStateToProps = (state: IRootState) => withAuthStateToProps(state);

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...withAuthDispatchToProps(dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Unassigned);
