import { get } from "lodash";
import { IBulkFormValues } from "./components/BulkFileUploadForm";
import {
  IOfferFormModel,
  IOfferFormModelWithNames,
  ILocalizedObject
} from "../../validation/validator";
import { IOfferPromotion, getPromoTagName } from "../../models/Promotion";
import { IOfferCategory } from "../../models/Category";
import { getCategoryNameFromValue } from "./utils/categories";
import moment from "moment-timezone";

export function getZIPFile(values: IBulkFormValues) {
  return values.files.find(
    file => get(file, "extension", "").toLowerCase() === "zip"
  );
}
export function getCSVFile(values: IBulkFormValues) {
  return values.files.find(
    file => get(file, "extension", "").toLowerCase() === "csv"
  );
}

// Transform offers to a type containing readable names for id specific fields
export function getOffersWithReadableNames(
  offers: IOfferFormModel[],
  promotions?: IOfferPromotion[],
  categories?: IOfferCategory[]
): IOfferFormModelWithNames[] {
  return offers.map((offer: IOfferFormModel) => {
    let tagNames: any[] = [];
    let offerCategoriesNames: object = {};

    if (promotions && offer.tags) {
      tagNames = offer.tags.map((tag: string) =>
        getPromoTagName(promotions, tag)
      );
    }

    if (categories) {
      if (offer.offerCategory1) {
        const offerCategory1Name = getCategoryNameFromValue(
          categories,
          offer.offerCategory1,
          true
        );
        offerCategoriesNames = { ...offerCategoriesNames, offerCategory1Name };

        if (offer.offerCategory2) {
          const offerCategory2Name = getCategoryNameFromValue(
            categories,
            offer.offerCategory2,
            true
          );

          offerCategoriesNames = {
            ...offerCategoriesNames,
            offerCategory2Name
          };

          if (offer.offerCategory3) {
            const offerCategory3Name = getCategoryNameFromValue(
              categories,
              offer.offerCategory3,
              true
            );
            offerCategoriesNames = {
              ...offerCategoriesNames,
              offerCategory3Name
            };
          }
        }
      }
    }
    return {
      ...offer,
      tagNames,
      ...offerCategoriesNames
    };
  });
}

// Remove values from the offer object to make it match the IOfferFormModel that is used with HTTP requests
export function removeReadableNamesFromOffers(
  offers: IOfferFormModelWithNames[]
): IOfferFormModel[] {
  return offers.map((offer: IOfferFormModelWithNames) => {
    delete offer.tagNames;
    delete offer.offerCategory1Name;
    delete offer.offerCategory2Name;
    delete offer.offerCategory3Name;
    return offer;
  });
}

export const DATE_TIME_FORMAT = "MM/DD/YYYY HH:mm";
export const DATE_TIME_SECONDS_FORMAT = "MM/DD/YY h:mm A";
export const DATE_FULL_YEAR_TIME_SECONDS_FORMAT =  "MM/DD/YYYY hh:mm A";

export function getLocalTime(date: string, format: string): string {
  return moment(date).format(format); 
}

export function getOfferFormattedDate(date: string | Date, lang: string) {
  const isFirst = moment(date).day() === 0;
  const enFormat = "MMM DD, YYYY";
  const frFormat = `DD MMM YYYY`;
  const formatString =
      lang === 'fr' || lang === 'fr-CA' ? frFormat : enFormat;
  const str =  moment
    .utc(date)
    .locale(lang)
    .format(formatString);
  return str
    .split('.')
    .join('')
    .split('1er')
    .join('1<sup>er</sup>');
}
