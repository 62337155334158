import {
  Card,
  CardContent,
  CardMedia,
  createStyles,
  Theme,
  Typography,
  withStyles,
  WithStyles
} from "@material-ui/core";
import React, { PureComponent } from "react";
import { IRbacUser } from "../../../models/User";

interface IUserCardProps extends WithStyles {
  user: IRbacUser;
}

const styles = createStyles((theme: Theme) => ({
  card: {
    borderRadius: 0,
    marginBottom: theme.spacing.unit * 4,
    maxWidth: 345
  },
  media: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    height: 200,
    justifyContent: "center",
    width: "auto"
  },
  svg: {
    height: "auto",
    margin: 10,
    width: "75%"
  }
}));

class UserCard extends PureComponent<IUserCardProps> {
  public render() {
    const { classes, user } = this.props;
    return (
      <Card className={classes.card}>
        <CardContent>
          <CardMedia
            className={classes.media}
            image={require("./avatar.svg")}
          />
          <Typography gutterBottom={true} variant="h5" component="h2">
            {user.fullName}
          </Typography>
          <Typography component="p">
            <a href={`${user.id}`}>{user.id}</a>
          </Typography>
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(UserCard);
