import React, { Component } from "react";
import { Redirect } from "react-router";
import Unauthorized from "../pages/Unauthorized";
import Unassigned from "../pages/Unassigned";
import { Authorizer } from "./Authorizer";

interface IWithAuthorizationProps {
  allowedPermissions: string[];
}

export default (
  WrappedComponent: any,
  permissions: string[]
): React.FunctionComponent<IWithAuthorizationProps> => {
  const WithAuthorization = ({ ...props }) => {
    const isAllowed = Authorizer.Instance.checkPermissions(
      permissions,
      props.user
    );
    const hasAssignedRoles = Authorizer.Instance.isAssignedToRole(
      ["Unassigned"],
      props.user
    );

    if (isAllowed && hasAssignedRoles) {
      return <WrappedComponent {...props} />;
    } else if (!hasAssignedRoles) {
      return <Unassigned />;
    }
    return <Unauthorized />;
  };
  return WithAuthorization;
};
