import { getType } from "typesafe-actions";
import * as loginActions from "./actions";
import { IAuthState } from "./types";

export default function(
  state: IAuthState = {
    user: undefined
  },
  action: loginActions.loginActionTypes
): IAuthState {
  switch (action.type) {
    case getType(loginActions.fetchLogin.success): {
      return { ...state, user: action.payload };
    }
    case getType(loginActions.fetchLogout.success): {
      return { ...state, user: undefined };
    }
    case getType(loginActions.fetchPermissionsConfig.success): {
      return { ...state, permissionConfig: action.payload };
    }
    default:
      return state;
  }
}
