import {
  CircularProgress,
  createStyles,
  Theme,
  WithStyles,
  withStyles
} from "@material-ui/core";
import {
  FormHelperText,
  InputAdornment,
  MenuItem,
  TextField
} from "@material-ui/core";
import { BaseTextFieldProps } from "@material-ui/core/TextField";
import classNames from "classnames";
import { Field, FieldProps } from "formik";
import React from "react";
import { Omit } from "utility-types";
export interface IBB8FieldProps extends BaseTextFieldProps {
  error?: any;
  status?: any;
  touched?: boolean;
  placeholder?: string;
  validate?: any;
  value?: any;
}

interface IBB8FormFieldProps extends Omit<IBB8FieldProps, "classes"> {
  ranges?: any[];
  required?: boolean;
  type?: string;
  validAdornment?: boolean;
  loadingAdornment?: boolean;
  maxLength?: number;
  autoFocus?: boolean;
  loading?: boolean;
  inputProps?: { classes?: any };
}

export const BB8InnerFormField: React.SFC<
  IBB8FormFieldProps & WithStyles
> = props => {
  const {
    ranges,
    maxLength,
    classes,
    error,
    validAdornment,
    label,
    loading,
    loadingAdornment,
    inputProps,
    ...fieldProps
  } = props;
  if (classes) {
    return (
      <div className={classes.root} style={{ flexDirection: "column" }}>
        <FormHelperText className={classes.fieldLabel} error={!!error}>
          {label}
        </FormHelperText>
        <TextField
          {...fieldProps}
          className={classNames(classes.textField)}
          variant="filled"
          helperText={error}
          error={!!error}
          select={!!ranges}
          InputProps={{
            classes: inputProps
              ? inputProps.classes
              : {
                  disabled: classes.cssInputDisabled,
                  focused: classes.cssInputFocused,
                  input: classes.cssFilledInput,
                  root: classes.cssInputRoot
                },
            ...TextFieldAdornments(
              validAdornment,
              loadingAdornment,
              loading,
              !error
            ),
            disableUnderline: true
          }}
          inputProps={{
            autoCapitalize: "on",
            maxLength
          }}
          FormHelperTextProps={{
            classes: { contained: classes.formTextHelper }
          }}
        >
          {props.ranges &&
            props.ranges.map((option: any) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
        </TextField>
      </div>
    );
  }
  return null;
};

function TextFieldAdornments(
  validAdornment?: boolean,
  loadingAdornment?: boolean,
  loading?: boolean,
  valid?: boolean
) {
  if (loadingAdornment && loading) {
    return {
      endAdornment: (
        <InputAdornment position="end">
          <CircularProgress />
        </InputAdornment>
      )
    };
  }
  if (validAdornment && valid) {
    return {
      endAdornment: (
        <InputAdornment position="end">
          {/* <img alt="checkmark-success" src={checkmark} /> */}
        </InputAdornment>
      )
    };
  }
  return {};
}

export const formFieldStyles = createStyles((theme: Theme) => ({
  cssFilledInput: {
    background: "transparent",
    boxSizing: "border-box",
    height: "3.375em",
    padding: "0 1em 0 1em",
    borderRadius: theme.shape.borderRadius
  },
  cssInputFocused: {
    outline: "#61c1ee solid 3px",
    outlineOffset: "-3px"
  },
  cssInputDisabled: {
    cursor: "not-allowed"
  },
  cssInputRoot: {
    borderRadius: theme.shape.borderRadius,
    background: "#f1f2f2",
    fontSize: "16px"
  },
  cssUnderline: {
    "&:before": {
      display: "none"
    }
  },
  fieldLabel: {
    margin: `${theme.spacing.unit}px 0`
  },
  formTextHelper: {
    margin: `${theme.spacing.unit}px 0`
  },
  textField: {
    marginRight: 2 * theme.spacing.unit
  }
}));

const StyledInnerFormField = withStyles(formFieldStyles)(BB8InnerFormField);

/**
 * This component wraps BB8Form Field with Formik Field component. For more details
 * please check {@link https://jaredpalmer.com/formik/docs/api/field}
 * @param props Form field props
 */
const BB8FormField = (props: IBB8FormFieldProps) => (
  <Field name={props.name} validate={props.validate}>
    {({ field, form }: FieldProps) => {
      return (
        <StyledInnerFormField
          {...props}
          {...field}
          // triggers formik onChange and also the passed in onChange from the caller
          onChange={e => {
            field.onChange(e);
            if (props.onChange) {
              props.onChange(e);
            }
          }}
          error={
            props.error || (form.touched[field.name] && form.errors[field.name])
          }
          value={props.value || (form.values && form.values[field.name])}
        />
      );
    }}
  </Field>
);

export default BB8FormField;
