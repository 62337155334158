import { ICommunicationState } from "../../../models/Communication";
import { adminActions } from "../../administration";
import * as offersActions from "../../offer-submission/actions";
import * as communicationActions from "../actions";

export const initialState: ICommunicationState = {
  modalContent: undefined,
  pendingRequests: 0,
  showTargetAlert: false,
};

const communicationReducer = (
  state: ICommunicationState = initialState,
  action:
    | communicationActions.communicationActionTypes
    | adminActions.AdministrationActionType
    | offersActions.OffersActionType
): ICommunicationState => {
  switch (action.type) {
    case communicationActions.displayModalActionType: {
      return { ...state, modalContent: action.payload };
    }
    case communicationActions.dismissModalActionType: {
      return {
        ...state,
        modalContent: undefined
      };
    }
    case communicationActions.showTargetAlertActionType: {
      return {
        ...state,
        showTargetAlert: true
      };
    }
    case communicationActions.hideTargetAlertActionType: {
      return {
        ...state,
        showTargetAlert: false
      };
    }

    case adminActions.fetchRolesRequest:
    case adminActions.fetchAddPermissionToRoleRequest:
    case adminActions.fetchRemovePermissionToRoleRequest:
    case adminActions.fetchAddGroupRequest:
    case adminActions.removePartnerFromGroupRequest:
    case adminActions.saveRoleRequest:
    case adminActions.fetchPartnersRequest:
    case adminActions.fetchPermissionRequest:
    case adminActions.fetchGroupsRequest:
    case adminActions.fetchUsersRequest:
    case offersActions.fetchOffersRequest:
    case offersActions.fetchCategoriesRequest:
    case offersActions.fetchPromotionsRequest:
    case offersActions.deleteOffersRequest:
    {
      return { ...state, pendingRequests: state.pendingRequests + 1 };
    }
    case adminActions.fetchRolesSuccess:
    case adminActions.fetchAddGroupSuccess:
    case adminActions.removePartnerFromGroupSuccess:
    case adminActions.fetchPartnersSuccess:
    case adminActions.fetchPermissionSuccess:
    case adminActions.fetchGroupsSuccess:
    case adminActions.fetchUsersSuccess:
    case offersActions.fetchOffersSuccess:
    case offersActions.fetchCategoriesSuccess:
    case offersActions.fetchPromotionsSuccess: {
      return {
        ...state,
        pendingRequests: Math.max(state.pendingRequests - 1, 0)
      };
    }
    case adminActions.fetchRolesError:
    case adminActions.fetchAddPermissionToRoleError:
    case adminActions.fetchRemovePermissionToRoleError:
    case adminActions.removePartnerFromGroupError:
    case adminActions.fetchAddGroupError:
    case adminActions.saveRoleError:
    case adminActions.fetchPartnersError:
    case adminActions.fetchPermissionError:
    case adminActions.fetchGroupsError:
    case adminActions.fetchUsersError:
    case offersActions.fetchOffersError:
    case offersActions.publishOffersError:
    case offersActions.deleteOffersError:
    case offersActions.disableOffersError:
    case offersActions.enableOffersError: {
      return {
        ...state,
        alertContent: { variant: "error", message: action.payload.message },
        pendingRequests: 0
      };
    }
    case offersActions.fetchBulkJobsError:
    case offersActions.fetchCategoriesError:
    case offersActions.fetchPromotionsError: {
      return {
        ...state,
        alertContent: { variant: "error", message: action.payload.message },
        pendingRequests: Math.max(state.pendingRequests - 1, 0)
      };
    }
    case adminActions.saveRoleSuccess: {
      return {
        ...state,
        alertContent: {
          message: `Added new role ${action.payload.name}`,
          variant: "success"
        },
        pendingRequests: Math.max(state.pendingRequests - 1, 0)
      };
    }
    case adminActions.fetchAddPermissionToRoleSuccess: {
      return {
        ...state,
        alertContent: {
          message: `Added ${action.payload.permission.name} to role ${
            action.payload.role.name
          }`,
          variant: "success"
        },
        pendingRequests: Math.max(state.pendingRequests - 1, 0)
      };
    }
    case adminActions.fetchRemovePermissionToRoleSuccess: {
      return {
        ...state,
        alertContent: {
          message: `Removed ${action.payload.permission.name}`,
          variant: "success"
        },
        pendingRequests: Math.max(state.pendingRequests - 1, 0)
      };
    }
    case adminActions.saveUserRoleSuccess: {
      return {
        ...state,
        alertContent: {
          message: action.payload.role
            ? `Assigned role "${action.payload.role.name}" to "${
                action.payload.fullName
              }"`
            : `Unassigned ${action.payload.fullName} from any roles`,
          variant: "success"
        },
        pendingRequests: Math.max(state.pendingRequests - 1, 0)
      };
    }
    case offersActions.deleteOffersSuccess: {
      return {
        ...state,
        alertContent: {
          message: `Deleted ${action.payload.deletedOffers.length} ${
            action.payload.deletedOffers.length === 1 ? "offer" : "offers"
          }`,
          variant: "success"
        },
        pendingRequests: Math.max(state.pendingRequests - 1, 0)
      };
    }
    case offersActions.disableOffersSuccess: {
      return {
        ...state,
        alertContent: {
          message: `Disabled ${action.payload.disabledOffers} of ${
            action.payload.totalOffers
          } ${action.payload.totalOffers === 1 ? "offer" : "offers"}`,
          variant: "success"
        },
        pendingRequests: Math.max(state.pendingRequests - 1, 0)
      };
    }
    case offersActions.enableOffersSuccess: {
      return {
        ...state,
        alertContent: {
          message: `Enabled ${action.payload.enabledOffers} of ${
            action.payload.totalOffers
          } ${action.payload.totalOffers === 1 ? "offer" : "offers"}`,
          variant: "success"
        },
        pendingRequests: Math.max(state.pendingRequests - 1, 0)
      };
    }
    case offersActions.disableOffersRequest:
    case offersActions.enableOffersRequest:
    case offersActions.publishOffersRequest: {
      return {
        ...state,
        pendingRequests: state.pendingRequests + action.payload.length
      };
    }
    case offersActions.publishOffersSuccess: {
      return {
        ...state,
        alertContent: {
          message: `Published ${action.payload.publishedOffers} of ${
            action.payload.totalOffers
          } ${action.payload.totalOffers === 1 ? "offer" : "offers"}`,
          variant: "success"
        },
        pendingRequests: Math.max(state.pendingRequests - 1, 0)
      };
    }
    default:
      return state;
  }
};

export default communicationReducer;
