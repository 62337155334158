import {
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Tooltip,
  Typography
} from "@material-ui/core";
import { BB8Button, BB8MainPage, BB8SystemType } from "bb8";
import { IColProps } from "bb8/lib/components/BB8Table";
import { chain, get, isEmpty } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Link, RouteComponentProps } from "react-router-dom";
import EditContentTabs from "../../../components/EditContentTabs";
import EditPageLayout from "../../../components/EditPageLayout";
import { IWithSearchProps } from "../../../components/WithSearch";
import { IGroup, IHaveName, IHavePartners } from "../../../models/Group";
import { IPartner } from "../../../models/Partner";
import { IRbacUser } from "../../../models/User";
import { IRootState, withAuthStateToProps } from "../../../store";
import withAuthorization from "../../auth/components/Authorization";
import * as adminActions from "../actions";
import { getGroupsWithUsers } from "../selectors";
import "./GroupsEditPage.scss";
import { getHumanReadableTimeFromNow } from "../../../shared/helpers";
import { getLocalTime, DATE_TIME_FORMAT } from "../../offer-submission/utils";

interface IGroupEditPage
  extends IWithSearchProps,
    RouteComponentProps<{ groupId: string }> {
  groups: IGroup[];
  partners: IPartner[];

  // groupUserMapping: Array<{ userId: string; groupId: number }>;

  fetchPartners: () => void;
  fetchGroup: (groupId: string) => Promise<any>;
  fetchUsersInGroup: (groupId: string) => void;

  onAddPartnerToGroup: (group: IGroup, partner: IPartner) => Promise<any>;
  onRemovePartnerFromGroup: (group: IGroup, partner: IPartner) => void;
  onRemoveGroupFromGroupList: (groups: IGroup[], group: IGroup) => void;
}

export class GroupEditPage extends React.PureComponent<IGroupEditPage> {
  public state = {
    partnerSearch: ""
  };
  public componentDidMount = () => {
    this.props.fetchGroup(this.props.match.params.groupId).then(() => {
      this.props.fetchUsersInGroup(this.props.match.params.groupId);
      this.props.fetchPartners();
    });
  };

  public render() {
    const {
      groups,
      match: {
        params: { groupId }
      }
    } = this.props;

    const group: IGroup | undefined = groups.find(
      g => g.id.toString() === groupId
    );

    if (!group) {
      return null;
    }

    const partnersInGroup: IPartner[] = group.partners ? group.partners : [];
    const partnersTableData = getPartnersTableData(
      partnersInGroup,
      this.handleRemovePartner(group)
    );
    const partnerSearchBar = getSearchBar(
      group,
      getSuggestions(this.state.partnerSearch, this.props.partners),
      this.onInputChange,
      this.onInputBlur,
      renderPartnerLookupOption,
      this.handleAddPartnerToGroup,
      this.state.partnerSearch
    );

    const usersTableData = this.getUsersTableData(
      group.users ? group.users : []
    );

    const tabInfo = [
      {
        label: "Partners",
        searchBar: partnerSearchBar,
        tableData: partnersTableData
      },
      {
        label: "Users",
        tableData: usersTableData
      }
    ];

    return (
      <BB8MainPage type={BB8SystemType.Billing}>
        <BB8MainPage.Padded>
          <EditPageLayout>
            <EditPageLayout.EntityName>
              <Typography variant="h5">Group Details</Typography>
              <Typography paragraph={true} gutterBottom={true}>
                <Link to={`/user-management/groups`}>
                  &larr; Back to Group List
                </Link>
              </Typography>
            </EditPageLayout.EntityName>
            <EditPageLayout.EntityDetails>
              <Card className="">
                <CardContent>
                  <Typography
                    variant="h6"
                    component="p"
                    className=""
                    gutterBottom={true}
                  >
                    Group Name: {group.name}
                  </Typography>
                  <Typography className="" color="textSecondary">
                    Created date:{" "}
                    {group.createdDate
                      ? getLocalTime(group.createdDate, DATE_TIME_FORMAT)
                      : " Unknown"}
                    <br />
                    Number of partners:{" "}
                    {group.partners ? group.partners.length : 0}
                    <br />
                    Number of users: {group.users ? group.users.length : "None"}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    color="primary"
                    variant="outlined"
                    disabled={true}
                    onClick={() =>
                      this.props.onRemoveGroupFromGroupList(groups, group)
                    }
                  >
                    {" Delete this group "}
                  </Button>
                </CardActions>
              </Card>
            </EditPageLayout.EntityDetails>
            <EditPageLayout.TabContainer>
              <EditContentTabs tabInfo={tabInfo} />
            </EditPageLayout.TabContainer>
          </EditPageLayout>
        </BB8MainPage.Padded>
        <BB8MainPage.Padded />
      </BB8MainPage>
    );
  }

  private onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ partnerSearch: e.target.value });
  };

  private onInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (
      isEmpty(e.relatedTarget) ||
      !(e.relatedTarget as any).classList.contains("chip")
    ) {
      this.setState({ partnerSearch: "" });
    }
  };

  private getUsersTableData = (users: IRbacUser[]) => {
    const tableId = "table-tab-content-users";
    const tableHeaders: IColProps[] = [
      {
        id: "fullName",
        title: "User Name"
      },
      {
        id: "id",
        title: "Email"
      },
      {
        id: "role",
        title: "Role"
      },
      {
        id: "lastLoginTs",
        title: "Last Active"
      },
      {
        id: "action",
        title: ""
      }
    ];
    let tableRows: any[] = [];

    if (!isEmpty(users)) {
      tableRows = chain(users.filter((user: IRbacUser) => !isEmpty(user)))
        .sortBy(user => user.fullName.toLowerCase(), ["asc"])
        .map((user: IRbacUser) => ({
          action: (
            <BB8Button
              onClick={this.handleGoToUser(user.id)}
              variant="contained"
              color="primary"
              size="large"
              className={"btn-edit"}
            >
              Edit
            </BB8Button>
          ),
          fullName: (
            <Link to={`/user-management/users/${user.id}`}>
              <Tooltip title={user.fullName}>
                <span>{user.fullName}</span>
              </Tooltip>
            </Link>
          ),
          id: (
            <a href={`mailto:${user.id}`}>
              <Tooltip title={user.id}>
                <span>{user.id}</span>
              </Tooltip>
            </a>
          ),
          lastLoginTs: user.lastLoginTs
            ? getHumanReadableTimeFromNow(user.lastLoginTs)
            : "never",
          role: user.role ? user.role.name : ""
        }))
        .value();
    }

    return { tableId, tableHeaders, tableRows };
  };

  // Partner is as selected from the BB8Lookup
  private handleAddPartnerToGroup = (group: IGroup, partner: IPartner) => {
    this.setState({ partnerSearch: "" });
    this.props.onAddPartnerToGroup(group, partner);
  };

  private handleRemovePartner = (group: IGroup) => (partner: IPartner) => {
    this.props.onRemovePartnerFromGroup(group, partner);
  };

  private handleGoToUser = (userId: string) => () => {
    this.props.history.push(`/user-management/users/${userId}`);
  };
}

const mapStateToProps = (state: IRootState) => ({
  ...withAuthStateToProps(state),
  groups: getGroupsWithUsers(state),
  partners: state.administration.partners
});

const mapDispatchToProps = {
  fetchGroup: adminActions.fetchGroupFlow,
  fetchPartners: adminActions.fetchPartnersFlow,
  fetchUsersInGroup: adminActions.fetchUsersInGroupFlow,
  onAddPartnerToGroup: adminActions.savePartnerToGroupFlow,
  // onRemoveGroupFromGroupList: adminActions.removeGroup,
  onRemovePartnerFromGroup: adminActions.removePartnerFromGroupFlow
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withAuthorization(GroupEditPage, [
    "CREATE_GROUP",
    "EDIT_GROUP",
    "DELETE_GROUP"
  ])
);

/* getPartnersTableData generates the table data in the partners tab on the Edit Pages
This get table data function is used on both the User Edit and Group Edit pages. 
This is because the tables are displaying the same information just for different entities.
*/
export const getPartnersTableData = (
  partners: IPartner[],
  onRemove: (partner: IPartner) => void
) => {
  const tableId = "table-tab-content";
  const tableHeaders: IColProps[] = [
    {
      id: "logo",
      title: "Logo"
    },
    {
      id: "name",
      title: "Name"
    },
    {
      id: "action",
      title: "Remove this Partner"
    }
  ];
  let tableRows: any[] = [];

  if (!isEmpty(partners)) {
    tableRows = chain(partners.filter((partner: IPartner) => !isEmpty(partner)))
      .sortBy(
        (partner: IPartner) => (partner.name ? partner.name.toLowerCase() : ""),
        ["asc"]
      )
      .map((partner: IPartner) => ({
        action: (
          <BB8Button
            onClick={() => onRemove(partner)}
            variant="contained"
            color="primary"
            size="large"
            className={"btn-edit"}
          >
            Remove
          </BB8Button>
        ),
        logo:
          partner.fullLogo && partner.fullLogo.length ? (
            <img
              src={get(partner, "fullLogo[0].file.url")}
              alt={partner.name + " logo"}
              className="logo-wrapper"
            />
          ) : (
            <span className="logo-text"> {partner.name + " logo"} </span>
          ),
        name: partner.name
      }))
      .value();
  }

  return { tableId, tableHeaders, tableRows };
};

export const getGroupsTableData = (
  groups: IGroup[],
  onRemove: (group: IGroup) => void,
  isAdminUser: boolean = false
) => {
  const tableId = "table-tab-content";
  const tableHeaders: IColProps[] = [
    {
      id: "name",
      title: "Group Name"
    },
    {
      id: "partners",
      title: "Partners In Group"
    },
    {
      id: "action",
      title: "Action"
    }
  ];
  let tableRows: any[] = [];

  if (!isEmpty(groups)) {
    tableRows = chain(groups)
      .sortBy(group => group.name.toLowerCase(), ["asc"])
      .map((group: IGroup) => ({
        action:
          group.coversAllPartners && isAdminUser ? (
            ""
          ) : (
            <BB8Button
              onClick={() => onRemove(group)}
              variant="contained"
              color="primary"
              size="large"
              className={"btn-edit"}
            >
              Remove
            </BB8Button>
          ),
        createdDate: group.createdDate,
        name: group.name,
        partners: group.coversAllPartners
          ? "All Partners"
          : getPartnerNames(group.partners)
      }))
      .value();
  }

  return { tableId, tableHeaders, tableRows };
};

export const getSearchBar = <T extends IHavePartners, G>(
  item: T,
  suggestions: G[],
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void,
  renderLookupOption: (
    option: any,
    item: T,
    onAdd: (item: T, partnerOrGroup: G) => void
  ) => void,
  onAddToItem: (item: T, partnerOrGroup: G) => void,
  inputValue: string
) => {
  return {
    id: "partner-search",
    loading: false,
    onInputBlur: onBlur,
    onInputChange: onChange,
    options: suggestions,
    placeholder: `Search by name to add`,
    renderOption: (option: any) =>
      renderLookupOption(option, item, onAddToItem),
    inputValue
  };
};

// Gets an array of partners that match the input string
export const getSuggestions = <T extends IHaveName>(
  input: string,
  items: T[]
) => {
  const inputValue = input.trim().toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;
  const filteredItems =
    inputLength === 0 || items === undefined
      ? // TODO: Default partners to show, return empty array for no partners
        items.filter((suggestion: T) => {
          const keep = count < 20;
          if (keep) {
            count++;
          }
          return keep;
        })
      : items.filter((suggestion: T) => {
          const keep =
            count < 10 &&
            suggestion.name.slice(0, inputLength).toLowerCase() === inputValue;

          if (keep) {
            count++;
          }
          return keep;
        });
  return filteredItems;
};

export const renderPartnerLookupOption = <T extends IHavePartners>(
  option: any,
  item: T,
  onAdd: (item: T, option: any) => void
) => {
  let disabled = false;
  if (!isEmpty(item.partners) && item.partners !== undefined) {
    disabled = item.partners.some((partner: IPartner) =>
      partner && partner.name ? partner.name === option.option.name : false
    );
  }

  return (
    <Chip
      onClick={() => onAdd(item, option.option)}
      label={option.option.name}
      clickable={!disabled}
      color={disabled ? "secondary" : "primary"}
      className="chip"
    />
  );
};

// TODO: customize item type to expect an item as RBacUser with groups or Group with partners
// Then merge renderGroupLookupOption with renderPartnerLookupOption
export const renderGroupLookupOption = (
  option: any,
  item: IRbacUser,
  onAdd: (item: IRbacUser, option: any) => void
) => {
  const disabled =
    !isEmpty(item.groups) && item.groups !== undefined
      ? item.groups.some((group: IGroup) =>
          group && group.name ? group.name === option.option.name : false
        )
      : false;

  return (
    <Chip
      onClick={() => onAdd(item, option.option)}
      label={option.option.name}
      clickable={!disabled}
      color={disabled ? "secondary" : "primary"}
      className="chip"
    />
  );
};

// This function will parse the function names as necessary to display in the table
export const getPartnerNames = (partners?: IPartner[]) => {
  if (!partners) {
    return "New Group, edit group to add partners";
  }

  const partnerString = partners
    .filter(partner => !isEmpty(partner))
    .map(partner => {
      return !partner ? "" : partner.name;
    })
    .join(", ");

  return (
    <Tooltip
      title={partnerString}
      placement="bottom-start"
      className="tooltip-closer"
    >
      <span className="partner-list">{partnerString}</span>
    </Tooltip>
  );
};
