import React from "react";
import { Subtract } from "utility-types";

export interface IWithSearchProps {
  searchString: string;
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSearchClear: () => void;
}
export interface IWithSearchState {
  searchString: string;
}
export const withSearch = <P extends IWithSearchProps>(
  WrappedComponent: React.ComponentType<P>
) => {
  class WithSearch extends React.Component<
    Subtract<P, IWithSearchProps>,
    IWithSearchState
  > {
    public state = {
      searchString: ""
    };

    public render() {
      const { searchString } = this.state;
      return (
        <WrappedComponent
          {...this.props as P}
          searchString={searchString}
          onSearchChange={this.handleOnSearchChange}
          onSearchClear={this.handleOnSearchClear}
        />
      );
    }

    private handleOnSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ searchString: e.target.value });
    };

    private handleOnSearchClear = () => {
      this.setState({ searchString: "" });
    };
  }
  return WithSearch;
};
