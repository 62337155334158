import { isEmpty } from "lodash";
import { createSelector } from "reselect";
import { IRole, IRoleMatrix, RoleAction } from "../../models/Role";
import { IRootState } from "../../store";

const getRoles = (state: IRootState) => state.administration.roles;
const getPermissionConfig = (auth: IRootState) => auth.auth.permissionConfig;

export const getRoleMatrix = createSelector(
  [getRoles, getPermissionConfig],
  (roles, permissionConfig): IRoleMatrix => {
    if (roles && permissionConfig) {
      const rolesWithActions: IRole[] = roles.map(role => {
        const result = {
          ...role,
          actions: (role.rolePermissions || [])
            .map(rolePermission => ({
              ...rolePermission.permission,
              actions: permissionConfig.mappings[
                rolePermission.permission.name
              ] as RoleAction[]
            }))
            .filter(r => !isEmpty(r.actions))
            .reduce((accumulator: RoleAction[], value): RoleAction[] => {
              return [...accumulator, ...value.actions];
            }, [])
        };
        return result;
      });
      return {
        availableActions: permissionConfig ? permissionConfig.actions : [],
        roles: rolesWithActions
      };
    }
    return { availableActions: [], roles: [] };
  }
);
