import * as offersActions from "../actions";

import { IOffersState } from "../types";

export default function(
  state: IOffersState = {
    offers: {
      fetchedOffers: {
        content: [],
        pageNumber: 0,
        pageSize: 100,
        totalCount: 0
      },
      fetchedOffersTabsCounts: {
        draft: 0,
        changesPending: 0,
        staged: 0,
        live: 0,
        expired: 0,
        disabled: 0,
        total: 0
      }
    },
    bulkJobs: [],
    filter: {},
    categories: [],
    promotions: []
  },
  action: offersActions.OffersActionType
): IOffersState {
  switch (action.type) {
    case offersActions.fetchOffersRequest: {
      return { ...state, filter: action.payload };
    }
    case offersActions.fetchOffersSuccess: {
      return { ...state, offers: action.payload };
    }
    case offersActions.fetchBulkJobsSuccess: {
      return { ...state, bulkJobs: action.payload };
    }
    case offersActions.fetchCategoriesSuccess: {
      return { ...state, categories: action.payload };
    }
    case offersActions.fetchPromotionsSuccess: {
      return { ...state, promotions: action.payload };
    }
    default: {
      return state;
    }
  }
}
