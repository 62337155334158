import {
  Card,
  CardContent,
  CardHeader,
  createStyles,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Theme,
  Typography,
  withStyles,
  WithStyles
} from "@material-ui/core";
import React, { PureComponent } from "react";
import { IRole } from "../../../models/Role";

interface IUserCardProps extends WithStyles {
  role: IRole;
  roles: IRole[];
  onRoleChange: (role?: IRole) => void;
  canEditRole: boolean;
}

const styles = createStyles((theme: Theme) => ({
  avatar: {
    height: 80,
    margin: 10,
    width: 80
  },
  card: {
    borderRadius: 0,
    marginBottom: theme.spacing.unit * 4,
    maxWidth: 345
  },
  formControl: {
    width: "100%"
  },
  media: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  },
  svg: {
    height: 60,
    width: 60
  }
}));

class UserCard extends PureComponent<IUserCardProps> {

  public render() {
    const { classes, role, roles, onRoleChange, canEditRole } = this.props;
    return (
      <Card className={classes.card}>
        <CardHeader
          title="Role"
          subheader={
            role ? (
              role.name
            ) : (
              <span style={{ fontStyle: "italic" }}>No role assigned</span>
            )
          }
        />
        {canEditRole && <CardContent>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="outlined-role-simple">Roles</InputLabel>
            <Select
              value={role ? role.id.toString() : ""}
              onChange={this.handleRoleChanged}
              fullWidth={true}
              input={
                <OutlinedInput
                  name="role"
                  id="outlined-role-simple"
                  labelWidth={80}
                />
              }
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {roles
                .filter(r => r.id && r.name)
                .map(r => (
                  <MenuItem key={r.id} value={r.id.toString()}>
                    {r.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </CardContent> }
      </Card>
    );
  }
  private handleRoleChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { roles, onRoleChange } = this.props;
    if (onRoleChange) {
      const { value } = e.target;
      const selectedRole = roles.find(r => r.id.toString() === value);
      onRoleChange(selectedRole);
    }
  };
}

export default withStyles(styles)(UserCard);
