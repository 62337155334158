import * as _ from "lodash";
import { IOfferCategory } from "../../../models/Category";

export function formatCategoryLabel(category: IOfferCategory) {
  return `${category.translations["en-US"]} / ${
    category.translations["fr-CA"]
  }`;
}

export function hasChildCategories(
  categoryValue: IOfferCategory | string,
  categories?: IOfferCategory[]
): boolean {
  if (typeof categoryValue === "string") {
    return !!(
      categories &&
      getChildCategoriesFromCategoryId(categories, categoryValue) &&
      getChildCategoriesFromCategoryId(categories, categoryValue).length > 0
    );
  } else {
    return !!(
      categoryValue &&
      categoryValue.childCategories &&
      categoryValue.childCategories.length > 0
    );
  }
}

export function getCategoryNameFromValue(
  categories: IOfferCategory[],
  categoryId: string,
  onlyEnglish?: boolean
) {
  const childCategories = _.flatten(
    categories.map(cat => cat.childCategories || [])
  );
  const flatCategories = [...categories, ...childCategories];
  const found = _.find(flatCategories, category =>
    category ? category.id === categoryId : false
  );

  if (onlyEnglish) {
    return found ? `${found.translations["en-US"]}` : null;
  }
  return found ? formatCategoryLabel(found) : null;
}

export function getChildCategoriesFromCategoryId(
  categories: IOfferCategory[],
  categoryId: string
) {
  const found = _.find(categories, category => category.id === categoryId);
  return found && found.childCategories ? found.childCategories : [];
}
