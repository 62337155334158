export interface IRoleMatrix {
  roles: IRole[];
  availableActions: string[];
}

export interface IRole {
  id: number;
  name: string;
  createdTs?: Date;
  actions?: RoleAction[];
  users?: any[];
  rolePermissions: IRolePermission[];
}

export interface IRolePermission {
  roleId: number;
  role: IRole;
  permissionId: number;
  permission: IPermission;
  createdTs?: Date;
}

export interface IPermission {
  id: number;
  name: string;
  createdTs?: Date;
}

export enum RoleAction {
  CREATE_OFFER = "CREATE_OFFER",
  EDIT_OFFER = "EDIT_OFFER",
  PUBLISH_OFFER = "PUBLISH_OFFER",
  DELETE_OFFER = "DELETE_OFFER",
  VIEW_OFFER = "VIEW_OFFER",
  DISABLE_OFFER = "DISABLE_OFFER",
  EDIT_USER = "EDIT_USER",
  DISABLE_USER = "DISABLE_USER",
  CREATE_ROLE = "CREATE_ROLE",
  EDIT_ROLE = "EDIT_ROLE",
  DELETE_ROLE = "DELETE_ROLE",
  CREATE_GROUP = "CREATE_GROUP",
  EDIT_GROUP = "EDIT_GROUP",
  DELETE_GROUP = "DELETE_GROUP"
}
