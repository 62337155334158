import { push } from "connected-react-router";
import { Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";
import {
  ActionType,
  createAsyncAction,
  createStandardAction,
  getType
} from "typesafe-actions";
import { IGroup } from "../../../models/Group";
import { IGroupsAndPartners } from "../../../models/GroupsAndPartners";
import { IPartner } from "../../../models/Partner";
import { IPermission, IRole, IRolePermission } from "../../../models/Role";
import { IRbacUser, IUser } from "../../../models/User";
import { ApiError } from "../../../shared/services/ApiError";
import { IRootState } from "../../../store";
import { RoleService } from "../services";
import { UserService } from "../services";
import { GroupService } from "../services";
import PartnerService from "../../../shared/services/PartnerService";

// *ROLES* async actions definition
export const fetchRoles = createAsyncAction(
  "ROLES/FETCH",
  "ROLES/FETCH_SUCCESS",
  "ROLES/FETCH_ERROR"
)<void, IRole[], ApiError>();

// async actions types
export const fetchRolesRequest = getType(fetchRoles.request);
export const fetchRolesSuccess = getType(fetchRoles.success);
export const fetchRolesError = getType(fetchRoles.failure);

// async action creator
export function fetchRolesFlow(forceUpdate = false) {
  return async (
    dispatch: ThunkDispatch<IRootState, void, AdministrationActionType>,
    getState: () => IRootState
  ): Promise<AdministrationActionType> => {
    dispatch(fetchRoles.request());
    // make service call here and deal with Promise
    try {
      const fetchedRoles = await RoleService.getRoles();
      return dispatch(fetchRoles.success(fetchedRoles));
    } catch (error) {
      return dispatch(fetchRoles.failure(error));
    }
  };
}

// simple action creator
export const addNewRole = createStandardAction("ROLES/ADD")();

// simple action creator type
export const addNewRoleActionType = getType(addNewRole);

// *GROUPS* async actions definition
export const fetchGroups = createAsyncAction(
  "GROUPS/FETCH",
  "GROUPS/FETCH_SUCCESS",
  "GROUPS/FETCH_ERROR"
)<void, IGroup[], ApiError>();

// async actions types
export const fetchGroupsRequest = getType(fetchGroups.request);
export const fetchGroupsSuccess = getType(fetchGroups.success);
export const fetchGroupsError = getType(fetchGroups.failure);

// async action creator
export function fetchGroupsFlow(forceUpdate = false) {
  return async (
    dispatch: ThunkDispatch<IRootState, void, AdministrationActionType>,
    getState: () => IRootState
  ): Promise<AdministrationActionType> => {
    dispatch(fetchGroups.request());
    // make service call here and deal with Promise
    try {
      const fetchedGroups = await GroupService.getGroups();
      return dispatch(fetchGroups.success(fetchedGroups));
    } catch (error) {
      return dispatch(fetchGroups.failure(error));
    }
  };
}

// *GROUPS* async actions definition
export const fetchGroup = createAsyncAction(
  "GROUP/FETCH",
  "GROUP/FETCH_SUCCESS",
  "GROUP/FETCH_ERROR"
)<void, IGroup, ApiError>();

// async actions types
export const fetchGroupRequest = getType(fetchGroup.request);
export const fetchGroupSuccess = getType(fetchGroup.success);
export const fetchGroupError = getType(fetchGroup.failure);

// async action creator
export function fetchGroupFlow(groupId: string) {
  return async (
    dispatch: ThunkDispatch<IRootState, void, AdministrationActionType>
  ): Promise<AdministrationActionType> => {
    dispatch(fetchGroup.request());
    // make service call here and deal with Promise
    try {
      const group = await GroupService.getGroup(groupId);
      return dispatch(fetchGroup.success(group));
    } catch (error) {
      return dispatch(fetchGroup.failure(error));
    }
  };
}

// *ADD GROUPS* async actions definition
export const fetchAddGroup = createAsyncAction(
  "ADDGROUP/FETCH",
  "ADDGROUP/FETCH_SUCCESS",
  "ADDGROUP/FETCH_ERROR"
)<void, IGroup, ApiError>();

// async actions types
export const fetchAddGroupRequest = getType(fetchAddGroup.request);
export const fetchAddGroupSuccess = getType(fetchAddGroup.success);
export const fetchAddGroupError = getType(fetchAddGroup.failure);

// async action creator
export function fetchAddGroupFlow(newGroup: IGroup) {
  return async (
    dispatch: ThunkDispatch<IRootState, void, AdministrationActionType>
  ): Promise<AdministrationActionType> => {
    dispatch(fetchAddGroup.request());
    try {
      const addGroup = await GroupService.addGroups(newGroup);
      return dispatch(fetchAddGroup.success(addGroup));
    } catch (error) {
      return dispatch(fetchAddGroup.failure(error));
    }
  };
}

// async actions definition
export const savePartnerToGroup = createAsyncAction(
  "SAVE_PARTNER/FETCH",
  "SAVE_PARTNER/FETCH_SUCCESS",
  "SAVE_PARTNER/FETCH_ERROR"
)<void, IGroup, Error>();

// async actions types
export const savePartnerToGroupRequest = getType(savePartnerToGroup.request);
export const savePartnerToGroupSuccess = getType(savePartnerToGroup.success);
export const savePartnerToGroupError = getType(savePartnerToGroup.failure);

// async action creator
export function savePartnerToGroupFlow(group: IGroup, partner: IPartner) {
  return async (
    dispatch: ThunkDispatch<IRootState, void, AdministrationActionType>
  ): Promise<AdministrationActionType> => {
    dispatch(savePartnerToGroup.request());
    // make service call here and deal with Promise
    try {
      const newGroup = await GroupService.savePartnerToGroup(group, partner);
      return dispatch(savePartnerToGroup.success(newGroup));
    } catch (error) {
      return dispatch(savePartnerToGroup.failure(error));
    }
  };
}

// async actions definition
export const removePartnerFromGroup = createAsyncAction(
  "REMOVE_PARTNER/FETCH",
  "REMOVE_PARTNER/FETCH_SUCCESS",
  "REMOVE_PARTNER/FETCH_ERROR"
)<void, IGroup, Error>();

// async actions types
export const removePartnerFromGroupRequest = getType(
  removePartnerFromGroup.request
);
export const removePartnerFromGroupSuccess = getType(
  removePartnerFromGroup.success
);
export const removePartnerFromGroupError = getType(
  removePartnerFromGroup.failure
);

// async action creator
export function removePartnerFromGroupFlow(group: IGroup, partner: IPartner) {
  return async (
    dispatch: ThunkDispatch<IRootState, void, AdministrationActionType>
  ): Promise<AdministrationActionType> => {
    dispatch(removePartnerFromGroup.request());
    // make service call here and deal with Promise
    try {
      // TODO: Rerender page when this promise resolves
      const newGroup = await GroupService.removePartnerFromGroup(
        group,
        partner
      );
      return dispatch(removePartnerFromGroup.success(newGroup));
    } catch (error) {
      return dispatch(removePartnerFromGroup.failure(error));
    }
  };
}

// *PERMISSIONS* async actions definition
export const fetchPermission = createAsyncAction(
  "PERMISSION/FETCH",
  "PERMISSION/FETCH_SUCCESS",
  "PERMISSION/FETCH_ERROR"
)<void, IPermission[], ApiError>();

// async actions types
export const fetchPermissionRequest = getType(fetchPermission.request);
export const fetchPermissionSuccess = getType(fetchPermission.success);
export const fetchPermissionError = getType(fetchPermission.failure);

// async action creator
export function fetchPermissionsFlow() {
  return async (
    dispatch: ThunkDispatch<IRootState, void, AdministrationActionType>,
    getState: () => IRootState
  ): Promise<AdministrationActionType> => {
    dispatch(fetchPermission.request());
    // make service call here and deal with Promise
    try {
      const fetchedPermission = await RoleService.getPermissions();
      return dispatch(fetchPermission.success(fetchedPermission));
    } catch (error) {
      return dispatch(fetchPermission.failure(error));
    }
  };
}

// async actions definition
export const saveRole = createAsyncAction(
  "ROLE/SAVE",
  "ROLE/SAVE_SUCCESS",
  "ROLE/SAVE_ERROR"
)<void, IRole, ApiError>();

// async actions types
export const saveRoleRequest = getType(saveRole.request);
export const saveRoleSuccess = getType(saveRole.success);
export const saveRoleError = getType(saveRole.failure);

// async action creator
export function saveRoleFlow(role: IRole) {
  return async (
    dispatch: ThunkDispatch<IRootState, void, AdministrationActionType>
  ): Promise<AdministrationActionType> => {
    dispatch(saveRole.request());
    // make service call here and deal with Promise
    try {
      const newRole = await RoleService.saveRole(role);
      dispatch(fetchRolesFlow());

      return dispatch(saveRole.success(newRole));
    } catch (error) {
      return dispatch(saveRole.failure(error));
    }
  };
}

// async actions definition
export const fetchUsersInGroup = createAsyncAction(
  "GROUP_USERS/FETCH",
  "GROUP_USERS/FETCH_SUCCESS",
  "GROUP_USERS/FETCH_ERROR"
)<void, Array<{ userId: string; groupId: number }>, Error>();

// async actions types
export const fetchUsersInGroupRequest = getType(fetchUsersInGroup.request);
export const fetchUsersInGroupSuccess = getType(fetchUsersInGroup.success);
export const fetchUsersInGroupError = getType(fetchUsersInGroup.failure);

// async action creator
export function fetchUsersInGroupFlow(groupId: string) {
  return async (
    dispatch: ThunkDispatch<IRootState, void, AdministrationActionType>
  ): Promise<AdministrationActionType> => {
    dispatch(fetchUsersInGroup.request());
    // make service call here and deal with Promise
    try {
      const users = await GroupService.getUsersInGroup(groupId);
      return dispatch(fetchUsersInGroup.success(users));
    } catch (error) {
      return dispatch(fetchUsersInGroup.failure(error));
    }
  };
}

// *Partners* async actions definition
export const fetchPartners = createAsyncAction(
  "PARTNERS/FETCH",
  "PARTNERS/FETCH_SUCCESS",
  "PARTNERS/FETCH_ERROR"
)<void, IPartner[], ApiError>();

// async actions types
export const fetchPartnersRequest = getType(fetchPartners.request);
export const fetchPartnersSuccess = getType(fetchPartners.success);
export const fetchPartnersError = getType(fetchPartners.failure);

// async action creator
export function fetchPartnersFlow() {
  return async (
    dispatch: ThunkDispatch<IRootState, void, AdministrationActionType>
  ): Promise<AdministrationActionType> => {
    dispatch(fetchPartners.request());
    // make service call here and deal with Promise
    try {
      const partners = await PartnerService.getPartners();
      return dispatch(fetchPartners.success(partners));
    } catch (error) {
      return dispatch(fetchPartners.failure(error));
    }
  };
}

// async actions definition
export const removePartnerFromUser = createAsyncAction(
  "REMOVE_PARTNER_FROM_USER/FETCH",
  "REMOVE_PARTNER_FROM_USER/FETCH_SUCCESS",
  "REMOVE_PARTNER_FROM_USER/FETCH_ERROR"
)<void, { user: IRbacUser; partners: IPartner[] }, Error>();

// async actions types
export const removePartnerFromUserRequest = getType(
  removePartnerFromUser.request
);
export const removePartnerFromUserSuccess = getType(
  removePartnerFromUser.success
);
export const removePartnerFromUserError = getType(
  removePartnerFromUser.failure
);

// async action creator
export function removePartnerFromUserFlow(user: IRbacUser, partner: IPartner) {
  return async (
    dispatch: ThunkDispatch<IRootState, void, AdministrationActionType>
  ): Promise<AdministrationActionType> => {
    dispatch(removePartnerFromUser.request());
    // make service call here and deal with Promise
    try {
      // removedPartner is the list of partners with a partner removed
      const removedPartner = await UserService.removePartnerFromUser(
        user,
        partner
      );
      return dispatch(
        removePartnerFromUser.success({ user, partners: removedPartner })
      );
    } catch (error) {
      return dispatch(removePartnerFromUser.failure(error));
    }
  };
}

// async actions definition
export const savePartnerToUser = createAsyncAction(
  "SAVE_PARTNER_TO_USER/FETCH",
  "SAVE_PARTNER_TO_USER/FETCH_SUCCESS",
  "SAVE_PARTNER_TO_USER/FETCH_ERROR"
)<void, { user: IRbacUser; partners: IPartner[] }, Error>();

// async actions types
export const savePartnerToUserRequest = getType(savePartnerToUser.request);
export const savePartnerToUserSuccess = getType(savePartnerToUser.success);
export const savePartnerToUserError = getType(savePartnerToUser.failure);

// async action creator
export function savePartnerToUserFlow(user: IRbacUser, partner: IPartner) {
  return async (
    dispatch: ThunkDispatch<IRootState, void, AdministrationActionType>
  ): Promise<AdministrationActionType> => {
    dispatch(savePartnerToUser.request());
    // make service call here and deal with Promise
    try {
      // savedPartner is the list of partners with the new partner saved to that list
      const savedPartner = await UserService.savePartnerToUser(user, partner);
      return dispatch(
        savePartnerToUser.success({ user, partners: savedPartner })
      );
    } catch (error) {
      return dispatch(savePartnerToUser.failure(error));
    }
  };
}

// async actions definition
export const removeGroupFromUser = createAsyncAction(
  "REMOVE_GROUP_FROM_USER/FETCH",
  "REMOVE_GROUP_FROM_USER/FETCH_SUCCESS",
  "REMOVE_GROUP_FROM_USER/FETCH_ERROR"
)<void, { user: IRbacUser; groups: IGroup[] }, Error>();

// async actions types
export const removeGroupFromUserRequest = getType(removeGroupFromUser.request);
export const removeGroupFromUserSuccess = getType(removeGroupFromUser.success);
export const removeGroupFromUserError = getType(removeGroupFromUser.failure);

// async action creator
export function removeGroupFromUserFlow(user: IRbacUser, group: IGroup) {
  return async (
    dispatch: ThunkDispatch<IRootState, void, AdministrationActionType>
  ): Promise<AdministrationActionType> => {
    dispatch(removeGroupFromUser.request());
    // make service call here and deal with Promise
    try {
      // removeGroup is the list of groups with the specified group removed
      const removeGroup = await UserService.removeGroupFromUser(user, group);
      return dispatch(
        removeGroupFromUser.success({ user, groups: removeGroup })
      );
    } catch (error) {
      return dispatch(removeGroupFromUser.failure(error));
    }
  };
}

// async actions definition
export const saveGroupToUser = createAsyncAction(
  "SAVE_GROUP_TO_USER/FETCH",
  "SAVE_GROUP_TO_USER/FETCH_SUCCESS",
  "SAVE_GROUP_TO_USER/FETCH_ERROR"
)<void, { user: IRbacUser; groups: IGroup[] }, Error>();

// async actions types
export const saveGroupToUserRequest = getType(saveGroupToUser.request);
export const saveGroupToUserSuccess = getType(saveGroupToUser.success);
export const saveGroupToUserError = getType(saveGroupToUser.failure);

// async action creator
export function saveGroupToUserFlow(user: IRbacUser, group: IGroup) {
  return async (
    dispatch: ThunkDispatch<IRootState, void, AdministrationActionType>
  ): Promise<AdministrationActionType> => {
    dispatch(saveGroupToUser.request());
    // make service call here and deal with Promise
    try {
      // saveGroup is the list of groups with the new group saved to that list
      const saveGroup = await UserService.saveGroupToUser(user, group);
      return dispatch(saveGroupToUser.success({ user, groups: saveGroup }));
    } catch (error) {
      return dispatch(saveGroupToUser.failure(error));
    }
  };
}
// async actions definition
export const fetchAddPermissionToRole = createAsyncAction(
  "ADD_PERMISSION/FETCH",
  "ADD_PERMISSION/FETCH_SUCCESS",
  "ADD_PERMISSION/FETCH_ERROR"
)<void, IRolePermission, ApiError>();

// async actions types
export const fetchAddPermissionToRoleRequest = getType(
  fetchAddPermissionToRole.request
);
export const fetchAddPermissionToRoleSuccess = getType(
  fetchAddPermissionToRole.success
);
export const fetchAddPermissionToRoleError = getType(
  fetchAddPermissionToRole.failure
);

// async action creator
export function fetchAddPermissionToRoleFlow(rolePermission: IRolePermission) {
  return async (
    dispatch: ThunkDispatch<IRootState, void, AdministrationActionType>
  ): Promise<AdministrationActionType> => {
    dispatch(fetchAddPermissionToRole.request());
    // make service call here and deal with Promise
    try {
      const AddPermissionToRole = await RoleService.addPermissionToRole(
        rolePermission.roleId,
        rolePermission
      );
      dispatch(fetchRolesFlow(true));
      return dispatch(fetchAddPermissionToRole.success(AddPermissionToRole));
    } catch (error) {
      return dispatch(fetchAddPermissionToRole.failure(error));
    }
  };
}

// async actions definition
export const fetchRemovePermissionToRole = createAsyncAction(
  "REMOVE_PERMISSION/FETCH",
  "REMOVE_PERMISSION/FETCH_SUCCESS",
  "REMOVE_PERMISSION/FETCH_ERROR"
)<void, { permission: IPermission }, ApiError>();

// async actions types
export const fetchRemovePermissionToRoleRequest = getType(
  fetchRemovePermissionToRole.request
);
export const fetchRemovePermissionToRoleSuccess = getType(
  fetchRemovePermissionToRole.success
);
export const fetchRemovePermissionToRoleError = getType(
  fetchRemovePermissionToRole.failure
);

// async action creator
export function fetchRemovePermissionFromRoleFlow(
  rolePermission: IRolePermission
) {
  return async (
    dispatch: ThunkDispatch<IRootState, void, AdministrationActionType>
  ): Promise<AdministrationActionType> => {
    dispatch(fetchRemovePermissionToRole.request());
    // make service call here and deal with Promise
    try {
      const permission = await RoleService.removePermissionToRole(
        rolePermission.roleId,
        rolePermission.permissionId
      );
      dispatch(fetchRolesFlow(true));

      return dispatch(fetchRemovePermissionToRole.success(permission));
    } catch (error) {
      return dispatch(fetchRemovePermissionToRole.failure(error));
    }
  };
}

// async actions definition
export const fetchUsers = createAsyncAction(
  "USERS/FETCH",
  "USERS/FETCH_SUCCESS",
  "USERS/FETCH_ERROR"
)<void, IRbacUser[], Error>();

// async actions types
export const fetchUsersRequest = getType(fetchUsers.request);
export const fetchUsersSuccess = getType(fetchUsers.success);
export const fetchUsersError = getType(fetchUsers.failure);

// async action creator
export function fetchUsersFlow(forceUpdate = false) {
  return async (
    dispatch: ThunkDispatch<IRootState, void, AdministrationActionType>,
    getState: () => IRootState
  ): Promise<AdministrationActionType> => {
    dispatch(fetchUsers.request());
    // make service call here and deal with Promise
    try {
      const fetchedUsers = await UserService.getUsers();
      return dispatch(fetchUsers.success(fetchedUsers));
    } catch (error) {
      return dispatch(fetchUsers.failure(error));
    }
  };
}

// async actions definition
export const fetchUser = createAsyncAction(
  "USER/FETCH",
  "USER/FETCH_SUCCESS",
  "USER/FETCH_ERROR"
)<void, IRbacUser, Error>();

// async actions types
export const fetchUserRequest = getType(fetchUser.request);
export const fetchUserSuccess = getType(fetchUser.success);
export const fetchUserError = getType(fetchUser.failure);

// async action creator
export function fetchUserFlow(userId: string) {
  return async (
    dispatch: ThunkDispatch<IRootState, void, AdministrationActionType>
  ): Promise<AdministrationActionType> => {
    dispatch(fetchUser.request());
    // make service call here and deal with Promise
    try {
      const user = await UserService.getUser(userId);
      return dispatch(fetchUser.success(user));
    } catch (error) {
      return dispatch(fetchUser.failure(error));
    }
  };
}

// async actions definition
export const fetchUserPartners = createAsyncAction(
  "USER_PARTNERS/FETCH",
  "USER_PARTNERS/FETCH_SUCCESS",
  "USER_PARTNERS/FETCH_ERROR"
)<void, { email: string; partners: IPartner[] }, Error>();

// async actions types
export const fetchUserPartnersRequest = getType(fetchUserPartners.request);
export const fetchUserPartnersSuccess = getType(fetchUserPartners.success);
export const fetchUserPartnersError = getType(fetchUserPartners.failure);

// async action creator
export function fetchUserPartnersFlow(email: string) {
  return async (
    dispatch: ThunkDispatch<IRootState, void, AdministrationActionType>
  ): Promise<AdministrationActionType> => {
    dispatch(fetchUserPartners.request());
    // make service call here and deal with Promise
    try {
      const userPartners = await UserService.getUserPartners(email);
      return dispatch(
        fetchUserPartners.success({ email, partners: userPartners })
      );
    } catch (error) {
      return dispatch(fetchUserPartners.failure(error));
    }
  };
}

// async actions definition
export const fetchUserGroups = createAsyncAction(
  "USER_GROUPS/FETCH",
  "USER_GROUPS/FETCH_SUCCESS",
  "USER_GROUPS/FETCH_ERROR"
)<void, { email: string; Groups: IGroup[] }, Error>();

// async actions types
export const fetchUserGroupsRequest = getType(fetchUserGroups.request);
export const fetchUserGroupsSuccess = getType(fetchUserGroups.success);
export const fetchUserGroupsError = getType(fetchUserGroups.failure);

// async action creator
export function fetchUserGroupsFlow(email: string) {
  return async (
    dispatch: ThunkDispatch<IRootState, void, AdministrationActionType>
  ): Promise<AdministrationActionType> => {
    dispatch(fetchUserGroups.request());
    // make service call here and deal with Promise
    try {
      const userGroups = await UserService.getUserGroups(email);
      return dispatch(fetchUserGroups.success({ email, Groups: userGroups }));
    } catch (error) {
      return dispatch(fetchUserGroups.failure(error));
    }
  };
}

// async actions definition
export const saveUserRole = createAsyncAction(
  "USER_ROLE/FETCH",
  "USER_ROLE/FETCH_SUCCESS",
  "USER_ROLE/FETCH_ERROR"
)<void, IRbacUser, Error>();

// async actions types
export const saveUserRoleRequest = getType(saveUserRole.request);
export const saveUserRoleSuccess = getType(saveUserRole.success);
export const saveUserRoleError = getType(saveUserRole.failure);

// async action creator
export function saveUserRoleFlow(user: IRbacUser, role?: IRole) {
  return async (
    dispatch: ThunkDispatch<IRootState, void, AdministrationActionType>
  ): Promise<AdministrationActionType> => {
    dispatch(saveUserRole.request());
    // make service call here and deal with Promise
    try {
      const existingRole: IRole = user.role;
      // Dirty way of ensuring every every time a user gets assigned to Administrator (role.id=1)
      // they get added to `All Partners` group (group.id=1).
      const result = await UserService.editUser(user, role);

      // FIXME: this logic should be in the Users API, not here
      const allPartnersGroup = await GroupService.getGroup("1");
      if (role && role.id === 1) {
        await UserService.saveGroupToUser(user, allPartnersGroup);
      } else if (existingRole && existingRole.id === 1) {
        await UserService.removeGroupFromUser(user, allPartnersGroup);
      }
      return dispatch(saveUserRole.success(result));
    } catch (error) {
      return dispatch(saveUserRole.failure(error));
    }
  };
}

// get permissions configuration
// all administration action types
export type AdministrationActionType = ActionType<
  | typeof fetchRoles
  | typeof addNewRole
  | typeof saveRole
  | typeof fetchAddGroup
  | typeof fetchGroups
  | typeof fetchPartners
  | typeof savePartnerToGroup
  | typeof removePartnerFromGroup
  | typeof fetchUsersInGroup
  | typeof fetchPermission
  | typeof fetchAddPermissionToRole
  | typeof fetchRemovePermissionToRole
  | typeof fetchUsers
  | typeof fetchUser
  | typeof fetchUserPartners
  | typeof fetchUserGroups
  | typeof saveUserRole
  | typeof fetchGroup
  | typeof removePartnerFromUser
  | typeof savePartnerToUser
  | typeof saveGroupToUser
  | typeof removeGroupFromUser
>;
