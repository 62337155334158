// tslint:disable:variable-name
// tslint:disable:no-var-requires
const jwt_decode = require("jwt-decode");
import {
  mockAdminToken,
  mockBasicToken,
  mockReviewerToken,
  mockPublisherToken,
  urls
} from "../../shared/constants";
import CookieService from "../../shared/services/cookie.service";
import { UserRoles } from "./pages/UserRoles";
import moment from "moment-timezone";

export class AuthService {
  public getJWTToken() {
    const { REACT_APP_ENV } = process.env;
    const jwtToken =
      REACT_APP_ENV === "local"
        ? this.getMockToken(localStorage.getItem("testRole") as UserRoles) ||
          mockAdminToken
        : CookieService.getByName("token");
    return jwtToken;
  }

  public decodeUser(jwtToken: string) {
    return jwt_decode(jwtToken);
  }

  public getMockToken(userRole?: UserRoles) {
    let token;
    switch (userRole) {
      case UserRoles.Reviewer: {
        token = mockReviewerToken;
        break;
      }
      case UserRoles.Publisher: {
        token = mockPublisherToken;
        break;
      }
      case UserRoles.Administrator:
      default: {
        token = mockAdminToken;
        break;
      }
    }
    return token;
  }

  public login(userRole?: UserRoles) {
    const { REACT_APP_ENV } = process.env;
    if (REACT_APP_ENV === "local") {
      localStorage.setItem("testRole", userRole!);
    }

    const jwtToken = this.getJWTToken();
    if (REACT_APP_ENV !== "local" && (!jwtToken || this.isJWTExpired())) {
      const targetResource = encodeURIComponent(
        window.location.pathname + window.location.search
      );
      window.location.replace(
        `${urls.pingAuthorizer!}&TargetResource=${targetResource}`
      );
    }

    const user = this.decodeUser(jwtToken!);
    return user;
  }

  public logout() {
    CookieService.removeByName("token");
  }

  public get isLoggedIn() {
    if (this.isJWTExpired()) {
      this.login();
    }
    return true;
  }

  public isJWTExpired() {
    const { REACT_APP_ENV } = process.env;
    if (REACT_APP_ENV === "local") {
      return false;
    }

    const jwt = this.getJWTToken();
    const isJWTExpired = jwt
      ? moment.unix(jwt_decode(jwt).exp).isBefore(moment())
      : true;
    return isJWTExpired;
  }

  public getUser() {
    const jwt = this.getJWTToken();
    return this.decodeUser(jwt!);
  }
}

const defaultOpsService = new AuthService();

export default defaultOpsService;
