import { IPermission, IRole, IRolePermission } from "../../../models/Role";
import { urls } from "../../../shared/constants";
import apiService, { IApiService } from "../../../shared/services/api.service";
import { IPermissionConfig } from "../../auth/types";

export class RoleService {
  private apiService: IApiService;
  constructor(apiServiceDep?: any) {
    this.apiService = apiServiceDep || apiService;
  }
  public getPermissionsConfig(): Promise<IPermissionConfig> {
    return this.apiService.ajax<IPermissionConfig>(
      urls.permissionController.permissionConfig
    );
  }

  public getRoles(): Promise<IRole[]> {
    const request: Request = new Request(
      urls.rolesController.roles + "?relations=rolePermissions",
      this.apiService.getDefaultRequestInit()
    );
    return this.apiService.ajax(request);
  }

  public saveRole(role: IRole): Promise<IRole> {
    const request: Request = new Request(urls.rolesController.roles, {
      ...this.apiService.getDefaultRequestInit(),

      body: JSON.stringify(role),
      method: "POST"
    });
    return this.apiService.ajax(request);
  }

  public getPermissions(): Promise<IPermission[]> {
    const request: Request = new Request(
      urls.permissionController.permissions,
      this.apiService.getDefaultRequestInit()
    );
    return this.apiService.ajax(request);
  }

  public addPermissionToRole(
    roleId: number,
    rolePermission: IRolePermission
  ): Promise<IRolePermission> {
    const request: Request = new Request(
      urls.rolesController.roles + "/" + roleId + "/permissions",
      {
        ...this.apiService.getDefaultRequestInit(),

        body: JSON.stringify(rolePermission),
        method: "POST"
      }
    );
    return this.apiService.ajax(request);
  }

  public removePermissionToRole(
    roleId: number,
    rolePermissionId: number
  ): Promise<{ permission: IPermission }> {
    const request: Request = new Request(
      urls.rolesController.roles +
        "/" +
        roleId +
        "/permissions/" +
        rolePermissionId,
      {
        ...this.apiService.getDefaultRequestInit(),
        method: "DELETE"
      }
    );
    return this.apiService.ajax(request);
  }
}
export default new RoleService();
