import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { dismissModal } from "./features/communication/actions";
import createRootReducer from "./store";
// the history object keeps track of navigation stack and
// exposes helper methods to navigate back and forward
export const history = createBrowserHistory();

// redux-observable connection middleware creation

// connecting redux to chrome dev tools
// check https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd
const composeEnhancers =
  (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

// returns a redux store object
const configureStore = () => {
  const store = createStore(
    createRootReducer(history),
    {},
    composeEnhancers(applyMiddleware(routerMiddleware(history), thunk))
  );

  if (process.env.NODE_ENV !== "production") {
    if ((module as any).hot) {
      (module as any).hot.accept("./store", () => {
        store.replaceReducer(createRootReducer(history));
      });
    }
  }
  history.listen((location, action) => {
    store.dispatch(dismissModal());
  });
  return store;
};

/**
 * Returns the store factory
 */
export default configureStore;
