import React from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { IUser } from "../../../models/User";
import { IRootState } from "../../../store";
import * as loginActions from "../actions";

const PrivateRoute = ({
  component: Component,
  isLoggedIn,
  user,
  ...rest
}: {
  component: any;
  isLoggedIn: boolean;
  user: IUser;
}) => {
  const WrappedComponent = (props: any) => {
    return (isLoggedIn && props.location.pathname !== "/auth") ||
      (!isLoggedIn && props.location.pathname === "/auth") ? (
      <Component {...props} />
    ) : (
      <Redirect
        from={props.location.pathname}
        to={{
          pathname:
            isLoggedIn && props.location.pathname === "/auth" ? "/" : "/auth",
          state: { from: props.location }
        }}
      />
    );
  };

  return <Route {...rest} render={WrappedComponent} />;
};

export const ConnectedPrivateRoute = connect<any, any, any, any>(
  (state: IRootState) => ({
    isLoggedIn: !!state.auth.user,
    user: state.auth.user
  }),
  {
    login: loginActions.asyncLogin,
    logout: loginActions.asyncLogout
  }
)(PrivateRoute);

export default ConnectedPrivateRoute;
