import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { IUser } from "../models/User";
import { IRootState, withAuthDispatchToProps } from "../store";
import BB8AuthPanel from "./BB8AuthPanel";
import "./BB8TopNav.scss";

interface IBB8TopNavProps {
  user: IUser;
  showAuth: boolean;
  showHeader?: boolean;
  subHeader?: boolean;
}

export const BB8TopNav: React.FunctionComponent<IBB8TopNavProps> = ({
  user,
  children,
  showAuth = true,
  showHeader = true,
  subHeader = false
}) => (
  <>
    {showHeader && (
      <header className={`navbar-links-wrapper ${subHeader && "sub-header"}`}>
        <div className={!subHeader ? "navbar-links" : "navbar-sub-links"}>
          <nav>{children}</nav>
          {showAuth && (
            <BB8AuthPanel
              user={user}
              logout={() => window.location.replace("/logout")}
            />
          )}
        </div>
      </header>
    )}
  </>
);

export const ConnectedTopNav = connect<any, any, any, any>(
  (state: IRootState) => ({ user: state.auth.user }),
  (dispatch: Dispatch) => withAuthDispatchToProps(dispatch),
  undefined,
  { pure: false }
)(BB8TopNav);

export default ConnectedTopNav;
