import {
  Grid,
  IconButton,
  SvgIcon,
  TextField,
  withStyles,
  WithStyles
} from "@material-ui/core";
import { BB8Button, BB8MainPage, BB8SystemType, BB8Table } from "bb8";
import classNames from "classnames";
import { chain } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { formFieldStyles } from "../../../components/BB8FormField";
import { IGroup } from "../../../models/Group";
import { IPartner } from "../../../models/Partner";
import { IRootState, withAuthStateToProps } from "../../../store";
import withAuthorization from "../../auth/components/Authorization";
import * as adminActions from "../actions";
import { getGroupsAndPartners } from "../selectors";
import { ReactComponent as CloseIcon } from "./baseline-close-24px.svg";
import { getPartnerNames } from "./GroupEditPage";
import "./GroupsListPage.scss";
import { IWithSearchProps, withSearch } from "../../../components/WithSearch";

interface IGroupsListPageProps
  extends IWithSearchProps,
    RouteComponentProps,
    WithStyles {
  groups: IGroup[];
  partners: IPartner[];
  fetchGroups: () => void;
  onAddGroup: (input: IGroup) => Promise<any>;
}

const groupHeaders = [
  {
    id: "name",
    title: "Group Name"
  },
  {
    id: "partners",
    title: "Partners In Group"
  },
  {
    id: "partnersCount",
    title: "Partners Count"
  },
  {
    id: "action",
    title: "Action"
  }
];

export class GroupsListPage extends React.PureComponent<IGroupsListPageProps> {
  public componentDidMount = () => {
    this.props.fetchGroups();
  };

  public render() {
    const {
      classes,
      groups,
      searchString,
      onSearchChange,
      onSearchClear
    } = this.props;
    const options = this.getSuggestions(searchString, groups);

    const displayError = this.getDisplayError(searchString, groups);

    return (
      <BB8MainPage type={BB8SystemType.Billing}>
        <Grid container={true} direction="column">
          <BB8MainPage.Padded>
            <Grid
              container={true}
              spacing={16}
              direction="row"
              className="search-field-row"
            >
              <Grid item={true} md={4}>
                <TextField
                  className={classNames(classes.textField)}
                  id="search-group-name"
                  placeholder="Enter group name to Add New or Search Existing"
                  variant="filled"
                  color="default"
                  fullWidth={true}
                  value={searchString}
                  onChange={onSearchChange}
                  InputProps={{
                    classes: {
                      focused: classes.cssInputFocused,
                      input: classes.cssFilledInput,
                      root: classes.cssInputRoot
                    },
                    disableUnderline: true,
                    endAdornment: searchString && (
                      <IconButton
                        className="btn-clear-search"
                        onClick={onSearchClear}
                      >
                        <SvgIcon>
                          <CloseIcon />
                        </SvgIcon>
                      </IconButton>
                    )
                  }}
                />
              </Grid>
              <Grid item={true} md={6} container={true} alignItems="center">
                <BB8Button
                  title="Click to Add New Group"
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={this.isButtonDisabled(searchString, groups)}
                  className={"btn-add-group"}
                  onClick={this.handleAddGroup}
                >
                  Add this as a new group
                </BB8Button>
              </Grid>
            </Grid>

            <Grid item={true} md={6}>
              {displayError && (
                <div className="error-message">{displayError}</div>
              )}
            </Grid>
            <Grid>
              <BB8Table
                tableId={"table-group-list"}
                cols={groupHeaders}
                data={this.tableData(groups, options)}
              />
            </Grid>
          </BB8MainPage.Padded>
        </Grid>
      </BB8MainPage>
    );
  }

  private getDisplayError = (value: string, options: any) => {
    if (!options || !value) {
      return ""; // return an empty string no display error
    }

    if (this.isContentValid(value)) {
      if (this.doesGroupExist(value, options)) {
        return "This group name already exists";
      } else {
        return "This group name does not exist, add it as a new group or continue to filter group names";
      }
    } else {
      return "Your group name must be between 3 and 20 characters and contain no special characters";
    }
  };

  /* getSuggestions returns options that match input value */
  private getSuggestions = (value: string, options: IGroup[]) => {
    if (!options || !value) {
      return [];
    }

    return options.filter(suggestion =>
      suggestion.name.toLowerCase().includes(value.trim().toLowerCase())
    );
  };

  /*  This function populates the table data:
   *  with filtered options if they are passed in
   *  with the entire endpoint if no options are passed in
   */
  private tableData = (groups: IGroup[], filteredGroups: IGroup[]) => {
    const data = chain(filteredGroups.length ? filteredGroups : groups)
      .sortBy(group => group.name.toLowerCase(), ["asc"])
      .map((group: IGroup) => ({
        name: group.name,
        partners: group.coversAllPartners
          ? "All Partners"
          : getPartnerNames(group.partners),
        partnersCount:
          group && group.partners && !group.coversAllPartners
            ? group.partners.length
            : "",
        action: group.coversAllPartners ? (
          ""
        ) : (
          <BB8Button
            onClick={this.handleGoToGroup(group.id)}
            variant="contained"
            color="primary"
            size="large"
            className={"btn-edit"}
          >
            Edit Group
          </BB8Button>
        ),
        createdDate: group.createdDate
      }))
      .value();
    return data;
  };

  // Returns true if the group namee is found in the list
  private doesGroupExist = (input: string, groups: IGroup[]) => {
    // ensure all group names are lowercase for lookup
    const groupNames = groups.map((group: IGroup) => group.name.toLowerCase());

    // if this group exists already change the error message
    if (groupNames.includes(input.toLowerCase())) {
      return true;
    }
  };

  /* isContentValid returns bool if content is suitable for a group name
   * alphanumeric chars length 3-60, includes whitespaces and apostrophe
   * must not be a groupname that already exists
   */
  // TODO: need error for max length
  // TODO: the max lengths should be constants somewhere
  private isContentValid = (input: string) => {
    return /^[a-zA-Z0-9'\s]{3,20}$/.test(input);
  };

  private isButtonDisabled = (input: string, groups: IGroup[]) => {
    return this.doesGroupExist(input, groups) || !this.isContentValid(input);
  };

  private handleGoToGroup = (destination: string) => () => {
    this.props.history.push("/user-management/groups/" + destination);
  };

  private handleAddGroup = () => {
    const input = this.props.searchString;
    let newName: string;
    newName = input;

    const newGroup: IGroup = {
      id: "0",
      name: newName,
      partners: []
    };

    // callback to ensure that it is synchronous
    this.setState({ inputValue: "" }, () =>
      this.props.onAddGroup(newGroup).then(({ payload }) => {
        this.props.history.push("/user-management/groups/" + payload.id);
      })
    );

    // TODO: When a group is added we drive to that page to edit it.
    // this.handleGoToGroup(newGroup.id);
  };
}
const mapStateToProps = (state: IRootState) => ({
  ...withAuthStateToProps(state),
  groups: state.administration.groups,
  groupsWithPartners: getGroupsAndPartners(state),
  partners: state.administration.partners
});

const mapDispatchToProps = {
  fetchGroups: adminActions.fetchGroupsFlow,
  onAddGroup: adminActions.fetchAddGroupFlow
};

export const StyledGroupsListPage = withStyles(formFieldStyles)(GroupsListPage);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthorization(withSearch(StyledGroupsListPage), ["CREATE_GROUP"]));
