import { createStyles, FormControl, FormLabel, Grid, IconButton, MenuItem, Paper, SvgIcon, Theme, withStyles, WithStyles } from "@material-ui/core";
import { BB8MainPage, BB8Spinner, BB8SystemType, BB8Table } from "bb8";
import BB8Lookup, { IRenderOptionProps } from "../../../components/BB8Lookup";
import _, { chain }                     from "lodash";
import React                            from "react";
import { connect }                      from "react-redux";
import { RouteComponentProps }          from "react-router-dom";
import { formFieldStyles }              from "../../../components/BB8FormField";
import { IGroup }                       from "../../../models/Group";
import { IPartner }                     from "../../../models/Partner";
import { IRootState, withAuthStateToProps, IWithCommunicationProps } from "../../../store";
import withAuthorization                from "../../auth/components/Authorization";
import * as adminActions                from "../actions";
import { getGroupsAndPartners }         from "../selectors";
import ChartService                     from "../services/ChartService";
import UserService                      from "../../administration/services/UserService";
import { ReactComponent as ChartIcon }  from "./baseline-show_chart-24px.svg";
import { ReactComponent as TableIcon }  from "./baseline-table_chart-24px.svg";
import * as d3 from "d3";
import "./OffersDashboardPage.scss";

const styles = createStyles((theme: Theme) => ({
  textField: {
    display: "flex"
  },
  btnViewSelector: {
    borderRadius: 0,
    padding: ".25em",
    "&.selected": {
      backgroundColor: "rgba(0, 0, 0, 0.54)",
      color: "#fff"
    }
  }
}));

interface IOffersDashboardPageProps
  extends RouteComponentProps, IWithCommunicationProps,
    WithStyles {
      groups      : IGroup[];
      partners    : IPartner[];
      fetchGroups : () => void;
      onAddGroup  : (input: IGroup) => Promise<any>;
      user        : any;
    }

interface IPartnerForFilter
  extends IPartner {
    filterType: string
  }

interface IDashboardFilter {
  partnerId : string,
  timeRange : string,
  timeScope : string,
  regions   : string,
  scope     : string
}
interface IChartData {
  date          : string,
  offers        : number,
  partnersTotal : number,
  scope         : string
}

interface IFilterOption {
  name       : string,
  value      : string,
  filterType : string,
  id         : string
}

const groupHeaders = [
  {id: "date",          title: "Time Bounds"},
  {id: "offers",        title: "Offers"},
  {id: "partnersTotal", title: ""}
];

interface IChartDataStates {
  dashboardFilter : IDashboardFilter;
  dataView        : string;
  chartData       : IChartData[];
  isLoading       : boolean;
  partners?       : IPartner[],
  timeRange       : IFilterOption[],
  timeScope       : IFilterOption[],
  regions         : IFilterOption[],
  [key: string]   : any
}

const initialState: IChartDataStates = {
  dashboardFilter: {
    "partnerId" : "",
    "timeRange" : "7",
    "timeScope" : "days",
    "regions"   : "",
    "scope"     : ""
  },
  dataView: "graph",
  chartData: [],
  isLoading: true,
  partners: [],
  partnerSearch: "",
  timeRange: [
    {"name":"7",  "value": "7",  "filterType": "timerange", "id": "7"},
    {"name":"11", "value": "11", "filterType": "timerange", "id": "11"}
  ],
  timeRangeSearch: "",
  timeScope: [
    {"name":"Days",   "value": "days",   "filterType": "timescope", "id": "days"},
    {"name":"Weeks",  "value": "weeks",  "filterType": "timescope", "id": "weeks"},
    {"name":"Months", "value": "months", "filterType": "timescope", "id": "months"}
  ],
  timeScopeSearch: "",
  regions: [
    {"name":"Alberta",                    "value":"ab", "filterType": "regions", "id": "ab" },
    {"name":"British Columbia",           "value":"bc", "filterType": "regions", "id": "bc" },
    {"name":"Manitoba",                   "value":"mb", "filterType": "regions", "id": "mb" },
    {"name":"New Brunswick",              "value":"nb", "filterType": "regions", "id": "nb" },
    {"name":"Newfoundland and Labrador",  "value":"nl", "filterType": "regions", "id": "nl" },
    {"name":"Northwest Territories",      "value":"nt", "filterType": "regions", "id": "nt" },
    {"name":"Nova Scotia",                "value":"ns", "filterType": "regions", "id": "ns" },
    {"name":"Nunavut",                    "value":"nu", "filterType": "regions", "id": "nu" },
    {"name":"Ontario",                    "value":"on", "filterType": "regions", "id": "on" },
    {"name":"Thunder Bay",                "value":"tb", "filterType": "regions", "id": "tb" },
    {"name":"Prince Edward Island",       "value":"pe", "filterType": "regions", "id": "pe"},
    {"name":"Quebec",                     "value":"qc", "filterType": "regions", "id": "qc"},
    {"name":"Saskatchewan",               "value":"sk", "filterType": "regions", "id": "sk"},
    {"name":"Yukon",                      "value":"yt", "filterType": "regions", "id": "yt"}
  ],
  regionSearch: ""
};

export class OffersDashboardPage extends React.PureComponent<IOffersDashboardPageProps> {

  public state = initialState;

  public componentDidMount = () => {
    const { user } = this.props;
    this.props.fetchGroups();
    // window.addEventListener("resize", this.updateDimensions.bind(this));

    this.fetchChartData();

    if (user) {
      UserService.getUserPartners(user.email, true)
        .then((partners: IPartner[]) => {
          const partnersForFilter : IPartnerForFilter[] = this.adjustPartners(partners);
          this.setState({
            partners: partnersForFilter
           });
        })
        .catch(() => {
          this.context.displayAlert({
            message: "Error while trying to fetch partners",
            variant: "error"
          });
        });
    }
  };

  public setChartView = () => {
    this.setState({ dataView: 'graph' });
    this.renderDashboard(this.state.chartData);
  };

  public setTableView = () => {
    this.setState({ dataView: 'table' });
  };

  public getPartnerNameFromId = (partnerId: string) => {
    const found = _.find(this.state.partners, partner => partner.id === partnerId);
    return _.get(found, "name", "")
  }

  public getValueFromStateByKey = (key: string, id: string) => {
    const found = _.find(this.state[key], val => val.id === id);
    return _.get(found, "name", "")
  }

  public setPartnerSearch = (searchString: string) => {
    this.setState({
      partnerSearch: searchString.toLowerCase()
    });
  };

  public setRegionSearch = (searchString: string) => {
    this.setState({
      regionSearch: searchString.toLowerCase()
    });
  };

  public setTimeScopeSearch = (searchString: string) => {
    this.setState({
      timeScopeSearch: searchString.toLowerCase()
    });
  };

  public setRangeSearch = (searchString: string) => {
    this.setState({
      timeRangeSearch: searchString.toLowerCase()
    });
  };

  // 'filter' object type depends on selected field
  public handleFilterChange = (filter: IPartner | IFilterOption, type: string) => {
    let updatedFilter = {
      "partnerId" : type === "partner"   ? _.get(filter, "id", "")        : this.state.dashboardFilter.partnerId,
      "regions"   : type === "regions"   ? _.get(filter, "value", "")     : this.state.dashboardFilter.regions,
      "timeScope" : type === "timescope" ? _.get(filter, "value", "days") : this.state.dashboardFilter.timeScope,
      "timeRange" : type === "timerange" ? _.get(filter, "value", "7")    : this.state.dashboardFilter.timeRange
    };

    this.setState({
      dashboardFilter: updatedFilter,
      isLoading: true,
      partnerSearch   : type === "partner"   ? _.get(filter, "partnerSearch", "")       : this.state.partnerSearch,
      regionSearch    : type === "regions"   ? _.get(filter, "regionSearch", "")        : this.state.regionSearch,
      timeScopeSearch : type === "timescope" ? _.get(filter, "timeScopeSearch", "") : this.state.timeScopeSearch,
      timeRangeSearch : type === "timerange" ? _.get(filter, "timeRangeSearch", "")    : this.state.timeRangeSearch
    }, ()=>{
      this.fetchChartData();
    });

  };

  public render() {
    const { classes } = this.props;
    const { dashboardFilter, dataView, chartData, isLoading, partners, partnerSearch, regions, regionSearch, timeScope, timeScopeSearch, timeRange, timeRangeSearch} = this.state;
    const partnersOptions = getPartnerLookupOptions(partners);

    function getPartnerLookupOptions(partners: IPartner[] | undefined) {
      return _.chain(partners)
        .filter(partner =>
          partner.name.toLowerCase().includes(partnerSearch.trim())
        )
        .sortBy(["name"], ["asc"])
        .value();
    }
    const regionsOptions = getRegionsLookupOptions(regions);
    function getRegionsLookupOptions(regions: IFilterOption[]) {
      return _.chain(regions)
        .filter(region =>
          region.name.toLowerCase().includes(regionSearch.trim())
        )
        .value();
    }
    const timeScopeOptions = getTimeScopeLookupOptions(timeScope);
    function getTimeScopeLookupOptions(timeScope: IFilterOption[]) {
      return _.chain(timeScope)
        .filter(timeScopeItem =>
          timeScopeItem.name.toLowerCase().includes(timeScopeSearch.trim())
        )
        .value();
    }
    const timeRangeOptions = getTimeRangeLookupOptions(timeRange);
    function getTimeRangeLookupOptions(timeRange: IFilterOption[]) {
      return _.chain(timeRange)
        .filter(timeRangeItem =>
          timeRangeItem.name.toLowerCase().includes(timeRangeSearch.trim())
        )
        .value();
    }

    return (
      <BB8MainPage type={BB8SystemType.Billing}>
        <Grid container={true} direction="column">
          <BB8MainPage.Padded>
            <Grid container={true} spacing={16} direction="row" className="search-field-row">
              <Grid item={true} md={3}>
                <FormControl className={classes.textField} margin="dense">
                  <FormLabel component="label">Select Partner: </FormLabel>
                  <BB8Lookup
                    name          = "partner"
                    options       = {partnersOptions}
                    renderOption  = {renderFilterOption}
                    onSelect      = {e => this.handleFilterChange(e, "partner")}
                    onInputChange = {e => this.setPartnerSearch(e.target.value)}
                    value         = {this.getValueFromStateByKey("partners", dashboardFilter.partnerId)}
                    shouldOpenOnFocus = {true}
                    placeholder   = "All"
                  />
                </FormControl>
              </Grid>
              <Grid item={true} md={3}>
                <FormControl className={classes.textField} margin="dense">
                  <FormLabel component="label">Province: </FormLabel>
                  <BB8Lookup
                    name          = "regions"
                    options       = {regionsOptions}
                    renderOption  = {renderFilterOption}
                    onSelect      = {e => this.handleFilterChange(e, "regions")}
                    onInputChange = {e => this.setRegionSearch(e.target.value)}
                    value         = {this.getValueFromStateByKey("regions", dashboardFilter.regions)}
                    shouldOpenOnFocus = {true}
                    placeholder   = "All"
                  />
                </FormControl>
              </Grid>
              <Grid item={true} md={3}>
                <FormControl className={classes.textField} margin="dense">
                  <FormLabel component="label">Time Scope:</FormLabel>
                  <BB8Lookup
                    name          = "timescope"
                    options       = {timeScopeOptions}
                    renderOption  = {renderFilterOption}
                    onSelect      = {e => this.handleFilterChange(e, "timescope")}
                    onInputChange = {e => this.setTimeScopeSearch(e.target.value)}
                    value         = {this.getValueFromStateByKey("timeScope", dashboardFilter.timeScope)}
                    shouldOpenOnFocus = {true}
                    placeholder   = "All"
                  />
                </FormControl>
              </Grid>
              <Grid item={true} md={3}>
                <FormControl className={classes.textField} margin="dense">
                  <FormLabel component="label">Range: </FormLabel>
                  <BB8Lookup
                    name          = "timerange"
                    options       = {timeRangeOptions}
                    renderOption  = {renderFilterOption}
                    onSelect      = {e => this.handleFilterChange(e, "timerange")}
                    onInputChange = {e => this.setRangeSearch(e.target.value)}
                    value         = {this.getValueFromStateByKey("timeRange", dashboardFilter.timeRange)}
                    shouldOpenOnFocus = {true}
                    placeholder   = ""
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container={true} alignItems="center">

              <Grid item={true} md={12}>
                <IconButton className={classes.btnViewSelector + (dataView === "graph" ? " selected" : "")} onClick={this.setChartView}>
                  <SvgIcon><ChartIcon /></SvgIcon>
                </IconButton>
                <IconButton className={classes.btnViewSelector + (dataView === "table" ? " selected" : "")} onClick={this.setTableView}>
                  <SvgIcon><TableIcon /></SvgIcon>
                </IconButton>
              </Grid>

              <Grid item={true} md={12} className="mb-2">
                <Paper
                  square={true}
                  className={(dataView === 'graph' ? '' : 'hide')}
                  key={dashboardFilter.partnerId + '-' + dashboardFilter.timeScope + '-' + dashboardFilter.regions}
                >
                  <div id="chartWrap"></div>
                  <svg width="100%" height="500" id="chart"></svg>
                </Paper>

                <Paper square={true} className={(dataView === 'table' ? '' : 'hide')}>
                  <BB8Table
                    tableId = {"table-chart-list"}
                    cols    = {groupHeaders}
                    data    = {this.tableData(chartData)}
                  />
                </Paper>
              </Grid>
            </Grid>
          </BB8MainPage.Padded>
        </Grid>
        <BB8Spinner show={isLoading} size={200} />
      </BB8MainPage>
    );
  }

  private adjustPartners = (partners: IPartner[]) => {
    let updatedPartners = partners.map((partner: IPartner) => {
      return Object.assign({}, partner, { filterType: "partners" });
    });

    return updatedPartners;
  }

  /*  This function populates the table data:
   *  with filtered options if they are passed in
   *  with the entire endpoint if no options are passed in
   */
  private tableData = (chartData: IChartData[]) => {
    return chain(chartData)
            .reverse()
            .map((group: IChartData) => ({
              date          : group.date,
              offers        : group.offers,
              partnersTotal : group.partnersTotal,
              scope         : ''
            }))
            .value();
  };

  private fetchChartData = () => {
    const filterObj = {
            dateContext : "live",
            partnerId   : this.state.dashboardFilter.partnerId,
            regions     : this.state.dashboardFilter.regions,
            timeRange   : parseInt(this.state.dashboardFilter.timeRange),
            timeScope   : this.state.dashboardFilter.timeScope
          };

    ChartService.getChartData(filterObj)
      .then((chartData: IChartData[]) => {
        this.renderDashboard(chartData);
        this.setState({
          isLoading: false,
          chartData: chartData
        });
      })
      .catch(() => {

      });
  }

  private renderDashboard = (fData: IChartData[]) => {
    // Remove chart if one exists
    this.cleanChartCanvas();

    const margin = {
          top   : 40,
          right : 80,
          bottom: 60,
          left  : 80
        },
        width  = this.getCanvasWidth(margin.left, margin.right),
        height = this.getCanvasHeight(margin.top, margin.bottom);

    let x = d3.scaleBand()
              .range([0, width])
              .padding(0.1);
    let y = d3.scaleLinear()
              .range([height, 0]);
    // let y2 = d3.scaleLinear()          // y2 - used for second chart
    //           .range([height, 0]);

    let svg = d3.select("svg#chart")
                .attr('preserveAspectRatio', 'none')
                .append("g")
                .attr("transform",
                      "translate(" + margin.left + "," + margin.top + ")")
                .attr("class", "main-canvas");

    var init = function (fData: IChartData[]) {
      const arrayX = fData.map((row: IChartData) => {
        return row.date || '';
      });
      const arrayY = fData.map((row: IChartData) => {
        return row.offers || 0;
      });
      // Scale the range of the data in the domains
      x.domain(arrayX);
      y.domain([0, d3.max(arrayY, function (d) {
        return d;
      }) || 0]);
      // append the rectangles for the bar chart
      let bars = svg.selectAll(".bar").data(fData);
      let counter = 0;

      bars.enter()
          .append("rect")
          .attr("class", function(d) {
            let className = "bar";
            if (d.scope !== "months" && fData.length && Math.floor(fData.length/2) === counter) {
              className += " current";
            } else if (d.scope === "months" && fData.length && (fData.length-2) === counter) {
              className += " current";
            }
            counter++;
            return className;
          })
          .attr("x", function(d: IChartData) { return x(d.date) || 0; })
          .attr("width", x.bandwidth())
          .attr("y", height)
          .attr("height", "0")
          .exit()
          .remove();

      // append amounts
      let amounts = svg.selectAll(".amount")
                        .data(fData)
                        .enter()
                        .append("text")
                        .text(0)
                        .attr("class", "amount")
                        .attr("fill", "#fff")
                        .attr("x", function(d) { return (x(d.offers.toString()) || 0) + (x.bandwidth()/2); })
                        .attr('y', height - 85)
                        .exit()
                        .remove();
      // Second (number of partners) chart
      // Data line and dots group
      // let lineAndDots = svg.append("g")
      //                   .attr("class", "line-and-dots")
      //                   .attr("transform", "translate(" + ((margin.left + margin.right) / 2) + "," + 0 + ")");

      drawChart(fData);
    };

    var drawChart = function (data: IChartData[]) {
      const arrayX = fData.map((row: IChartData) => {
        return row.date || "";
      });
      const arrayY = fData.map((row: IChartData) => {
        return row.offers || 0;
      });
      // Scale the range of the data in the domains
      x.domain(arrayX);
      y.domain([0, d3.max(arrayY, function (d) {
        return d;
      }) || 0]);

      // Bottom Axis 'Time scope'
      svg.append("g")
          .attr("transform", "translate(0," + height + ")")
          .call(d3.axisBottom(x))
          .append("text")
          .attr("fill", "#000")
          .attr("x", width/2)
          .attr("y", 26)
          .attr("dy", "1em")
          .attr("text-anchor", "middle")
          .attr("font-size", "12")
          .text("TIME SCOPE")
          .exit().remove();
      // Left Axis 'Offers'
      svg.append("g")
          .call(d3.axisLeft(y))
          .append("text")
          .attr("fill", "#0a6fb3")
          .attr("transform", "rotate(-90)")
          .attr("x", -height/2)
          .attr("y", -56)
          .attr("dy", "1em")
          .attr("text-anchor", "middle")
          .attr("font-size", "12")
          .text("OFFERS")
          .exit().remove();

      // append the rectangles for the bar chart
      var bars = svg.selectAll(".bar").data(data);

      bars.enter()
          .append("rect")
          .attr("class", "bar");

      bars.exit()
          .transition()
          .duration(300)
          .ease(d3.easeExp)
          .attr("height", 0)
          .remove();

      bars.transition()
          .duration(1000)
          .ease(d3.easeQuad)
          .attr("x", function(d) { return x(d.date) || 0; })
          .attr("width", x.bandwidth())
          .attr("y", function(d: IChartData) { return y(d.offers); })
          .attr("height", function(d: IChartData) { return height - y(d.offers); });

      // Second (number of partners) chart
      // const arrayY2 = fData.map((row: IChartData) => {
      //   return row.partnersTotal || 0;
      // });

      // if (Math.max.apply(null, arrayY2) > 0) {
      //   y2.domain([0, d3.max(arrayY2, function (d) {
      //     return d;
      //   }) || 0]);
      //   const yGraphScale = Math.max.apply(null, arrayY) / Math.max.apply(null, arrayY2);

      //   // Right Axis 'Partners'
      //   svg.append("g")
      //     .call(d3.axisRight(y2))
      //     .attr("transform", "translate( " + width + ", 0 )")
      //     .append("text")
      //     .attr("fill", "#d30e8b")
      //     .attr("transform", "rotate(-90)")
      //     .attr("x", -height/2)
      //     .attr("y", 36)
      //     .attr("dy", "1em")
      //     .attr("text-anchor", "middle")
      //     .attr("font-size", "12")
      //     .text("PARTNERS")
      //     .exit().remove();

      //   const lineShiftX = ChartService.getLineStartShift(data);

      //   let line = d3.line()
      //       .x(function(d: IChartData) { return (x(d.date) || 0) + lineShiftX; })
      //       .y(function(d: IChartData) { return y(d.partnersTotal * yGraphScale); });
      //   // Data dots
      //   svg.selectAll("line-circle")
      //       .data(data)
      //       .enter()
      //       .append("circle")
      //       .attr("class", "data-circle")
      //       .attr("r", 5)
      //       .attr("cx", function(d: IChartData) { return (x(d.date) || 0) + (x.bandwidth()/2); })
      //       .attr("cy", function(d: IChartData) { return y(d.partnersTotal * yGraphScale); })
      //       .append("text")
      //       .attr("class", "line-and-dots__text")
      //       .text(function(d){ return d3.format(",")(d.partnersTotal)})
      //       .attr("x", function(d: IChartData) { return (x(d.date) || 0) + x.bandwidth()/2 - 20; })
      //       .attr('y', function(d: IChartData) { return y(d.partnersTotal) * yGraphScale - 30;});

      //   // Data line and dots group
      //   var lineAndDots = svg.append("g")
      //       .attr("class", "line-and-dots")
      //       .attr("transform", "translate(" + ((margin.left + margin.right) / 2) + "," + 0 + ")");
      //   // Data line
      //   lineAndDots.append("path")
      //       .datum(data)
      //       .attr("class", "data-line")
      //       .attr("d", line);
      //   // Figures
      //   svg.selectAll("line-circle")
      //       .data(data)
      //       .enter()
      //       .append("text")
      //       .attr("class", "line-and-dots__text")
      //       .text(function(d){ return d3.format(",")(d.partnersTotal)})
      //       .attr("x", function(d: IChartData) { return (x(d.date) || 0) + x.bandwidth()/2 + 15; })
      //       .attr('y', function(d: IChartData) { return y(Number(d.value) * yGraphScale + 15);});
      // }

      var amounts = svg.selectAll(".amount").data(data);
      amounts.enter()
              .append("text")
              .attr("class", "amount");

      amounts.exit()
              .transition()
              .duration(1000)
              .ease(d3.easeExp)
              .attr("y", 0)
              .remove()

      amounts.transition()
              .duration(100)
              .ease(d3.easeQuad)
              .attr("fill", "#fff")
              .text(function(d){ return d3.format(",")(d.offers)})
              .attr("x", function(d: IChartData) { return (x(d.date) || 0) + x.bandwidth()/2 - 20; })
              .attr('y', function(d: IChartData) { return y(Number(d.offers)) + 30;}) //
    };

    init(fData);
  }

  private cleanChartCanvas = () => {
    if (d3.select("svg#chart").selectAll(".main-canvas").size()) {
      d3.select("svg#chart").selectAll(".main-canvas").remove();
    }
  }

  private getCanvasWidth = (marginLeft: number, marginRight: number) => {
    let width = 900,
        pageContentWidth = document!.querySelector(".main-page")!.clientWidth;

    if (pageContentWidth) {
      width = pageContentWidth - marginLeft - marginRight;
    }

    return width;
  }

  private getCanvasHeight = (marginTop: number, marginBottom: number) => {
    let height = 500,
        svgHeight = d3.select("svg#chart").attr("height");

    if (svgHeight) {
      height = parseInt(svgHeight) - marginTop - marginBottom;
    }

    return height;
  }
}

const renderFilterOption: React.FunctionComponent<
  IRenderOptionProps<IPartner>
> = props => {
  return (
    <MenuItem {...props.itemProps} key={props.option.id}>
      {props.option.name}
    </MenuItem>
  );
};

const mapStateToProps = (state: IRootState) => ({
  ...withAuthStateToProps(state),
  groups: state.administration.groups,
  groupsWithPartners: getGroupsAndPartners(state),
  partners: state.administration.partners
});

const mapDispatchToProps = {
  fetchGroups: adminActions.fetchGroupsFlow,
  onAddGroup: adminActions.fetchAddGroupFlow
};

export const StyledOffersDashboardPage = withStyles(formFieldStyles)(OffersDashboardPage);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withAuthorization(StyledOffersDashboardPage, ["CREATE_GROUP"])));
