import { AppBar, Grid, Tab, Tabs } from "@material-ui/core";
import { BB8Table } from "bb8";
import { IColProps } from "bb8/lib/components/BB8Table";
import React from "react";
import "./EditContentTabs.scss";
import BB8Lookup from "../components/BB8Lookup";
interface IEditConentTabs {
  tabInfo: ITabInfo[];
}

interface ITabInfo {
  label: string;
  searchBar?: any; // TODO: assign to proper type IBB8LookupProps
  tableData?: { tableId: string; tableHeaders: IColProps[]; tableRows: any[] };
  tabContent?: any; // All tab content
}

function TabContainer(
  props: any & { className: string }
): React.FunctionComponentElement<any & { className: string }> {
  return (
    <div className={props.className} style={{ padding: 8 * 3 }}>
      {props.children}
    </div>
  );
}

class EditConentTabs extends React.Component<IEditConentTabs> {
  public state = {
    inputValue: "",
    value: 0
  };

  public handleChange = (event: any, value: any) => {
    this.setState({ value });
  };

  public getTabLabels = (tabInfo: ITabInfo[]) =>
    tabInfo.map((tab, i) => <Tab label={tab.label} key={i} />);

  public getTabContent = (currentTab: ITabInfo) => {
    if (currentTab.tabContent) {
      return (
        <TabContainer className="tab-container">
          {currentTab.tabContent}
        </TabContainer>
      );
    }

    const search = currentTab.searchBar;
    return (
      <TabContainer className="tab-container">
        <Grid container={true} direction="column">
          {search && (
            <Grid
              container={true}
              spacing={16}
              direction="row"
              className="lookup-partner"
            >
              <Grid item={true} md={6}>
                <BB8Lookup
                  id={search.id}
                  shouldOpenOnFocus={true}
                  placeholder={search.placeholder}
                  onInputChange={search.onInputChange}
                  onInputBlur={search.onInputBlur}
                  options={search.options ? search.options : []}
                  renderOption={search.renderOption}
                  inputValue={search.inputValue}
                />
              </Grid>
              <Grid item={true} md={6} />
            </Grid>
          )}
          <Grid>
            {currentTab.tableData && (
              <BB8Table
                tableId={currentTab.tableData.tableId}
                cols={currentTab.tableData.tableHeaders}
                data={currentTab.tableData.tableRows}
              />
            )}
          </Grid>
        </Grid>
      </TabContainer>
    );
  };

  public render() {
    const { value } = this.state;
    const tabInfo = this.props.tabInfo;
    const tabLabels = this.getTabLabels(tabInfo); // this only needs to happen once

    return (
      <div className="tab-container-inner">
        <AppBar position="static" color="default">
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            value={this.state.value}
            onChange={this.handleChange}
          >
            {tabLabels}
          </Tabs>
        </AppBar>
        {value === 0 && this.getTabContent(tabInfo[value])}
        {value === 1 && this.getTabContent(tabInfo[value])}
      </div>
    );
  }
}

export default EditConentTabs;
