import React, { lazy } from "react";
import { Redirect, Route } from "react-router-dom";
import ConnectedPrivateRoute from "./features/auth/pages/ConnectedProtectedRoute";

// TODO : check route names and missing routes

// React.lazy enables code-splitting / bundle tree-shaking

// Offer Submission module
const OfferSubmission = lazy(() => import("./features/offer-submission"));

// User Management module
const UserManagement = lazy(() => import("./features/administration"));

// User Unassigned
const Unassigned = lazy(() => import("./features/auth/pages/Unassigned"));

// Other pages
const Login = lazy(() => import("./features/auth/pages/TempLoginPage"));

const NotFound = lazy(() => import("bb8/lib/containers/NotFound"));

const Unauthorized = lazy(() => import("./features/auth/pages/Unauthorized"));

const LoggedOut = lazy(() => import("./features/auth/pages/LoggedOut"));

const Routes: JSX.Element[] = [
  <ConnectedPrivateRoute
    key={0}
    path="/offer-submission"
    component={OfferSubmission}
  />,
  <ConnectedPrivateRoute
    key={2}
    path="/user-management"
    component={UserManagement}
  />,
  <ConnectedPrivateRoute key={5} path="/unassigned" component={Unassigned} />,
  <Route key={4} path="/auth" component={Login} />,
  <Route key={6} path="/unauthorized" component={Unauthorized} />,
  <Route key={7} path="/logout" component={LoggedOut} />,
  <Redirect key={3} path="/" to="/offer-submission" exact={true} />,
  <Route key={5} component={NotFound} />
];

export default Routes;
