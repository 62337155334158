import { keyBy } from "lodash";
import { createSelector } from "reselect";
import { IGroup } from "../../../models/Group";
import { IPartner } from "../../../models/Partner";
import { IRbacUser } from "../../../models/User";
import { IRootState } from "../../../store";

const getUsers = (state: IRootState): IRbacUser[] => state.administration.users;
const getAllGroups = (state: IRootState): IGroup[] =>
  state.administration.groups;
const getAllPartners = (state: IRootState): IPartner[] =>
  state.administration.partners;
const getGroupsMap = createSelector(
  [getAllGroups],
  (groups: IGroup[]): { [groupId: string]: IGroup } =>
    keyBy<IGroup>(groups, "id")
);

const getPartnersMap = createSelector(
  [getAllPartners],
  (partners: IPartner[]): { [partnerId: string]: IPartner } =>
    keyBy<IPartner>(partners, "id")
);

const getUserPartnerMappings = (state: IRootState) =>
  state.administration.userPartnersMapping;

const getGroupUserMappings = (state: IRootState) =>
  state.administration.groupUserMappings;

const getUserGroupMappings = (state: IRootState) =>
  state.administration.userGroupsMapping;

export const getGroupsAndPartners = createSelector(
  [getAllGroups, getPartnersMap],
  groups => {
    return groups;
  }
);

/*
This redux selector https://github.com/reduxjs/reselect
accepts the app state and returns a customized mapping of users mapped to their partners and groups.
It is used when mapping state to props, when the prop needs to be generated from a customized mapping of the state.
We use this selector when mapping state to props on the UserEditPage, when the we want to have the information about each users mapping to partners and groups.
*/
export const getUsersWithPartnersAndGroups = createSelector(
  [
    getUsers,
    getUserPartnerMappings,
    getPartnersMap,
    getUserGroupMappings,
    getGroupsMap
  ],
  (users, userPartnerMapping, partners, userGroupMapping, groups) => {
    return users.map(user => {
      const userId = encodeURIComponent(user.id);
      return {
        ...user,
        groups: userGroupMapping[userId]
          ? userGroupMapping[userId].map(
              groupId => groups[parseInt(groupId, 10)]
            )
          : [],
        partners: userPartnerMapping[userId]
          ? userPartnerMapping[userId].map(partnerId => partners[partnerId])
          : []
      };
    });
  }
);

// Leverage group user partner mappings to map users to groups and returns groups with users
export const getGroupsWithUsers = createSelector(
  [getAllGroups, getGroupUserMappings, getUsers],
  (groups, groupUserMappings, users) => {
    return groups.map(group => {
      return {
        ...group,
        users: groupUserMappings.map(
          (mapping: { userId: string; groupId: number }) =>
            users.find(
              user =>
                user.id === mapping.userId &&
                mapping.groupId === parseInt(group.id, 10)
            )
        )
      };
    });
  }
);
