import {
  objectToQueryString,
  queryStringToObject,
  toCheckedSet,
  toCommaSeparatedString
} from "../shared/helpers";
import {
  OfferStatus,
  IOfferFormModel,
  OfferDateContext
} from "../validation/validator";

export type OfferSortField = keyof IOfferFormModel;
export type SortDirection = "asc" | "desc";
export interface IOfferSort {
  field: OfferSortField;
  direction: SortDirection;
}
export enum AdvancedSearchFilterTypes {
  tags = "tags",
  bulkId = "bulkId",
  batchName = "batchName",
  massOffer = "massOffer",
  active = "active",
  offerCategory1 = "offerCategory1",
  offerCategory2 = "offerCategory2",
  onlyEmptyCategory = "onlyEmptyCategory"
}

export enum StaticFilterTypes {
  offerType = "offerType",
  qualifier = "qualifier",
  awardType = "awardType",
  programType = "programType",
  mechanismType = "mechanismType",
  regions = "regions"
}
export interface IOfferFilter {
  [key: string]: any;

  id?: string;

  bulkId?: string;
  batchName?: string;

  user?: string;
  partnerId?: string;
  tags?: string;
  region?: string;

  status?: OfferStatus;
  dateContext?: OfferDateContext;
  offerType?: Set<string>;
  qualifier?: Set<string>;
  awardType?: Set<string>;
  mechanismType?: Set<string>;
  regions?: Set<string>;

  page?: number;
  size?: number;
  sort?: OfferSortField;
  direction?: SortDirection;

  massOffer?: boolean;
  active?: boolean;

  offerCategory1?: string;
  offerCategory2?: string;

  onlyEmptyCategory?: boolean;
  hasIssuanceCode?: string;
  campaignCode?: string;

  toQueryString?: () => string | undefined;
}

export class OffersRequestParams implements IOfferFilter {
  [key: string]: any;

  public static fromQueryString(qs: string): IOfferFilter {
    if (!qs) {
      return new OffersRequestParams();
    }
    const params = queryStringToObject(qs);
    return new OffersRequestParams(params);
  }

  public id: string | undefined;

  public user: string | undefined;
  public bulkId: string | undefined;
  public tags: string | undefined;
  public partnerId: string | undefined;
  public offerType: Set<string> | undefined;
  public qualifier: Set<string> | undefined;
  public awardType: Set<string> | undefined;
  public mechanismType: Set<string> | undefined;
  public regions: Set<string> | undefined;

  public page: number | undefined;
  public size: number | undefined;
  public status: OfferStatus;
  public dateContext?: OfferDateContext;
  public sort: OfferSortField;
  public direction: SortDirection;

  public massOffer: boolean | undefined;
  public active: boolean | undefined;

  public offerCategory1: string | undefined;
  public offerCategory2: string | undefined;

  public onlyEmptyCategory: boolean | undefined;
  public hasIssuanceCode: string | undefined;
  public campaignCode: string | undefined;

  constructor(params?: any) {
    if (params) {
      this.id = params.id;
      this.user = params.user;
      this.tags = params.tags;
      this.partnerId = params.partnerId;
      this.offerType = toCheckedSet(params.offerType);
      this.qualifier = toCheckedSet(params.qualifier);
      this.awardType = toCheckedSet(params.awardType);
      this.mechanismType = toCheckedSet(params.mechanismType)
      this.regions = toCheckedSet(params.regions);
      this.bulkId = params.bulkId;
      this.status = params.status;
      this.dateContext = params.dateContext;

      this.page = params.page;
      this.size = params.size;

      this.sort = params.sort;
      this.direction = params.direction;
      this.massOffer = params.massOffer;
      this.active = params.active;

      this.offerCategory1 = params.offerCategory1;
      this.offerCategory2 = params.offerCategory2;

      this.onlyEmptyCategory = params.onlyEmptyCategory;
      this.hasIssuanceCode = params.hasIssuanceCode;
      this.campaignCode = params.campaignCode;
    }
  }

  public toQueryString() {
    return objectToQueryString({
      id: this.id,
      user: this.user,
      bulkId: this.bulkId,
      tags: this.tags,
      partnerId: this.partnerId,
      offerType: toCommaSeparatedString(this.offerType),
      qualifier: toCommaSeparatedString(this.qualifier),
      awardType: toCommaSeparatedString(this.awardType),
      mechanismType: toCommaSeparatedString(this.mechanismType),
      regions: toCommaSeparatedString(this.regions),
      status: this.status,
      dateContext: this.dateContext,
      page: this.page,
      size: this.size,
      sort: this.sort,
      direction: this.direction,
      massOffer: this.massOffer,
      active: this.active, // Set to true until disable offer tab
      offerCategory1: this.offerCategory1,
      offerCategory2: this.offerCategory2,
      onlyEmptyCategory: this.onlyEmptyCategory,
      hasIssuanceCode: this.hasIssuanceCode,
      campaignCode: this.campaignCode
    });
  }
}
