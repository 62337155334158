import React, { useState, useEffect } from "react";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import BB8Alert from "../../components/BB8Alert";
import { IBB8AlertProps } from "bb8/lib/components/BB8Alert";
import TargetAlertMessage from "./TargetAlertMessage";
import { connect } from "react-redux";
import {
  IRootState,
  withCommunicationDispatchToProps,
} from "../../store";
import * as communicationActions from "../communication/actions";

export type AlertContentType = Pick<IBB8AlertProps, "variant"> & {
  message?: string | React.ReactNode;
};
export interface SnackbarProps {
  alertContent?: Pick<IBB8AlertProps, "message" | "variant">;
  displayAlert: (content: AlertContentType) => void;
  hideAlert: () => void;
}

export const SnackbarContext = React.createContext<SnackbarProps>({
  displayAlert: () => {},
  hideAlert: () => {}
});

interface AlertContainerProps {
  showTargetAlertState?: boolean;
  hideTargetAlertAction: () => void;
}

const TargetAlertContainer: React.FunctionComponent<AlertContainerProps> = ({
  showTargetAlertState,
  hideTargetAlertAction
}) => {
  const [state, setState] = useState<{
    showTargetAlertLocal: boolean;
  }>({showTargetAlertLocal: false});

  useEffect(() => {
    setState(({ showTargetAlertLocal: showTargetAlertState || false}))
  }, [showTargetAlertState]);

  const handleTargetAlertClose = (e: any, reason: string) => {
    if(reason !== 'clickaway') {
      hideTargetAlertAction();
    }
  }
  return (
    <BB8Alert message="" variant="warning" show={state.showTargetAlertLocal} onClose={handleTargetAlertClose}>
      <TargetAlertMessage/>
    </BB8Alert>
  );
};

const mapStateToProps = (state: IRootState) => ({
  showTargetAlertState: state.communication.showTargetAlert
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IRootState, void, Action>
) =>
  withCommunicationDispatchToProps(dispatch, {
    hideTargetAlertAction: () => dispatch(communicationActions.hideTargetAlert()),
  });

export default connect(mapStateToProps, mapDispatchToProps)(TargetAlertContainer);
