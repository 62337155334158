import { chain, keyBy, map } from "lodash";
import { IAdministrationState } from "../../../types";
import * as adminActions from "../actions";
import { GroupService, UserService } from "../services";
import { updateObjectInArray } from "./../../../shared/helpers";

export default function(
  state: IAdministrationState = {
    groupUserMappings: [],
    groups: [],
    partners: [],
    permissions: [],
    roles: [],
    userGroupsMapping: {},
    userPartnersMapping: {},
    users: []
  },
  action: adminActions.AdministrationActionType
): IAdministrationState {
  switch (action.type) {
    case adminActions.fetchRolesSuccess: {
      return { ...state, roles: action.payload };
    }
    case adminActions.fetchPermissionSuccess: {
      return { ...state, permissions: action.payload };
    }
    case adminActions.saveRoleSuccess: {
      return { ...state, roles: [...state.roles, action.payload] };
    }
    case adminActions.fetchGroupsSuccess: {
      return {
        ...state,
        groups: action.payload
      };
    }
    case adminActions.fetchUsersSuccess: {
      return {
        ...state,
        users: action.payload
      };
    }
    case adminActions.fetchUserSuccess: {
      return {
        ...state,
        users:
          state.users && state.users.length
            ? [
                ...updateObjectInArray(
                  state.users,
                  action.payload,
                  UserService.usersComparer
                )
              ]
            : [action.payload]
      };
    }
    case adminActions.fetchPartnersSuccess: {
      return {
        ...state,
        partners: action.payload
      };
    }
    case adminActions.fetchGroupSuccess: {
      return {
        ...state,
        groups:
          state.groups && state.groups.length
            ? [
                ...updateObjectInArray(
                  state.groups,
                  action.payload,
                  GroupService.groupsComparer
                )
              ]
            : [action.payload]
      };
    }
    case adminActions.fetchAddGroupSuccess: {
      return {
        ...state,
        groups: [...state.groups, action.payload]
      };
    }
    case adminActions.savePartnerToGroupSuccess: {
      return {
        ...state,
        groups: [
          ...updateObjectInArray(
            state.groups,
            action.payload,
            GroupService.groupsComparer
          )
        ]
      };
    }
    case adminActions.removePartnerFromGroupSuccess: {
      return {
        ...state,
        groups: [
          ...updateObjectInArray(
            state.groups,
            action.payload,
            GroupService.groupsComparer
          )
        ]
      };
    }
    case adminActions.fetchUsersInGroupSuccess: {
      return {
        ...state,
        groupUserMappings: action.payload
      };
    }
    case adminActions.saveUserRoleSuccess: {
      return {
        ...state,
        users:
          state.users && state.users.length
            ? [
                ...updateObjectInArray(
                  state.users,
                  action.payload,
                  UserService.usersComparer
                )
              ]
            : [action.payload]
      };
    }
    case adminActions.fetchUserPartnersSuccess: {
      return {
        ...state,
        partners: mergeArraysByKey(
          state.partners,
          action.payload.partners,
          "id"
        ),
        userPartnersMapping: {
          [action.payload.email]: map(action.payload.partners, "id")
        }
      };
    }
    case adminActions.fetchUserGroupsSuccess: {
      return {
        ...state,
        groups: mergeArraysByKey(state.groups, action.payload.Groups, "id"),
        userGroupsMapping: {
          [action.payload.email]: map(action.payload.Groups, "id")
        }
      };
    }
    case adminActions.removeGroupFromUserSuccess:
    case adminActions.saveGroupToUserSuccess: {
      return {
        ...state,
        userGroupsMapping: {
          [encodeURIComponent(action.payload.user.id)]: map(
            action.payload.groups,
            "id"
          )
        }
      };
    }
    case adminActions.removePartnerFromUserSuccess:
    case adminActions.savePartnerToUserSuccess: {
      return {
        ...state,
        userPartnersMapping: {
          [encodeURIComponent(action.payload.user.id)]: map(
            action.payload.partners,
            "id"
          )
        }
      };
    }
    default: {
      return state;
    }
  }
}

function mergeArraysByKey<T>(arr1: T[], arr2: T[], key: keyof T): T[] {
  return chain(arr1)
    .keyBy(key) // create a dictionary of the 1st array
    .merge(keyBy(arr2, key)) // create a dictionary of the 2nd array, and merge it to the 1st
    .values() // turn the combined dictionary to array
    .value();
}
