import {
  Button,
  Fab,
  Grid,
  IconButton,
  SvgIcon,
  TextField,
  Tooltip,
  withStyles,
  WithStyles,
  Typography
} from "@material-ui/core";
import { BB8Button, BB8MainPage, BB8SystemType } from "bb8";
import { chain, isEmpty } from "lodash"; 
import React, { ReactNode } from "react";
import { connect } from "react-redux";
import { Link, RouteComponentProps } from "react-router-dom";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { formFieldStyles } from "../../../components/BB8FormField";
import BB8Table from "../../../components/BB8Table";
import { IRbacUser } from "../../../models/User";
import { IRootState, withAuthStateToProps } from "../../../store";
import withAuthorization from "../../auth/components/Authorization";
import * as adminActions from "../actions";
import { ReactComponent as CloseIcon } from "./baseline-close-24px.svg";
import "./GroupsListPage.scss";
import { IWithSearchProps, withSearch } from "../../../components/WithSearch";
import { getHumanReadableTimeFromNow } from "../../../shared/helpers";

interface IUsersListPageProps
  extends IWithSearchProps,
    RouteComponentProps,
    WithStyles {
  users: IRbacUser[];
  fetchUsers: () => void;
}

interface ISorting {
  direction: string;
  field: string;
}
interface IUserListPageStates {
  sorting: ISorting;
}

const initialState: IUserListPageStates = {
  sorting: {
    direction: "asc",
    field: "fullName"
  }
};

const tableHeaders: Array<{
  id: keyof IRbacUser | "action";
  title: ReactNode;
  sort?: boolean;
}> = [
  {
    id: "fullName",
    title: "User Name",
    sort: true
  },
  {
    id: "id",
    title: "Email",
    sort: true
  },
  {
    id: "role",
    title: "Role",
    sort: true
  },
  {
    id: "lastLoginTs",
    title: <>Last Active </>,
    sort: true
  },
  {
    id: "action",
    title: ""
  }
];

export class UsersListPage extends React.PureComponent<IUsersListPageProps> {
  public state = initialState;

  public componentDidMount = () => {
    this.props.fetchUsers();
  };

  public sortChanged = (field: string) => {
    const sorting = {
      direction: this.state.sorting.direction === "asc" ? "desc" : "asc",
      field
    };
    this.setState({ sorting });
  };

  public render() {
    const {
      classes,
      users,
      searchString,
      onSearchChange,
      onSearchClear
    } = this.props;
    const options = this.getSuggestions(searchString, users);
    const { sorting } = this.state;

    return (
      <BB8MainPage type={BB8SystemType.Billing}>
        <Grid container={true} direction="column">
          <BB8MainPage.Padded>
            <Grid
              container={true}
              spacing={16}
              direction="row"
              className="search-field-row"
            >
              <Grid item={true} md={4}>
                <TextField
                  id="search-user-name"
                  placeholder="Search by user name"
                  variant="filled"
                  color="default"
                  fullWidth={true}
                  value={searchString}
                  onChange={onSearchChange}
                  InputProps={{
                    classes: {
                      focused: classes.cssInputFocused,
                      input: classes.cssFilledInput,
                      root: classes.cssInputRoot
                      // notchedOutline: classes.notchedOutline
                    },
                    disableUnderline: true,
                    endAdornment: searchString && (
                      <IconButton
                        className="btn-clear-search"
                        onClick={onSearchClear}
                      >
                        <SvgIcon>
                          <CloseIcon />
                        </SvgIcon>
                      </IconButton>
                    )
                  }}
                />
              </Grid>
            </Grid>
            <Grid>
              <Typography variant="subtitle1" color="default">
                Total Number of Users: {options.length}
              </Typography>
              <BB8Table
                tableId={"table-user-list"}
                cols={tableHeaders}
                data={this.tableData(options, sorting)}
                noDataComponent={<div>Could not find any user(s).</div>}
                sorting={sorting}
                sortChange={this.sortChanged}
              />
            </Grid>
          </BB8MainPage.Padded>
        </Grid>
      </BB8MainPage>
    );
  }

  /*  This function populates the table data:
   *  with filtered options if they are passed in
   *  with the entire endpoint if no options are passed in
   */
  private tableData = (filteredUsers: IRbacUser[], sorting: any) => {
    const data = chain(filteredUsers)
      .orderBy(sorting.field, sorting.direction)
      .map((user: IRbacUser) => ({
        action: (
          <BB8Button
            onClick={this.handleGoToUser(user.id)}
            variant="contained"
            color="primary"
            size="large"
            className={"btn-edit"}
          >
            Edit user
          </BB8Button>
        ),

        fullName: (
          <Link
            to={`/user-management/users/${encodeURIComponent(
              user.id.toString()
            )}`}
          >
            <Tooltip title={user.fullName}>
              <span>{user.fullName}</span>
            </Tooltip>
          </Link>
        ),
        id: (
          <a href={`mailto:${user.id}`}>
            <Tooltip title={user.id}>
              <span>{user.id}</span>
            </Tooltip>
          </a>
        ),
        lastLoginTs: user.lastLoginTs
          ? getHumanReadableTimeFromNow(user.lastLoginTs)
          : "never",
        role: user.role ? user.role.name : ""
      }))
      .value();
    return data;
  };

  /* getSuggestions returns options that match input value */
  private getSuggestions = (value: string, options: IRbacUser[]) => {
    if (!options) {
      return [];
    }
    if (isEmpty(value)) {
      return options;
    }

    return options.filter(
      suggestion =>
        suggestion.fullName &&
        suggestion.fullName.toLowerCase().includes(value.trim().toLowerCase())
    );
  };

  private handleGoToUser = (userId: string) => () => {
    this.props.history.push(
      `/user-management/users/${encodeURIComponent(userId)}`
    );
  };
}

const mapStateToProps = (state: IRootState) => ({
  ...withAuthStateToProps(state),
  users: state.administration.users
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IRootState, void, Action>
) => ({
  fetchUsers: () => {
    dispatch(adminActions.fetchUsersFlow(true));
  }
});
export const StyledUsersListPage = withStyles(formFieldStyles)(UsersListPage);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthorization(withSearch(StyledUsersListPage), ["EDIT_USER"]));
