import React, { useContext, useState, useEffect } from "react";
import { BB8Alert } from "bb8";
import { IBB8AlertProps } from "bb8/lib/components/BB8Alert";
import { connect } from "react-redux";
import { IRootState } from "../../store";
import { isEqual } from "lodash";

export type AlertContentType = Pick<IBB8AlertProps, "variant"> & {
  message?: string | React.ReactNode;
};
export interface SnackbarProps {
  alertContent?: Pick<IBB8AlertProps, "message" | "variant">;
  displayAlert: (content: AlertContentType) => void;
  hideAlert: () => void;
}

export const SnackbarContext = React.createContext<SnackbarProps>({
  displayAlert: () => {},
  hideAlert: () => {}
});

interface AlertContainerProps {
  globalAlertContent?: Pick<IBB8AlertProps, "message" | "variant">;
}

const AlertContainer: React.FunctionComponent<AlertContainerProps> = ({
  children,
  globalAlertContent
}) => {
  const [state, setState] = useState<{
    alertContent?: Pick<IBB8AlertProps, "message" | "variant">;
  }>({});

  function displayAlert(content: AlertContentType) {
    setState({ alertContent: content });
  }
  function hideAlert() {
    setState({ alertContent: undefined });
  }

  useEffect(() => {
    if (
      globalAlertContent &&
      !isEqual(globalAlertContent, state.alertContent)
    ) {
      displayAlert(globalAlertContent);
    }
  }, [globalAlertContent]);
  return (
    <>
      <SnackbarContext.Provider value={{ ...state, displayAlert, hideAlert }}>
        {children}
      </SnackbarContext.Provider>
      {state.alertContent && (
        <BB8Alert
          message={state.alertContent.message}
          variant={state.alertContent.variant}
          show={true}
          onClose={hideAlert}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: IRootState) => ({
  globalAlertContent: state.communication.alertContent
});

export default connect(mapStateToProps)(AlertContainer);
