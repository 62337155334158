import React from "react";
import { BB8Button, BB8Form } from "bb8";
import BB8FormField from "../../../components/BB8FormField";
import {
  createStyles,
  Theme,
  withStyles,
  Grid,
  WithStyles
} from "@material-ui/core";
import { withFormik } from "formik";
import { isEmpty } from "lodash";
import { IRole } from "../../../models/Role";

interface IRoleFormValues {
  name: string;
}
interface IRoleFormProps extends WithStyles {
  roles?: IRole[];
  onSave: (roleName: string) => void;
  onCancel: () => void;
}

const formStyles = createStyles((theme: Theme) => ({
  form: {
    display: "flex",
    flex: "0 0 auto",
    flexDirection: "row",
    position: "relative"
  },
  formField: {
    marginBottom: 8
  },

  formButton: {
    margin: "30px 8px auto",
    padding: theme.spacing.unit
  }
}));

const RoleForm = withFormik<IRoleFormProps, IRoleFormValues>({
  /**
   * Called on form submit
   */
  handleSubmit: (values, props) => {
    if (!isEmpty(values.name)) {
      props.props.onSave(values.name);
    }
  },

  /**
   * called on every input change and blur
   */
  validate: (values, props) => {
    const errors: { [k: string]: string | undefined } = {};
    // TODO: validations
    if (
      props.roles &&
      props.roles.length &&
      props.roles.some(role => {
        return role.name.toLowerCase() === values.name.toLowerCase();
      })
    ) {
      errors.name = "This role already exists";
    }
    return errors;
  },
  validateOnBlur: false
})(
  ({
    dirty,
    errors,
    isSubmitting,
    isValidating,
    isValid,
    onCancel,
    classes
  }) => {
    return (
      <BB8Form className={classes.form}>
        <Grid
          container={true}
          direction="row"
          wrap="nowrap"
          alignItems="center"
        >
          <Grid
            item={true}
            className={classes.formButtons}
            container={true}
            alignItems="center"
            justify="space-evenly"
          >
            <BB8FormField
              id="role-name"
              name="name"
              placeholder="e.g: ACBD"
              label="Role name"
              autoFocus={true}
              error={dirty && errors.name}
              maxLength={100}
              variant="filled"
              margin="dense"
              fullWidth={true}
            />

            <BB8Button
              className={classes.formButton}
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSubmitting || isValidating || !dirty || !isValid}
            >
              Save
            </BB8Button>
            <BB8Button
              className={classes.formButton}
              variant="outlined"
              color="secondary"
              onClick={onCancel}
            >
              Cancel
            </BB8Button>
          </Grid>
        </Grid>
      </BB8Form>
    );
  }
);

export default withStyles(formStyles)(RoleForm);
