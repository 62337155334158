import { ActionType, createAsyncAction, getType } from "typesafe-actions";

import { ApiError } from "../../../shared/services/ApiError";
import { IBulkJob } from "../../../models/BulkJob";
import { IGetOffersResponse } from "../../../models/IGetOffersResponse";
import { IOfferFormModel } from "../../../validation/validator";
import { IOfferFilter } from "../../../models/OffersRequestParams";
import { IPartner } from "../../../models/Partner";
import { IRootState } from "../../../store";
import OfferService from "../services/OffersService";
import PartnerService from "../../../shared/services/PartnerService";
import { ThunkDispatch } from "redux-thunk";
import { IOffersCounts, IOfferCategory } from "../../../models/Category";
import { IOfferPromotion } from "../../../models/Promotion";

const MAX_RETRIES = 5;

export enum OffersTabsValues {
  draft = "draft",
  changesPending = "changesPending",
  live = "live",
  staged = "staged",
  expired = "expired",
  disabled = "disabled"
}
export type OffersTabs =
  | OffersTabsValues.draft
  | OffersTabsValues.changesPending
  | OffersTabsValues.live
  | OffersTabsValues.staged
  | OffersTabsValues.expired
  | OffersTabsValues.disabled;

export interface IOfferByStatus {
  fetchedOffers: IGetOffersResponse;
  fetchedOffersTabsCounts: IOffersCounts;
}

// *OFFERS* async actions definitions for fetch offers
export const fetchOffers = createAsyncAction(
  "OFFERS/FETCH",
  "OFFERS/FETCH_SUCCESS",
  "OFFERS/FETCH_ERROR"
)<IOfferFilter, IOfferByStatus, ApiError>();

// async action types for fetch offers
export const fetchOffersRequest = getType(fetchOffers.request);
export const fetchOffersSuccess = getType(fetchOffers.success);
export const fetchOffersError = getType(fetchOffers.failure);

// async action for fetch offers
export function fetchOffersFlow(request: IOfferFilter) {
  return async (
    dispatch: ThunkDispatch<IRootState, void, OffersActionType>
  ): Promise<OffersActionType> => {
    dispatch(fetchOffers.request(request));
    try {
      // make offers call
      const fetchedOffers = OfferService.getOffers(request, request.tab);
      const fetchedOffersTabsCounts = OfferService.getOffersTabsCounts(request);

      const [offers, tabsCounts] = await Promise.all([
        fetchedOffers,
        fetchedOffersTabsCounts
      ]);

      return dispatch(
        fetchOffers.success({
          fetchedOffers: offers,
          fetchedOffersTabsCounts: tabsCounts
        })
      );
    } catch (error) {
      return dispatch(fetchOffers.failure(error));
    }
  };
}

// *BULK_JOBS* async actions definitions for fetch bulk jobs
export const fetchBulkJobs = createAsyncAction(
  "BULK_JOBS/FETCH",
  "BULK_JOBS/FETCH_SUCCESS",
  "BULK_JOBS/FETCH_ERROR"
)<void, IBulkJob[], ApiError>();

// async action types for fetch bulk jobs
export const fetchBulkJobsRequest = getType(fetchBulkJobs.request);
export const fetchBulkJobsSuccess = getType(fetchBulkJobs.success);
export const fetchBulkJobsError = getType(fetchBulkJobs.failure);

// async action for fetch bulk jobs
export function fetchBulkJobsFlow() {
  return async (
    dispatch: ThunkDispatch<IRootState, void, OffersActionType>,
    getSate: () => IRootState
  ): Promise<OffersActionType> => {
    dispatch(fetchBulkJobs.request());
    try {
      const fetchedBulkJobs = await OfferService.getBulkJobs();
      return dispatch(fetchBulkJobs.success(fetchedBulkJobs));
    } catch (error) {
      return dispatch(fetchBulkJobs.failure(error));
    }
  };
}

// *OFFERS* async actions definitions for publishing offers
export const publishOffers = createAsyncAction(
  "OFFERS/PUBLISH",
  "OFFERS/PUBLISH_SUCCESS",
  "OFFERS/PUBLISH_ERROR"
)<
  IOfferFormModel[],
  { publishedOffers: number; totalOffers: number },
  ApiError
>();

// async action types for fetch offers
export const publishOffersRequest = getType(publishOffers.request);
export const publishOffersSuccess = getType(publishOffers.success);
export const publishOffersError = getType(publishOffers.failure);

// async action for publish offers
export function publishOffersFlow(
  offers: IOfferFormModel[],
  postAction?: (data: any) => void,
  retryNumber: number = 0,
  publishedOffers: number = 0,
  totalOffers: number = 0
) {
  return async (
    dispatch: ThunkDispatch<IRootState, void, OffersActionType>,
    getState: () => IRootState
  ): Promise<OffersActionType> => {
    // Only dispatch the request on the first attempt to publish
    if (retryNumber === 0) {
      dispatch(publishOffers.request(offers));
    }

    // Only assign the total number of offers if it has never been set
    // We want the first original amount of offers to be stored as the total offers
    if (totalOffers === 0) {
      totalOffers = offers.length;
    }

    const offersToPublish: IOfferFormModel[] = Object.assign([], offers);

    try {
      // Synchronously make API calls to publish each offer sequentially
      // TODO: When Bulk Publish API will be ready, switch this to use that API instead of making sequential call
      for (const { offer, index } of offers.map((offer, index) => ({
        offer,
        index
      }))) {
        await OfferService.bulkSingleOffer(offer);
        offersToPublish.splice(index, 1);
        retryNumber = 0;
        publishedOffers += 1;
        // Sometimes, we want to execute actions after successful publishing of offers, like cleaning up the state,
        // or hiding a button. Therefore calling the function for each successful update
        if (postAction) {
          postAction(offer.id);
        }
        dispatch(publishOffers.success({ publishedOffers, totalOffers }));
      }
      return dispatch(fetchOffersFlow(getState().offers.filter));
    } catch (error) {
      // If we encounter a retriable error, retry the same request with the leftover offers that haven't been published yet.
      // This is being done to overcome the intermittent issue where the publishing takes longer than 30 seconds
      // therefore timing out on some requests (API Gateway's 30 second limit on open connections)
      if (error.shouldRetry && retryNumber < MAX_RETRIES) {
        return dispatch(
          publishOffersFlow(
            offersToPublish,
            postAction,
            retryNumber + 1,
            publishedOffers,
            totalOffers
          )
        );
      } else {
        // Generate user friendly message for 400 response
        if (error.statusCode === 400) {
          error.message =
            "This offer cannot be published. Please ensure all of the offer data is valid.";
        }
        dispatch(publishOffers.failure(error));
        return dispatch(fetchOffersFlow(getState().offers.filter));
      }
    }
  };
}

export const deleteOffers = createAsyncAction(
  "OFFERS/DELETE",
  "OFFERS/DELETE_SUCCESS",
  "OFFERS/DELETE_ERROR"
)<string[], { deletedOffers: string[] }, ApiError>();

// async action types for delete offers
export const deleteOffersRequest = getType(deleteOffers.request);
export const deleteOffersSuccess = getType(deleteOffers.success);
export const deleteOffersError = getType(deleteOffers.failure);

// async action for delete offers
export function deleteOffersFlow(
  offerIds: string[],
  postAction?: (data: any) => void
) {
  return async (
    dispatch: ThunkDispatch<IRootState, void, OffersActionType>,
    getState: () => IRootState
  ): Promise<OffersActionType> => {
    dispatch(deleteOffers.request(offerIds));

    try {
      if (process.env.REACT_APP_DELETE_OFFERS === "true") {
        await OfferService.deleteOffers(offerIds);
      }
      dispatch(deleteOffers.success({ deletedOffers: offerIds }));

      if (postAction) {
        postAction(offerIds);
      }

      return dispatch(fetchOffersFlow(getState().offers.filter));
    } catch (error) {
      error.message = "Offers could not be deleted";
      dispatch(deleteOffers.failure(error));
      return dispatch(fetchOffersFlow(getState().offers.filter));
    }
  };
}

// async action creator
export function fetchPartnersFlow() {
  return async (
    dispatch: ThunkDispatch<IRootState, void, OffersActionType>
  ): Promise<OffersActionType> => {
    dispatch(fetchPartners.request());
    // make service call here and deal with Promise
    try {
      const partners = await PartnerService.getPartners();
      return dispatch(fetchPartners.success(partners));
    } catch (error) {
      return dispatch(fetchPartners.failure(error));
    }
  };
}

// *Partners* async actions definition
export const fetchPartners = createAsyncAction(
  "PARTNERS/FETCH",
  "PARTNERS/FETCH_SUCCESS",
  "PARTNERS/FETCH_ERROR"
)<void, IPartner[], ApiError>();

// async actions types
export const fetchPartnersRequest = getType(fetchPartners.request);
export const fetchPartnersSuccess = getType(fetchPartners.success);
export const fetchPartnersError = getType(fetchPartners.failure);

// *Categories* async actions definitions for fetch categories
export const fetchCategories = createAsyncAction(
  "CATEGORIES/FETCH",
  "CATEGORIES/FETCH_SUCCESS",
  "CATEGORIES/FETCH_ERROR"
)<void, IOfferCategory[], ApiError>();

// async action types for fetch categories
export const fetchCategoriesRequest = getType(fetchCategories.request);
export const fetchCategoriesSuccess = getType(fetchCategories.success);
export const fetchCategoriesError = getType(fetchCategories.failure);

export function fetchCategoriesAsync() {
  return async (
    dispatch: ThunkDispatch<IRootState, void, OffersActionType>,
    getState: () => IRootState
  ): Promise<OffersActionType> => {
    dispatch(fetchCategories.request());

    // make service call here and deal with Promise
    try {
      let categories: IOfferCategory[];
      if (
        getState().offers.categories.length === 0 &&
        process.env.REACT_APP_CATEGORY === "true"
      ) {
        const categoriesResponse = await OfferService.fetchCategories();
        categories = categoriesResponse.results;
      } else {
        categories = getState().offers.categories;
      }
      return dispatch(fetchCategories.success(categories));
    } catch (error) {
      error.message = "Error while trying to fetch categories";
      return dispatch(fetchCategories.failure(error));
    }
  };
}

// *Promotions* async actions definitions for fetch promotions
export const fetchPromotions = createAsyncAction(
  "PROMOS/FETCH",
  "PROMOS/FETCH_SUCCESS",
  "PROMOS/FETCH_ERROR"
)<void, IOfferPromotion[], ApiError>();

// async action types for fetch promotions
export const fetchPromotionsRequest = getType(fetchPromotions.request);
export const fetchPromotionsSuccess = getType(fetchPromotions.success);
export const fetchPromotionsError = getType(fetchPromotions.failure);

export function fetchPromotionsAsync() {
  return async (
    dispatch: ThunkDispatch<IRootState, void, OffersActionType>,
    getState: () => IRootState
  ): Promise<OffersActionType> => {
    dispatch(fetchPromotions.request());

    // make service call here and deal with Promise
    try {
      let promotions: IOfferPromotion[];
      if (getState().offers.promotions.length === 0) {
        const promotionsResponse = await OfferService.fetchPromotions();
        promotions = promotionsResponse.results;
      } else {
        promotions = getState().offers.promotions;
      }
      return dispatch(fetchPromotions.success(promotions));
    } catch (error) {
      error.message = "Error while trying to fetch promotions";
      return dispatch(fetchPromotions.failure(error));
    }
  };
}

// *OFFERS* async actions definitions for publishing offers
export const disableOffers = createAsyncAction(
  "OFFERS/DISABLE",
  "OFFERS/DISABLE_SUCCESS",
  "OFFERS/DISABLE_ERROR"
)<string[], { disabledOffers: number; totalOffers: number }, ApiError>();
// async action types for disable offers
export const disableOffersRequest = getType(disableOffers.request);
export const disableOffersSuccess = getType(disableOffers.success);
export const disableOffersError = getType(disableOffers.failure);

// async action for disable offers
export function disableOffersFlow(
  idsToDisable: string[],
  postAction?: (data: any) => void,
  retryNumber: number = 0,
  disabledOffers: number = 0,
  totalOffers: number = 0,
  initRequest: boolean = true
) {
  return async (
    dispatch: ThunkDispatch<IRootState, void, OffersActionType>,
    getState: () => IRootState
  ): Promise<OffersActionType> => {
    // Only dispatch the request on the first attempt to disable
    if (retryNumber === 0 && initRequest) {
      dispatch(disableOffers.request(idsToDisable));
    }

    // Only assign the total number of offers if it has never been set
    // We want the first original amount of offers to be stored as the total offers
    if (totalOffers === 0) {
      totalOffers = idsToDisable.length;
    }

    const offersToDisable: string[] = Object.assign([], idsToDisable);

    try {
      // Synchronously make API calls to disable each offer sequentially
      for (const id of idsToDisable) {
        await OfferService.disableOffers(id);
        offersToDisable.splice(0, 1);
        retryNumber = 0;
        disabledOffers += 1;
        // Sometimes, we want to execute actions after successful disabling of offers, like cleaning up the state,
        // or hiding a button. Therefore calling the function for each successful update
        if (postAction) {
          postAction(id);
        }
        dispatch(disableOffers.success({ disabledOffers, totalOffers }));
      }
      return dispatch(fetchOffersFlow(getState().offers.filter));
    } catch (error) {
      // If we encounter a retriable error, retry the same request with the leftover offers that haven't been disabled yet.
      // This is being done to overcome the intermittent issue where the disabling takes longer than 30 seconds
      // therefore timing out on some requests (API Gateway's 30 second limit on open connections)
      if (error.shouldRetry && retryNumber < MAX_RETRIES) {
        return dispatch(
          disableOffersFlow(
            offersToDisable,
            postAction,
            retryNumber + 1,
            disabledOffers,
            totalOffers
          )
        );
      } else {
        // Generate user friendly message for 400 response
        if (error.statusCode === 400 && retryNumber === 0) {
          error.message =
            "This offer cannot be disabled. Please ensure it's in a non-disabled state.";
          dispatch(disableOffers.failure(error));
        } else if (error.statusCode === 400) {
          /* If we encounter an error that is not retriable, and the retry number is not 0
            We know the request was successful, this is because the 
            400 status code would have been returned before the initial timeout
            We can dispatch the success of this offer and treat this 400 as a success as it should be handled
          */
          var idForPostAction = idsToDisable.splice(0, 1);
          retryNumber = 0;
          disabledOffers += 1;

          if (postAction) {
            postAction(idForPostAction);
          }

          dispatch(disableOffers.success({ disabledOffers, totalOffers }));
          // Then proceed to disable the remaining offers as a new request
          if (disabledOffers < totalOffers) {
            return dispatch(
              disableOffersFlow(
                idsToDisable,
                postAction,
                retryNumber,
                disabledOffers,
                totalOffers,
                (initRequest = false)
              )
            );
          }
        } else {
          error.message = `There has been an error while disabling the offer(s). ${disabledOffers} of ${totalOffers} have been successfully disabled`;
          dispatch(disableOffers.failure(error));
        }
        return dispatch(fetchOffersFlow(getState().offers.filter));
      }
    }
  };
}

// *OFFERS* async actions definitions for enabling
export const enableOffers = createAsyncAction(
  "OFFERS/ENABLE",
  "OFFERS/ENABLE_SUCCESS",
  "OFFERS/ENABLE_ERROR"
)<string[], { enabledOffers: number; totalOffers: number }, ApiError>();
// async action types for enable offers
export const enableOffersRequest = getType(enableOffers.request);
export const enableOffersSuccess = getType(enableOffers.success);
export const enableOffersError = getType(enableOffers.failure);

// async action for enable offers
export function enableOffersFlow(
  idsToEnable: string[],
  postAction?: (data: any) => void,
  retryNumber: number = 0,
  enabledOffers: number = 0,
  totalOffers: number = 0,
  initRequest: boolean = true
) {
  return async (
    dispatch: ThunkDispatch<IRootState, void, OffersActionType>,
    getState: () => IRootState
  ): Promise<OffersActionType> => {
    // Only dispatch the request on the first attempt to enable
    if (retryNumber === 0 && initRequest) {
      dispatch(enableOffers.request(idsToEnable));
    }

    // Only assign the total number of offers if it has never been set
    // We want the first original amount of offers to be stored as the total offers
    if (totalOffers === 0) {
      totalOffers = idsToEnable.length;
    }

    const offersToEnable: string[] = Object.assign([], idsToEnable);

    try {
      // Synchronously make API calls to enable each offer sequentially
      for (const id of idsToEnable) {
        await OfferService.enableOffers(id);
        offersToEnable.splice(0, 1);
        retryNumber = 0;
        enabledOffers += 1;
        // Sometimes, we want to execute actions after success, like cleaning up the state,
        // or hiding a button. Therefore calling the function for each successful update
        if (postAction) {
          postAction(id);
        }
        dispatch(enableOffers.success({ enabledOffers, totalOffers }));
      }
      return dispatch(fetchOffersFlow(getState().offers.filter));
    } catch (error) {
      // If we encounter a retriable error, retry the same request with the leftover offers that haven't been enabled yet.
      // This is being done to overcome the intermittent issue where the enabling takes longer than 30 seconds
      // therefore timing out on some requests (API Gateway's 30 second limit on open connections)
      if (error.shouldRetry && retryNumber < MAX_RETRIES) {
        return dispatch(
          enableOffersFlow(
            offersToEnable,
            postAction,
            retryNumber + 1,
            enabledOffers,
            totalOffers
          )
        );
      } else {
        // Generate user friendly message for 400 response
        if (error.statusCode === 400 && retryNumber === 0) {
          error.message =
            "This offer cannot be enabled. Please ensure it's in a non-enabled state.";
          dispatch(enableOffers.failure(error));
        } else if (error.statusCode === 400) {
          /* If we encounter an error that is not retriable, and the retry number is not 0
            We know the request was successful, this is because the 
            400 status code would have been returned before the initial timeout
            We can dispatch the success of this offer and treat this 400 as a success as it should be handled
          */
          var idForPostAction = idsToEnable.splice(0, 1);
          retryNumber = 0;
          enabledOffers += 1;

          if (postAction) {
            postAction(idForPostAction);
          }

          dispatch(enableOffers.success({ enabledOffers, totalOffers }));

          // Proceed to enable remaining offers
          if (enabledOffers < totalOffers) {
            return dispatch(
              enableOffersFlow(
                idsToEnable,
                postAction,
                retryNumber,
                enabledOffers,
                totalOffers,
                (initRequest = false)
              )
            );
          }
        } else {
          error.message = `There has been an error while enabling the offer(s). ${enabledOffers} of ${totalOffers} have been successfully enabled.`;
          dispatch(enableOffers.failure(error));
        }
        return dispatch(fetchOffersFlow(getState().offers.filter));
      }
    }
  };
}

export type OffersActionType = ActionType<
  | typeof fetchOffers
  | typeof fetchPartners
  | typeof publishOffers
  | typeof fetchBulkJobs
  | typeof fetchCategories
  | typeof fetchPromotions
  | typeof deleteOffers
  | typeof disableOffers
  | typeof enableOffers
>;
