import { Typography } from "@material-ui/core";
import { BB8MainPage, BB8SystemType } from "bb8";
import { get, isEmpty } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import EditContentTabs from "../../../components/EditContentTabs";
import EditPageLayout from "../../../components/EditPageLayout";
import { IGroup } from "../../../models/Group";
import { IPartner } from "../../../models/Partner";
import { IRole, RoleAction } from "../../../models/Role";
import { IRbacUser, IUser } from "../../../models/User";
import { IRootState, withAuthStateToProps } from "../../../store";
import withAuthorization from "../../auth/components/Authorization";
import * as adminActions from "../actions";
import { RoleCard, UserCard } from "../components";
import { getUsersWithPartnersAndGroups } from "../selectors";
import {
  getGroupsTableData,
  getPartnersTableData,
  getSearchBar,
  getSuggestions,
  renderGroupLookupOption,
  renderPartnerLookupOption
} from "./GroupEditPage";
import "./GroupsEditPage.scss";
import "./GroupsListPage.scss";
import { IWithSearchProps } from "../../../components/WithSearch";

interface IUserEditPageProps
  extends IWithSearchProps,
    RouteComponentProps<{ userId: string }> {
  users: IRbacUser[];
  roles: IRole[];
  partners: IPartner[];
  groups: IGroup[];
  user: IUser;

  fetchGroups: () => void;
  fetchUser: (userId: string) => any;
  fetchUserGroups: (email: string) => void;
  fetchUserPartners: (email: string) => void;
  fetchPartners: () => void;
  fetchRoles: () => void;

  updateUserRole: (user: IRbacUser, role?: IRole) => void;
  onRemovePartnerFromUser: (user: IRbacUser, partner: IPartner) => void;
  onAddPartnerToUser: (user: any, partner: IPartner) => void;
  onRemoveGroupFromUser: (user: IRbacUser, group: IGroup) => void;
  onAddGroupToUser: (user: IRbacUser, group: IGroup) => void;
}
export class UserEditPage extends React.Component<IUserEditPageProps> {
  public state = {
    partnerSearch: ""
  };

  public componentDidMount() {
    this.props
      .fetchUser(this.props.match.params.userId)
      .then(({ payload }: { payload: any }) => {
        if (payload && payload.id) {
          this.props.fetchUserPartners(encodeURIComponent(payload.id));
          this.props.fetchUserGroups(encodeURIComponent(payload.id));
        }
      });
    this.props.fetchRoles();
    this.props.fetchPartners();
    this.props.fetchGroups();
  }

  public render() {
    const { roles, user } = this.props;

    const userToView: IRbacUser | undefined = this.getUserById();

    if (!userToView) {
      return null;
    }

    const usersPartners = userToView.partners ? userToView.partners : [];
    const partnersTableData = getPartnersTableData(
      usersPartners,
      this.handleRemovePartner(userToView)
    );

    const partnerSearchBar = getSearchBar(
      userToView,
      getSuggestions(this.state.partnerSearch, this.props.partners),
      this.onInputChange,
      this.onInputBlur,
      renderPartnerLookupOption,
      this.handleAddPartnerToUser,
      this.state.partnerSearch
    );

    const groupSearchBar = getSearchBar(
      userToView,
      getSuggestions(this.state.partnerSearch, this.props.groups),
      this.onInputChange,
      this.onInputBlur,
      renderGroupLookupOption,
      this.handleAddGroupToUser,
      this.state.partnerSearch
    );

    // TODO: Check if this is the right way to know if youre admin
    const groupsTableData = getGroupsTableData(
      userToView.groups,
      this.handleRemoveGroup(userToView),
      userToView.role ? userToView.role.name === "Administrator" : false
    );

    // Array with content to render for each tab
    const tabInfo = [
      {
        label: "Partners",
        searchBar: partnerSearchBar,
        tableData: partnersTableData
      },
      {
        label: "Groups",
        searchBar: groupSearchBar,
        tableData: groupsTableData
      }
    ];

    const userHasEditPermission = () : boolean => {
      if( user && Array.isArray(user.permissions)) {
        // user.role has to have "EDIT_USER"
        return user.permissions.some((permission: string) => permission === RoleAction.EDIT_USER)
      }
      return false
    }
    
    const canEditRole: boolean =
    userHasEditPermission() && user.email !== userToView.id;
    
    return (
      <BB8MainPage type={BB8SystemType.Billing}>
        <BB8MainPage.Padded>
          <EditPageLayout>
            <EditPageLayout.EntityName>
              <Typography variant="h5">User Details</Typography>
              <Typography paragraph={true} gutterBottom={true}>
                <Link to={`/user-management/users`}>
                  &larr; Back to Users List
                </Link>
              </Typography>
            </EditPageLayout.EntityName>
            <EditPageLayout.EntityDetails>
              <UserCard user={userToView} />
              <RoleCard
                role={userToView.role}
                roles={roles}
                canEditRole={canEditRole}
                onRoleChange={this.handleUserRoleChanged(userToView)}
              />
            </EditPageLayout.EntityDetails>
            <EditPageLayout.TabContainer>
              <EditContentTabs tabInfo={tabInfo} />
            </EditPageLayout.TabContainer>
          </EditPageLayout>
        </BB8MainPage.Padded>
      </BB8MainPage>
    );
  }

  private getUserById = () => {
    const { users } = this.props;
    const userId = decodeURIComponent(get(this.props, "match.params.userId"));
    return users.find(u => u.id === userId);
  };

  private onInputChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ partnerSearch: e.target.value });

  private onInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (
      isEmpty(e.relatedTarget) ||
      !(e.relatedTarget as any).classList.contains("chip")
    ) {
      this.setState({ partnerSearch: "" });
    }
  };

  private handleUserRoleChanged = (user: IRbacUser) => (role?: IRole) => {
    const { updateUserRole } = this.props;
    updateUserRole(user, role);
  };

  private handleRemovePartner = (user: IRbacUser) => (partner: IPartner) => {
    this.props.onRemovePartnerFromUser(user, partner);
  };

  private handleAddPartnerToUser = (user: IRbacUser, partner: IPartner) => {
    this.setState({ partnerSearch: "" });
    this.props.onAddPartnerToUser(user, partner);
  };

  private handleRemoveGroup = (user: IRbacUser) => (group: IGroup) => {
    this.props.onRemoveGroupFromUser(user, group);
  };

  private handleAddGroupToUser = (user: IRbacUser, group: IGroup) => {
    this.props.onAddGroupToUser(user, group);
  };
}

const mapStateToProps = (state: IRootState) => ({
  ...withAuthStateToProps(state),
  groups: state.administration.groups,
  partners: state.administration.partners,
  roles: state.administration.roles,
  users: getUsersWithPartnersAndGroups(state)
});

const mapDispatchToProps = {
  fetchGroups: adminActions.fetchGroupsFlow,
  fetchPartners: adminActions.fetchPartnersFlow,
  fetchRoles: adminActions.fetchRolesFlow,
  fetchUser: adminActions.fetchUserFlow,
  fetchUserGroups: adminActions.fetchUserGroupsFlow,
  fetchUserPartners: adminActions.fetchUserPartnersFlow,
  onAddGroupToUser: adminActions.saveGroupToUserFlow,
  onAddPartnerToUser: adminActions.savePartnerToUserFlow,
  onRemoveGroupFromUser: adminActions.removeGroupFromUserFlow,
  onRemovePartnerFromUser: adminActions.removePartnerFromUserFlow,
  updateUserRole: adminActions.saveUserRoleFlow
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthorization(UserEditPage, ["EDIT_USER"]));
