import React from "react";
import { Redirect, RouteComponentProps, Switch } from "react-router";
import { NavLink, Route } from "react-router-dom";
import { noop } from "rxjs";
import { BB8TopNav } from "../../../components/ConnectedTopNav";
import { IUser } from "../../../models/User";
import GroupEditPage from "./GroupEditPage";
import GroupsListPage from "./GroupsListPage";
import RolesListPage from "./RolesListPage";
import UserEditPage from "./UserEditPage";
import UsersListPage from "./UsersListPage";
import OffersDashboardPage from "./OffersDashboardPage";

export default function UserManagement({ match }: RouteComponentProps) {
  return (
    <>
      <BB8TopNav user={{} as IUser} showAuth={false} subHeader={true}>
        <NavLink to={`${match.path}/users`}>Users</NavLink>
        <NavLink to={`${match.path}/groups`}>Groups</NavLink>
        <NavLink to={`${match.path}/roles`}>Roles</NavLink>
        <NavLink to={`${match.path}/offers-dashboard`}>Stats</NavLink>
      </BB8TopNav>
      <Switch>
        <Route path={`${match.path}/users/:userId`} component={UserEditPage} />
        <Route path={`${match.path}/users`} component={UsersListPage} />
        <Route
          path={`${match.path}/groups/:groupId`}
          component={GroupEditPage}
        />
        <Route path={`${match.path}/groups`} component={GroupsListPage} />
        <Route path={`${match.path}/roles`} component={RolesListPage} />
        <Redirect
          from={`${match.path}`}
          to={`${match.path}/users`}
          exact={true}
        />
        <Route path={`${match.path}/offers-dashboard`} component={OffersDashboardPage} />
      </Switch>
    </>
  );
}
