import { IBB8AlertProps } from "bb8/lib/components/BB8Alert";
import { ActionType, createStandardAction, getType } from "typesafe-actions";
import { IModalProps } from "../../../models/Modal";

export const displayModal = createStandardAction("COMM/DISPLAY_MODAL")<
  IModalProps
>();

export const dismissModal = createStandardAction("COMM/DISMISS_MODAL")();

export const displayAlert = createStandardAction("COMM/DISPLAY_ALERT")<
  Pick<IBB8AlertProps, "variant"> & {
    message?: string | React.FunctionComponentElement<any>;
  }
>();

export const hideAlert = createStandardAction("COMM/HIDE_ALERT")();

export const showTargetAlert = createStandardAction("COMM/SHOW_TARGET_ALERT")();

export const hideTargetAlert = createStandardAction("COMM/HIDE_TARGET_ALERT")();

export const displayModalActionType = getType(displayModal);
export const dismissModalActionType = getType(dismissModal);

export const showTargetAlertActionType = getType(showTargetAlert);
export const hideTargetAlertActionType = getType(hideTargetAlert);

export type communicationActionTypes =
  | ActionType<typeof displayModal>
  | ActionType<typeof dismissModal>
  | ActionType<typeof displayAlert>
  | ActionType<typeof hideAlert>
  | ActionType<typeof showTargetAlert>
  | ActionType<typeof hideTargetAlert>;
