import { isEmpty } from "lodash";
import { IUser } from "../../../models/User";
import { IPermissionConfig } from "../types";
export class Authorizer {
  private static instance: Authorizer;
  private permissionConfig?: IPermissionConfig;
  private constructor() {
    // this.permissionConfig = permissionConfig;
  }
  public init(permissionConfig?: IPermissionConfig) {
    this.permissionConfig = permissionConfig;
  }
  public static get Instance() {
    return this.instance || (this.instance = new this());
  }
  public checkPermissions(
    permissions: string[],
    user?: IUser,
    hasAllPermissions = true
  ): boolean {
    // check if there's an user
    if (
      !user ||
      isEmpty(user) ||
      !user.permissions ||
      user.permissions.length === 0
    ) {
      return false;
    }

    // check if permissions config is loaded
    if (!this.permissionConfig) {
      return false;
    }
    if (isEmpty(this.permissionConfig)) {
      return false;
    }

    // check if the user has the right permissions
    if (hasAllPermissions) {
      return permissions.every(permission =>
        this.userHasSpecificPermission(user, permission)
      );
    } else {
      return permissions.some(permission =>
        this.userHasSpecificPermission(user, permission)
      );
    }
  }

  private userHasSpecificPermission(user: IUser, permission: string) {
    return (
      !isEmpty(this.permissionConfig!.mappings[permission]) &&
      user.permissions.some(permissionName => permissionName === permission)
    );
  }

  public isAssignedToRole(rejectRoles?: string[], user?: IUser): boolean {
    if (
      !user ||
      isEmpty(user) ||
      !user.permissions ||
      user.permissions.length === 0
    ) {
      return false;
    }
    if (rejectRoles && rejectRoles.includes(user.role.toUpperCase())) {
      return false;
    }
    return true;
  }
}
