import { isEqual }                  from "lodash";
import { urls }                     from "../../../shared/constants";
import apiService, { IApiService }  from "../../../shared/services/api.service";
import moment                       from "moment";

interface IChartData {
  date           : string,
  offers         : number,
  partnersTotal  : number,
  scope          : string
}

interface IFilterObject {
  dateContext : string,
  partnerId   : string,
  regions     : string,
  timeRange   : number,
  timeScope   : string
}

export class ChartService {
  private apiService: IApiService;
  constructor(apiServiceDep?: any) {
    this.apiService = apiServiceDep || apiService;
  }

  public async getChartData(filterObj: IFilterObject) {
    let chartData: IChartData[] = [];

    for(let i=0; i < filterObj.timeRange; i++) {
      const requestUrl = this.getChartRequestUrl(filterObj, i);
      let periodItem = "";
      if (filterObj.timeScope.toLowerCase() === "days") {
        periodItem = moment(new Date()).add(i - (Math.floor(filterObj.timeRange/2)),'days').format("MMM-DD");
      } else if (filterObj.timeScope.toLowerCase() === "weeks") {
        const weekDay = moment(new Date()).add(i - (Math.floor(filterObj.timeRange/2)),'weeks').format("YYYY-MM-DD");
        periodItem = 'Week of ' + moment(weekDay).startOf('week').format("DD-MMM");
      } else if (filterObj.timeScope.toLowerCase() === "months") {
        periodItem = moment(new Date()).add(i - (filterObj.timeRange-2),'months').format("MMMM-YYYY");
      }
      await this.getChartItemData(requestUrl)
            .then((singleItemData: any) => {
              chartData.push({
                "date"          : periodItem,
                "partnersTotal" : 0,
                "offers"        : singleItemData.totalCount,
                "scope"         : filterObj.timeScope
              });
            })
            .catch();
    }

    return chartData;
  }

  // need to figure out dependency of this shift and number of bars
  // so far have it hardcoded
  public getLineStartShift = (chartData: IChartData[]) => {
    switch (chartData.length) {
      case 5:  return 26;
      case 7:  return -4;
      case 9:  return -20;
      case 11: return -32;
      case 13: return -40;
      case 15: return -44;
      default: return 0;
    }
  }

  private getChartItemData (requestUrl: string | Request): Promise<IChartData[]> {
    return this.apiService.ajax<IChartData[]>(
      `${urls.offersController.offers}?${requestUrl}`
    );
  }

  private getChartRequestUrl(filterObj: IFilterObject, chartItemNumber: number) {
    let startDate   = "",
        endDate     = "",
        dateContextUrlPart = filterObj.dateContext ? '&dateContext=' + filterObj.dateContext : "",
        regionsUrlPart     = filterObj.regions     ? '&regions='     + filterObj.regions.toUpperCase() : "",
        partnerIdUrlPart   = filterObj.partnerId   ? '&partnerId='    + filterObj.partnerId : "";

    if (filterObj.timeScope.toLowerCase() === "days") {
      startDate = moment(new Date()).add(chartItemNumber - (Math.floor(filterObj.timeRange/2)),'days').format("YYYY-MM-DD");
      endDate   = moment(new Date()).add(chartItemNumber - (Math.floor(filterObj.timeRange/2)),'days').format("YYYY-MM-DD");
    } else if (filterObj.timeScope.toLowerCase() === "weeks") {
      const weekPoint = moment(new Date()).add(chartItemNumber - (Math.floor(filterObj.timeRange/2)),'weeks').format("YYYY-MM-DD");
      startDate = moment(weekPoint).startOf('week').format("YYYY-MM-DD");
      endDate   = moment(weekPoint).endOf('week').format("YYYY-MM-DD");
    } else if (filterObj.timeScope.toLowerCase() === "months") {
      const monthPoint = moment(new Date()).add(chartItemNumber - (filterObj.timeRange-2),'months').format("YYYY-MM-DD");
      startDate = moment(monthPoint).startOf('month').format("YYYY-MM-DD");
      endDate   = moment(monthPoint).endOf('month').format("YYYY-MM-DD");
    }

    return `displayDate=${startDate}&endDate=${endDate}${dateContextUrlPart}${regionsUrlPart}${partnerIdUrlPart}`;
  }
}

export default new ChartService();
