import { isEqual } from "lodash";
import { IGroup } from "../../../models/Group";
import { IPartner } from "../../../models/Partner";
import { IUser, IRbacUser } from "../../../models/User";
import { urls } from "../../../shared/constants";

import apiService, { IApiService } from "../../../shared/services/api.service";

export class GroupService {
  private apiService: IApiService;
  constructor(apiServiceDep?: any) {
    this.apiService = apiServiceDep || apiService;
  }

  public getGroups(): Promise<IGroup[]> {
    return this.apiService.ajax<IGroup[]>(urls.groupsController.groups);
  }
  public getGroup(groupId: string): Promise<IGroup> {
    return this.apiService.ajax<IGroup>(
      urls.groupsController.groups + "/" + groupId
    );
  }
  public addGroups(group: IGroup): Promise<IGroup> {
    const request: Request = new Request(urls.groupsController.groups, {
      ...this.apiService.getDefaultRequestInit(),
      body: JSON.stringify(group),
      method: "POST"
    });

    return this.apiService.ajax<IGroup>(request);
  }

  public savePartnerToGroup(group: IGroup, partner: IPartner): Promise<IGroup> {
    const request: Request = new Request(
      urls.groupsController.groups + "/" + group.id + "/partners",
      {
        ...this.apiService.getDefaultRequestInit(),
        body: JSON.stringify({ partnerId: partner.id }),
        method: "POST"
      }
    );

    return this.apiService.ajax(request);
  }

  public removePartnerFromGroup(
    group: IGroup,
    partner: IPartner
  ): Promise<IGroup> {
    const request: Request = new Request(
      urls.groupsController.groups + "/" + group.id + "/partners/" + partner.id,
      {
        ...this.apiService.getDefaultRequestInit(),
        method: "DELETE"
      }
    );
    return this.apiService.ajax(request);
  }

  public getUsersInGroup(
    groupId: string
  ): Promise<Array<{ userId: string; groupId: number }>> {
    return this.apiService.ajax(
      urls.groupsController.groups + "/" + groupId + "/users"
    );
  }

  public groupsComparer(group1: IGroup, group2: IGroup): boolean {
    return isEqual(group1.id, group2.id);
  }
}

export default new GroupService();
