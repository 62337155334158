import { IBB8AlertProps } from "bb8/lib/components/BB8Alert";
import { connectRouter } from "connected-react-router";
import { RouterState } from "connected-react-router";
import { History } from "history";
import { combineReducers, Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";
import administrationReducer from "../features/administration/reducers";
import * as loginActions from "../features/auth/actions";
import loginReducer from "../features/auth/reducer";
import authService from "../features/auth/service";
import { IAuthState } from "../features/auth/types";
import * as communicationActions from "../features/communication/actions";
import communicationReducer from "../features/communication/reducers";
import offersReducer from "../features/offer-submission/reducers";
import { IOffersState } from "../features/offer-submission/types";
import { ICommunicationState } from "../models/Communication";
import { IModalProps } from "../models/Modal";
import { IUser } from "../models/User";
import { IAdministrationState } from "../types";

export interface IRootState {
  administration: IAdministrationState;
  auth: IAuthState;
  router?: RouterState;
  communication: ICommunicationState;
  offers: IOffersState;
}

const rootReducer = (history: History) =>
  combineReducers<IRootState>({
    administration: administrationReducer,
    auth: loginReducer,
    communication: communicationReducer,
    offers: offersReducer,
    router: connectRouter(history)
  });

export interface IWithCommunicationProps {
  isLoading: boolean;
  modalContent?: IModalProps;

  displayModal: (props: IModalProps) => void;

  hideModal: () => void;
}

export function withAuthStateToProps<T = object>(
  state: IRootState,
  maps?: T
): IAuthState & T {
  return {
    isLoggedIn: !!state.auth.user,
    user: state.auth.user,
    ...(maps as any)
  };
}

export interface IWithAuthDispatchToProps {
  login: () => IUser;
  logout: () => void;
}
export function withAuthDispatchToProps<T = object>(
  dispatch: ThunkDispatch<IRootState, void, loginActions.loginActionTypes>,
  maps?: T
): IWithAuthDispatchToProps & T {
  return {
    login: () => dispatch(loginActions.asyncLogin()),
    logout: () => dispatch(loginActions.asyncLogout()),
    ...(maps as any)
  };
}

export function withCommunicationStateToProps<T = object>(
  state: IRootState,
  maps?: T
): IWithCommunicationProps & T {
  return {
    alertContent: state.communication.alertContent,
    isLoading: state.communication.pendingRequests > 0,
    modalContent: state.communication.modalContent,
    shouldShowAlert:
      state.communication.pendingRequests === 0 &&
      !!state.communication.alertContent,
    showTargetAlert: state.communication.showTargetAlert,
    ...(maps as any)
  };
}
export function withCommunicationDispatchToProps(
  dispatch: Dispatch,
  maps?: any
) {
  return {
    displayModal: (modalProps: IModalProps) =>
      dispatch(communicationActions.displayModal(modalProps)),
    hideModal: () => dispatch(communicationActions.dismissModal()),
    showTargetAlert: () => dispatch(communicationActions.showTargetAlert()),
    hideTargetAlert: () => dispatch(communicationActions.hideTargetAlert()),
    ...maps
  };
}

export default rootReducer;
