import React, { ReactNode, useState } from "react";

import { Theme } from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles from "@material-ui/core/styles/withStyles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell, { TableCellProps } from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { BB8Button } from "bb8";
import { path } from "rambda";

interface IBB8Table {
  tableId?: string;
  cols: IColProps[];
  detailsComponent?: (data: any) => ReactNode;
  data: any[];
  classes?: any;
  noDataComponent?: JSX.Element;
  pagination?: boolean;
  rowsPerPage?: number;
  sortChange?: any;
  sorting?: any;
  onRowClick?: (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    data: any
  ) => void;
}

export interface IColProps {
  id: string | "colSpan";
  title: ReactNode;
  sort?: boolean;
  style?: any;
  headerCellProps?: TableCellProps;
  tableCellProps?: TableCellProps;
  tableCellTemplate?: React.FunctionComponent<ITemplateCellProps>;
}

export interface ITemplateCellProps {
  id: string;
  data: any;
  row: any;
}
const CustomTableCell = withStyles((theme: Theme) =>
  createStyles({
    body: {
      backgroundColor: "#FFFFFF",
      color: "#231F20",
      fontFamily: "Omnes",
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 4,
      opacity: 0.9,
      padding: "4px 16px 4px 16px"
    },
    head: {
      alignSelf: "flex-start",
      backgroundColor: "#F2F5F7",
      borderBottomColor: "#CCD9E0",
      borderBottomWidth: 2,
      color: theme.palette.common.black,
      fontFamily: "Omnes",
      fontSize: 14,
      fontWeight: 500,
      padding: "4px 16px 4px 16px",
      position: "sticky",
      top: 0,
      zIndex: 10
    }
  })
)(TableCell);

const styles = createStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
    width: "100%"
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: "rgb(189, 211, 222)"
    }
  },
  eventBasedRow:{
    backgroundColor: "rgb(165, 43, 241) !important"
  }
}));

class BB8Table extends React.Component<IBB8Table> {
  public state = {
    page: 0,
    rowsPerPage: this.props.pagination ? this.props.rowsPerPage || 25 : 9999
  };

  public handleChangePage = (event: any, page: any) => {
    this.setState({ page });
  };

  public handleChangeRowsPerPage = (event: any) => {
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  public render() {
    const {
      tableId,
      cols,
      data,
      classes,
      onRowClick,
      noDataComponent,
      pagination,
      sorting,
      sortChange,
      detailsComponent
    } = this.props;
    const { page, rowsPerPage } = this.state;
    return (
      <Table id={tableId} className={classes.table}>
        <TableHead>
          {pagination && (
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                colSpan={cols.length}
                count={data.length}
                rowsPerPage={rowsPerPage || 9999}
                page={page}
                SelectProps={{
                  native: true
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </TableRow>
          )}
          <TableRow>
            {cols.map(({ title, id, sort, headerCellProps }, index: number) => (
              <CustomTableCell key={index} {...headerCellProps}>
                {sort ? (
                  <TableSortLabel
                    active={sorting.field === id}
                    direction={sorting.direction}
                    onClick={() => sortChange(id)}
                  >
                    {title}
                  </TableSortLabel>
                ) : (
                  <span>{title}</span>
                )}
              </CustomTableCell>
            ))}
          </TableRow>
        </TableHead>
        <BB8TableBody
          cols={cols}
          data={data}
          classes={classes}
          noDataComponent={noDataComponent}
          rowsPerPage={rowsPerPage || 9999}
          page={page}
          onRowClick={onRowClick}
          detailsComponent={detailsComponent}
        />
      </Table>
    );
  }
}

const BB8TableBody = ({
  cols,
  data,
  classes,
  noDataComponent,
  rowsPerPage,
  page,
  onRowClick,
  detailsComponent
}: {
  cols: IColProps[];
  data: any[];
  classes: any;
  noDataComponent?: JSX.Element;
  rowsPerPage: number;
  page: number;
  onRowClick?: (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    data: any
  ) => void;
  detailsComponent?: (data: any) => ReactNode;
}) => {
  const isExpandable = Boolean(detailsComponent);
  const [expand, setExpanded] = useState<{ [key: number]: boolean }>({});
  if (!data || !data.length) {
    return (
      <TableBody>
        <TableRow>
          <CustomTableCell colSpan={cols.length}>
            {noDataComponent ? noDataComponent : "No data"}
          </CustomTableCell>
        </TableRow>
      </TableBody>
    );
  }
  return (
    <TableBody>
      {data
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, i: number) => {
          return (
            <React.Fragment key={i}>
              <TableRow
                key={`row-1-${i}`}
                className={`${classes.row} ${row.eventBasedOffer ? classes.eventBasedRow :''}`}
                onClick={event =>
                  onRowClick ? onRowClick(event, row) : undefined
                }
                hover={Boolean(onRowClick)}
              >
                {cols
                  .filter(({ id }) => id !== "colSpan")
                  .map(
                    (
                      {
                        id,
                        style,
                        tableCellProps,
                        tableCellTemplate: TableCellTemplate
                      },
                      index: number
                    ) => {
                      return (
                        <CustomTableCell
                          key={index}
                          style={style}
                          {...tableCellProps}
                        >
                          {TableCellTemplate ? (
                            <TableCellTemplate
                              id={id}
                              data={path(id, row)}
                              row={row}
                            />
                          ) : (
                            path(id, row)
                          )}
                        </CustomTableCell>
                      );
                    }
                  )}
              </TableRow>
              {isExpandable && detailsComponent && (
                <TableRow key={`row-2-${i}`}>
                  <TableCell>{}</TableCell>
                  <TableCell colSpan={6}>{detailsComponent(row)}</TableCell>
                </TableRow>
              )}
            </React.Fragment>
          );
        })}
    </TableBody>
  );
};

export default withStyles(styles)(BB8Table);
