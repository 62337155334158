import { ILocalizedObject } from "../validation/validator";
import { get, find } from "lodash";

export interface IOfferPromotion {
  active: boolean;
  id: string;
  translations: ILocalizedObject;
}

export interface IOfferPromotionResponse {
  results: IOfferPromotion[];
}

export function getPromoTagName(
  promotions: IOfferPromotion[],
  id?: string
): ILocalizedObject | undefined {
  return get(
    find(promotions, promo => promo !== undefined && promo.id === id),
    "translations"
  );
}
