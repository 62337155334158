import classNames from 'classnames'
import React from 'react'

import { Icon } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'

import errorIcon from '../shared/assets/icons/alert-error.svg'
import doneIcon from '../shared/assets/icons/bb8-success.svg'
import closeIcon from '../shared/assets/icons/close.svg'
import warningIcon from '../shared/assets/icons/warning-orange.svg'

const VariantIcon: { [type: string]: React.SFC<any> } = {
  error: ({ classes }: { classes: any }) => <img className={classes.iconVariant} src={errorIcon} />,
  info: ({ classes }: { classes: any }) => <img className={classes.iconVariant} src={closeIcon} />,
  success: ({ classes }: { classes: any }) => (
    <img className={classes.iconVariant} src={doneIcon} />
  ),
  warning: ({ classes }: { classes: any }) => (
    <img className={classes.iconVariant} src={warningIcon} />
  )
}

const snackbarContentStyles = (theme: Theme) =>
  createStyles({
    closeIcon: {
      backgroundImage: 'url(' + require('../shared/assets/icons/close.svg') + ')',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '8px',
      fontSize: 20
    },
    error: {
      backgroundColor: theme.palette.error.light
    },
    iconVariant: {
      marginRight: theme.spacing.unit * 2,
      opacity: 0.9
    },
    info: {
      backgroundColor: theme.palette.secondary.dark
    },
    message: {
      alignItems: 'center',
      color: '#3A3733',
      display: 'flex'
    },
    root: {
      borderRadius: '2px',
      marginBottom: '16px'
    },
    success: {
      backgroundColor: (theme.palette as any).alert.success
    },
    warning: {
      backgroundColor: (theme.palette as any).status.warning
    }
  })

/**
 * Wrapper for material-ui alert component. for more details see https://material-ui.com/demos/snackbars/
 * @param props
 */
function BB8SnackbarContent(props: any) {
  const { classes, className, message, onClose, variant, children, ...other } = props
  const AlertIcon = VariantIcon[variant]

  return (
    <SnackbarContent
      className={classNames(classes.root, classes[variant], className)}
      aria-describedby="target-alert-snackbar"
      message={
        <>
          <span id="target-alert-snackbar" className={classes.message}>
            <AlertIcon classes={classes} />
            {message}
            {children}
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={onClose}
            >
              <Icon className={classes.closeIcon} />
            </IconButton>
          </span>
        </>
      }
      {...other}
    />
  )
}

const BB8SnackbarContentWrapper = withStyles(snackbarContentStyles)(BB8SnackbarContent)

const snackbarStyles = (theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing.unit
    },
    targetAlert: {
      top: "10px",
      bottom: "unset"
    }
  })

export interface ICustomAlertProps extends WithStyles<typeof snackbarStyles> {
  autoHideDuration?: number
  className?: string
  message?: Error | string
  onClose?: (e: any, reason: string) => void
  variant: 'success' | 'warning' | 'error' | 'info'
  show: boolean
  children?: any;
}

let cache: any[] = []
function jsonReplacer(key: string, value: string) {
  if (typeof value === 'object' && value !== null) {
    if (cache.indexOf(value) !== -1) {
      // Duplicate reference found
      try {
        // If this value does not reference a parent it can be deduped
        return JSON.parse(JSON.stringify(value))
      } catch (error) {
        // discard key if value cannot be deduped
        return
      }
    }
    // Store value in our collection
    cache.push(value)
  }
  return value
}

/**
 * Tries to parse the error message and display something meaningful
 * @param message any error message in any format
 */
function messageParser(message: any): string {
  cache = []
  if (!message) {
    return ''
  }
  if (typeof message === 'string') {
    return message
  }
  if (typeof message === 'object') {
    if (Array.isArray(message)) {
      return message.map(messageParser).join(' | ')
    }

    if (message instanceof Error) {
      return `${message.name} ${message.message}`
    }
    return JSON.stringify(message, jsonReplacer, 2)
  }
  return message.toString()
}

function BB8CustomAlert({ autoHideDuration, message, onClose, variant, show, classes, children }: ICustomAlertProps) {
  const innerMessage = message instanceof Error ? message.message : message
  return (
    <Snackbar
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'top'
      }}
      open={show}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      className={classes.targetAlert}
    >
      <BB8SnackbarContentWrapper
        className="bb8-snackbar-content-wrapper"
        onClose={onClose}
        variant={variant}
        message={messageParser(innerMessage)}
      >
        {children}
      </BB8SnackbarContentWrapper>
    </Snackbar>
  )
}

export default withStyles(snackbarStyles)(BB8CustomAlert)
