// import { install } from "@material-ui/styles";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import App from "./App";
import * as authActions from "./features/auth/actions";
import { UserRoles } from "./features/auth/pages/UserRoles";
import authService from "./features/auth/service";
import "./index.scss";
import configureStore from "./setupRedux";
import { IRootState } from "./store";

// install();
export const store = configureStore();

const checkAuth = new Promise(resolve => {
  if (authService.isLoggedIn) {
    const user = authService.getUser();
    if (user) {
      return (store.dispatch as ThunkDispatch<IRootState, void, Action>)(
        authActions.asyncLogin(user.role as UserRoles)
      ).then(auth => {
        resolve();
      });
    }
  } else {
    resolve();
  }
});

const fetchPermissionConfig = new Promise(resolve => {
  (store.dispatch as ThunkDispatch<IRootState, void, Action>)(
    authActions.asyncPermissionsConfigFlow()
  ).then(auth => {
    resolve();
  });
});

const render = (Component: any) => {
  return ReactDOM.render(
    <Provider store={store}>
      <Component />
    </Provider>,
    document.getElementById("root")
  );
};

Promise.all([checkAuth, fetchPermissionConfig]).then(() => {
  render(App);
});

if ((module as any).hot) {
  (module as any).hot.accept("./App", () => {
    const NextApp = require("./App").default;
    render(NextApp);
  });
}
