import { Tooltip } from "@material-ui/core";
import { BB8Button } from "bb8";
import React from "react";
import { IUser } from "../models/User";
import "./BB8AuthPanel.scss";
import { get } from "lodash";

const submitIssue = (user: IUser): string => {
  const data = {
    name: get(user, "name"),
    email: get(user, "email"),
    subject: "Post V2: ",
    platform: process.env.REACT_APP_ENV || "",
    userAgent: navigator.userAgent
  };

  const body =
    `Issue Description: \n` +
    `\n` +
    `\n` +
    `Steps to Reproduce: \n` +
    `\n` +
    `\n` +
    `Additional Details or Comments: \n` +
    `\n` +
    `\n` +
    `----\n` +
    `(!) Do not edit the information below!\n` +
    `||Name|${data.name.trim()}|\n` +
    `||Email|${data.email.trim()}|\n` +
    `||Environment|${data.platform.trim()}|\n` +
    `||User Agent|${data.userAgent.trim()}|\n`;

  const mailUrl: string = `mailto:offersjira@loyalty.com?cc=things@loyalty.com,offervaluestream@loyalty.com&subject=${encodeURIComponent(
    data.subject
  )}&body=${encodeURIComponent(body)}`;

  return mailUrl;
};

const BB8AuthPanel = ({
  user,
  logout
}: {
  user: IUser;
  logout: () => void;
}) => {
  if (user) {
    return (
      <section className="user-profile">
        <Tooltip
          title={
            <>
              <p>
                <small>email: </small>
                {user.email}
              </p>
              <p>
                <small>role: </small>
                {user.role}
              </p>
            </>
          }
        >
          <div className="user">
            {/* <img
              className="avatar"
              alt="avatar"
              src={require("../assets/images/bb8-person_outline-24px.svg")}
            /> */}
            <span className="user-name">{user.name}</span>
          </div>
        </Tooltip>
        <div className="actions">
          <BB8Button
            id="bb8-btn-auth-submit-issue"
            color="primary"
            size="small"
            onClick={() => {
              const mailURL = submitIssue(user);
              window.open(mailURL, "_self");
            }}
          >
            Submit Issue
          </BB8Button>
        </div>
        <div className="actions">
          <BB8Button
            id="bb8-btn-auth-signout"
            color="primary"
            size="small"
            onClick={logout}
          >
            Signout
          </BB8Button>
        </div>
      </section>
    );
  } else {
    return (
      <section className="user-profile">
        <div className="actions">
          <BB8Button id="bb8-btn-auth-signin" color="primary" size="small">
            Signin
          </BB8Button>
        </div>
      </section>
    );
  }
};

export default BB8AuthPanel;
