import { CircularProgress, IconButton } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import classNames from "classnames";
import Downshift, { StateChangeOptions } from "downshift";
import * as React from "react";
import { Omit } from "utility-types";
// import { ReactComponent as Close } from '../assets/images/bb8-close.svg'
import { IBB8FieldProps } from "./BB8FormField";
import "./BB8Lookup.scss";

export interface IRenderOptionProps<T> extends WithStyles {
  id?: string;
  highlightedIndex: number | null;
  index: number;
  itemProps?: object;
  selectedItem: string;
  option: T;
  placeholder?: string;
}

const renderInput: React.SFC<any> = (inputProps: any) => {
  const {
    id,
    InputProps,
    classes,
    clearSelection,
    loading,
    ref,
    ...other
  } = inputProps;

  return (
    <>
      <input
        type="text"
        className={classNames(
          "bb8-search",
          "am-input-text__field",
          classes.inputInput,
          classes.inputRoot
        )}
        ref={ref}
        {...InputProps}
        {...other}
      />
      <IconButton
        disabled={InputProps.disabled}
        className={classNames("bb8-search-icon", {
          show: !!InputProps.value && !loading && !InputProps.disabled
        })}
        onClick={clearSelection}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
          <path d="M0 0h24v24H0z" fill="none" />
        </svg>
      </IconButton>
      <CircularProgress
        className={classNames("bb8-search-icon", { show: loading })}
      />
    </>
  );
};

const defaultRenderOption: React.SFC<IRenderOptionProps<any>> = ({
  id,
  classes,
  highlightedIndex,
  index,
  itemProps,
  selectedItem,
  option
}) => {
  const isHighlighted = highlightedIndex === index;
  // const isSelected = (selectedItem || "").indexOf(option) > -1;
  return (
    <MenuItem
      {...itemProps}
      key={option}
      selected={isHighlighted}
      component="div"
      className={classes.menuItem}
    >
      {option}
    </MenuItem>
  );
};

const styles = createStyles((theme: Theme) => ({
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`
  },
  container: {
    alignItems: "center",
    display: "flex",
    position: "relative"
  },
  inputInput: {
    width: "auto"
  },
  inputRoot: {
    flexWrap: "wrap"
  },
  menuItem: {
    fontFamily: theme.typography.fontFamily
  },
  popper: {
    zIndex: 9999
  },
  root: {
    fontFamily: theme.typography.fontFamily
  }
}));

export interface IBB8LookupProps<T = any>
  extends Omit<IBB8FieldProps, "classes">,
  WithStyles {
  id?: string;
  placeholder?: string;
  options: T[];
  onInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onInputBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onSelect?: (item: any) => void;
  renderOption?: React.SFC<IRenderOptionProps<T>>;
  itemToString?: (item: T) => string;
  loading?: boolean;
  selectedItem?: T;
  shouldOpenOnFocus?: boolean;
  valuePath?: string;
  inputValue?: string;
}

class BB8LookupInner extends React.Component<IBB8LookupProps> {
  private popperNode: any;

  public render() {
    const {
      id,
      classes,
      defaultValue,
      disabled,
      itemToString,
      loading,
      placeholder,
      onSelect,
      options,
      renderOption,
      name,
      inputValue,
      value,
      className
    } = this.props;

    return (
      <div
        className={classNames(className, "bb8-search-wrapper", classes.root)}
      >
        <Downshift
          id={id}
          onChange={onSelect}
          itemToString={itemToString}
          selectedItem={value}
          inputValue={inputValue}
        >
          {({
            clearSelection,
            getInputProps,
            getItemProps,
            getMenuProps,
            highlightedIndex,
            isOpen,
            selectedItem,
            openMenu
          }) => (
              <div className={classes.container}>
                {renderInput({
                  InputProps: getInputProps({
                    defaultValue,
                    disabled,
                    name,
                    onBlur: this.handleInputBlur,
                    onChange: this.handleInputChange,
                    onFocus: (e: any) => this.handleInputFocus(e, openMenu),
                    placeholder
                  }),
                  classes,
                  clearSelection,
                  loading,
                  ref: (node: any) => {
                    this.popperNode = node;
                  }
                })}
                <Popper
                  open={isOpen}
                  anchorEl={this.popperNode}
                  className={classNames(`${className}-popper`, classes.popper)}
                >
                  <div
                    {...(isOpen
                      ? getMenuProps({}, { suppressRefError: true })
                      : {})}
                  >
                    <Paper
                      square={true}
                      style={{
                        marginTop: 8,
                        maxHeight: "300px",
                        overflowY: "auto",
                        width: this.popperNode
                          ? this.popperNode.clientWidth
                          : null
                      }}
                    >
                      {options && options.length
                        ? options.map((option, index) =>
                          (renderOption || defaultRenderOption)({
                            classes,
                            highlightedIndex,
                            id,
                            index,
                            itemProps: getItemProps({ item: option }),
                            option,
                            selectedItem
                          })
                        )
                        : null}
                    </Paper>
                  </div>
                </Popper>
              </div>
            )}
        </Downshift>
      </div>
    );
  }

  private handleInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (this.props.onInputBlur) {
      this.props.onInputBlur(e);
    }
  };

  private handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (this.props.onInputChange) {
      this.props.onInputChange(e);
    }
  };

  private handleInputFocus = (
    e: React.FocusEvent<HTMLInputElement>,
    openMenu: any
  ) => {
    if (this.props.shouldOpenOnFocus) {
      openMenu();
    }
  };
}

export default withStyles(styles)(BB8LookupInner);
