import {
  Checkbox,
  createStyles,
  Grid,
  Paper,
  Theme,
  withStyles,
  WithStyles
} from "@material-ui/core";
import { BB8Button, BB8Form, BB8MainPage, BB8SystemType, BB8Table } from "bb8";
import { IColProps, ITemplateCellProps } from "bb8/lib/components/BB8Table";
import { isEmpty, sortBy } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import {
  IPermission,
  IRole,
  IRoleMatrix,
  IRolePermission
} from "../../../models/Role";
import { IRootState, withAuthStateToProps } from "../../../store";
import withAuthorization from "../../auth/components/Authorization";
import { getRoleMatrix } from "../../auth/selector";
import * as adminActions from "../actions";
import RoleForm from "../components/RoleForm";

const actionDescriptions: { [key: string]: string } = {
  CREATE_OFFER: "Create Offer(s)",
  EDIT_OFFER: "Edit Offer(s)",
  PUBLISH_OFFER: "Publish Offer(s)",
  VIEW_OFFER: "View Offer(s)",
  DELETE_OFFER: "Delete Offer(s)",
  DISABLE_OFFER: "Disable Offer(s)",
  EDIT_USER: "Edit User(s)",
  DISABLE_USER: "Disable User(s)",
  CREATE_ROLE: "Create Role(s)",
  EDIT_ROLE: "Edit Role(s)",
  DELETE_ROLE: "Delete Role(s)",
  CREATE_GROUP: "Create Group(s)",
  EDIT_GROUP: "Edit Group(s)",
  DELETE_GROUP: "Delete Group(s)"
};
interface IRolesListPageProps extends WithStyles {
  roles: IRole[];
  permissions: IPermission[];
  roleMatrix: IRoleMatrix;
  fetchRoles: () => void;
  fetchPermissions: () => void;
  onAddRole: (role: IRole) => void;
  onPermissionChanged: (rolePermission: IRolePermission, add: boolean) => void;
  onSave: () => void;
}

interface IRoleListPageState {
  isEditMode: boolean;
}

interface IChanges {
  [roleId: number]: IRole;
}

const styles = createStyles((theme: Theme) => ({
  formWrapper: {
    // position: "absolute",
    // zIndex: 1000
  },
  formPaper: {
    marginLeft: 2 * theme.spacing.unit,
    width: "50%"
    // position: "absolute",
    // zIndex: 1000
  },
  pageHeader: {
    height: theme.spacing.unit * 20
  },
  paper: {
    padding: 2 * theme.spacing.unit
  },
  root: {}
}));
export class RolesListPage extends React.PureComponent<
  IRolesListPageProps,
  IRoleListPageState
> {
  public state: IRoleListPageState = {
    isEditMode: false
  };

  public componentDidMount = () => {
    this.props.fetchRoles();
    this.props.fetchPermissions();
  };

  public render() {
    const { classes, roles } = this.props;
    const { isEditMode } = this.state;
    return (
      <BB8MainPage type={BB8SystemType.Billing}>
        <BB8MainPage.Padded>
          <Grid
            container={true}
            alignItems="center"
            justify="center"
            spacing={40}
          >
            <Grid
              item={true}
              xs={12}
              container={true}
              alignItems="center"
              className={classes.pageHeader}
            >
              <BB8Button
                className="btn-add-role"
                color="primary"
                variant="contained"
                onClick={this.handleAddNewRoleClick}
                style={{ marginTop: "1em" }}
              >
                Add a new role
              </BB8Button>
              {isEditMode && (
                <Grid
                  item={true}
                  xs={12}
                  sm={12}
                  md={8}
                  className={classes.formWrapper}
                >
                  <Paper className={classes.formPaper}>
                    <RoleForm
                      roles={roles}
                      onSave={this.handleFormSave}
                      onCancel={this.handleFormCancel}
                    />
                  </Paper>
                </Grid>
              )}
            </Grid>
            <Grid item={true} xs={12}>
              <BB8Table
                tableId="table-roles"
                cols={this.getRolesTableColumns()}
                data={sortBy(roles, ["id"])}
              />
            </Grid>
          </Grid>
        </BB8MainPage.Padded>
        <BB8MainPage.Padded />
      </BB8MainPage>
    );
  }

  private handleAddNewRoleClick = () => {
    this.setState({ isEditMode: true });
  };

  private handleFormCancel = () => {
    this.setState({ isEditMode: false });
  };

  private handleFormSave = (name: string) => {
    this.props.onAddRole({ id: -1, name, rolePermissions: [] });
    this.setState({ isEditMode: false });
  };

  private tableCellTemplate = (props: ITemplateCellProps) => {
    const permission =
      props.row &&
      props.row.rolePermissions &&
      props.row.rolePermissions.length &&
      props.row.rolePermissions.find(
        (rolePermission: IRolePermission) =>
          rolePermission.permission.name === props.id
      );
    const isChecked = !!permission;
    return (
      <Checkbox
        color="secondary"
        value={props.id}
        checked={isChecked}
        data-permission-id={permission ? permission.id : null}
        onChange={this.handleRolePermissionCellClick(props.row)}
      />
    );
  };

  private getRolesTableColumns(): IColProps[] {
    const { permissions } = this.props;
    return [
      {
        id: "name",
        title: "Role"
      },
      ...permissions.map(permission => ({
        id: permission.name.toString(),
        tableCellTemplate: this.tableCellTemplate,
        title: actionDescriptions[permission.name]
      }))
    ];
  }

  private handleRolePermissionCellClick = (rowData: any) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value, checked } = e.target;
    const { roles, permissions } = this.props;

    // reference to the permission we're trying to change
    const clickedPermission = permissions.find(
      permission => permission.name === value
    );

    // else it's an existent role being modified
    const changedRole = roles.find(role => role.id === rowData.id);
    if (changedRole && clickedPermission) {
      this.props.onPermissionChanged(
        {
          permission: clickedPermission,
          permissionId: clickedPermission.id,
          roleId: changedRole.id
        } as IRolePermission,
        checked
      );
    }
  };
}

const mapStateToProps = (state: IRootState) => ({
  ...withAuthStateToProps(state),
  permissions: state.administration.permissions,
  roleMatrix: getRoleMatrix(state),
  roles: state.administration.roles
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IRootState, void, Action>
) => ({
  fetchPermissions: () => {
    dispatch(adminActions.fetchPermissionsFlow());
  },
  fetchRoles: () => {
    dispatch(adminActions.fetchRolesFlow());
  },
  onAddRole: (newRole: IRole) => {
    dispatch(adminActions.saveRoleFlow(newRole));
  },
  onPermissionChanged: (
    rolePermission: IRolePermission,
    add: boolean = true
  ) => {
    if (add) {
      dispatch(adminActions.fetchAddPermissionToRoleFlow(rolePermission));
    } else {
      dispatch(adminActions.fetchRemovePermissionFromRoleFlow(rolePermission));
    }
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthorization(withStyles(styles)(RolesListPage), ["CREATE_ROLE"]));
