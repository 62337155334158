import { IPartner } from "../../models/Partner";
import { urls } from "../../shared/constants";
import apiService, { IApiService } from "../../shared/services/api.service";

export class PartnerService {
  private apiService: IApiService;
  constructor(apiServiceDep?: any) {
    this.apiService = apiServiceDep || apiService;
  }

  public getPartners(): Promise<IPartner[]> {
    return this.apiService.ajax<IPartner[]>(urls.partnersController.partners);
  }
}

export default new PartnerService();
