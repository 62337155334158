import React from "react";
import "./BB8NavBar.scss";

function getEnvName(): string {
  let name;
  switch (process.env.REACT_APP_ENV) {
    case "local":
      name = "LOCAL";
      break;
    case "development":
      name = "DEV";
      break;
    case "int":
      name = "INT";
      break;
    case "uat":
      name = "UAT";
      break;
    case "production":
      name = "PROD";
      break;
    default:
      name = "UNKNOWN";
      break;
  }
  return name;
}

const BB8NavBar = (props: any) => (
  <div
    className={
      (process.env.REACT_APP_ENV === "production"
        ? "bb8-nav-bar-wrapper-prod"
        : "") +
      " bb8-nav-bar-wrapper " +
      props.className
    }
  >
    <div className={"bb8-nav-bar"}>
      <div className="bb8-title-wapper">
        <h1 id={props.id}>
          {props.title} [{getEnvName()}]
        </h1>
      </div>
      {props.children}
    </div>
  </div>
);

export default BB8NavBar;
