import { isEqual } from "lodash";
import { IGroup } from "../../../models/Group";
import { IPartner } from "../../../models/Partner";
import { ISponsor } from "../../../models/Sponsor";
import { IRole } from "../../../models/Role";
import { IRbacUser } from "../../../models/User";
import { urls } from "../../../shared/constants";
import apiService, { IApiService } from "../../../shared/services/api.service";
import { IIssuance } from "../../../models/Issuance";

export class UserService {
  private apiService: IApiService;
  constructor(apiServiceDep?: any) {
    this.apiService = apiServiceDep || apiService;
  }

  public getUsers(): Promise<IRbacUser[]> {
    return this.apiService.ajax<IRbacUser[]>(urls.userController.users);
  }

  public getUser(userId: string): Promise<IRbacUser> {
    return this.apiService.ajax<IRbacUser>(
      urls.userController.users + "/" + userId
    );
  }

  public getUserPartners(
    email: string,
    includeGroupPartners = false
  ): Promise<IPartner[]> {
    return this.apiService.ajax<IPartner[]>(
      `${urls.userController.partners(email)}${
        includeGroupPartners ? "?includeGroupPartners=true" : ""
      }`
    );
  }

  public getActiveSponsorCodes(): Promise<ISponsor[]> {
    return this.apiService.ajax<ISponsor[]>(
      `${urls.offersIssuer.activeSponsorCodes}?active=true`
    );
  }
  
  public getIssuanceOfferCodes(sponsorCode: string): Promise<IIssuance[]> {
    return this.apiService.ajax<IIssuance[]>(
      `${urls.offersIssuer.issuanceOfferCodes}?issuerCode=${sponsorCode}`
    );
  }

  public getUserGroups(email: string): Promise<IGroup[]> {
    return this.apiService.ajax<IGroup[]>(urls.userController.groups(email));
  }

  public editUser(user: IRbacUser, role?: IRole): Promise<IRbacUser> {
    const request: Request = new Request(
      urls.userController.users + "/" + user.id,
      {
        ...this.apiService.getDefaultRequestInit(),
        body: JSON.stringify({ ...user, role }),
        method: "put"
      }
    );
    return this.apiService.ajax<IRbacUser>(request);
  }
  public usersComparer(user1: IRbacUser, user2: IRbacUser): boolean {
    return isEqual(user1.id, user2.id);
  }

  public savePartnerToUser(
    user: IRbacUser,
    partner: IPartner
  ): Promise<IPartner[]> {
    const request: Request = new Request(
      urls.userController.partners(user.id),
      {
        ...this.apiService.getDefaultRequestInit(),
        body: JSON.stringify({ partnerId: partner.id }),
        method: "POST"
      }
    );

    return this.apiService.ajax(request);
  }

  public removePartnerFromUser(
    user: IRbacUser,
    partner: IPartner
  ): Promise<IPartner[]> {
    const request: Request = new Request(
      urls.userController.partners(user.id) + "/" + partner.id,
      {
        ...this.apiService.getDefaultRequestInit(),
        method: "DELETE"
      }
    );
    return this.apiService.ajax(request);
  }

  public saveGroupToUser(user: IRbacUser, group: IGroup): Promise<IGroup[]> {
    const request: Request = new Request(urls.userController.groups(user.id), {
      ...this.apiService.getDefaultRequestInit(),
      body: JSON.stringify({ groupId: group.id }),
      method: "POST"
    });

    return this.apiService.ajax(request);
  }

  public removeGroupFromUser(
    user: IRbacUser,
    group: IGroup
  ): Promise<IGroup[]> {
    const request: Request = new Request(
      urls.userController.groups(user.id) + "/" + group.id,
      {
        ...this.apiService.getDefaultRequestInit(),
        method: "DELETE"
      }
    );
    return this.apiService.ajax(request);
  }
}

export default new UserService();
