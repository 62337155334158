import { push } from "connected-react-router";
import { Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { ActionType, createAsyncAction } from "typesafe-actions";
import { IUser } from "../../models/User";
import { IRootState } from "../../store";
import { RoleService } from "../administration/services";
import authService from "../auth/service";
import { Authorizer } from "./components/Authorizer";
import { UserRoles } from "./pages/UserRoles";
import { IPermissionConfig } from "./types";

export const fetchLogin = createAsyncAction(
  "FETCH_LOGIN",
  "FETCH_LOGIN_SUCCESS",
  "FETCH_LOGIN_ERROR"
)<boolean | undefined, IUser, Error>();

export function asyncLogin(userRole?: UserRoles) {
  return async (
    dispatch: ThunkDispatch<IRootState, void, loginActionTypes>
  ): Promise<loginActionTypes> => {
    dispatch(fetchLogin.request(false));
    // make service call here and deal with Promise
    try {
      const user = await authService.login(userRole);
      // (dispatch as Dispatch)(push("/"));
      return dispatch(fetchLogin.success(user));
    } catch (error) {
      return dispatch(fetchLogin.failure(error));
    }
  };
}

export const fetchLogout = createAsyncAction(
  "FETCH_LOGOUT",
  "FETCH_LOGOUT_SUCCESS",
  "FETCH_LOGOUT_ERROR"
)<void, void, Error>();
export function asyncLogout() {
  return async (
    dispatch: ThunkDispatch<IRootState, void, loginActionTypes>
  ): Promise<loginActionTypes> => {
    dispatch(fetchLogout.request());
    // make service call here and deal with Promise
    try {
      await authService.logout();
      return dispatch(fetchLogout.success());
    } catch (error) {
      return dispatch(fetchLogout.failure(error));
    }
  };
}

export const fetchPermissionsConfig = createAsyncAction(
  "FETCH_PERMISSION_CONFIG",
  "FETCH_PERMISSION_CONFIG_SUCCESS",
  "FETCH_PERMISSION_CONFIG_ERROR"
)<void, IPermissionConfig, Error>();

// async action creator
export function asyncPermissionsConfigFlow() {
  return async (
    dispatch: ThunkDispatch<IRootState, void, loginActionTypes>
  ): Promise<loginActionTypes> => {
    dispatch(fetchPermissionsConfig.request());
    // make service call here and deal with Promise
    try {
      const permissions = await RoleService.getPermissionsConfig();
      Authorizer.Instance.init(permissions);
      return dispatch(fetchPermissionsConfig.success(permissions));
    } catch (error) {
      return dispatch(fetchPermissionsConfig.failure(error));
    }
  };
}

export type loginActionTypes = ActionType<
  typeof fetchLogin | typeof fetchLogout | typeof fetchPermissionsConfig
>;
