import { groupBy, uniqWith } from "rambda";
import { toPairs, differenceWith, isEqual, fromPairs } from "lodash";
import moment from "moment-timezone";
/**
 * Checks if an input is empty
 * @param {object} obj - object to be checked against undefined or null
 * @returns true if undefined, null,  "" or {} else false
 */
export function isEmpty(obj: any): boolean {
  if (obj === undefined || obj === null) {
    return true;
  } else if (typeof obj === "string") {
    return obj.trim() === "";
  } else if (typeof obj === "object") {
    if (Array.isArray(obj)) {
      return obj.every(item => isEmpty(item));
    }
    return Object.keys(obj).every(key => isEmpty(obj[key]));
  } else if (typeof obj === "number") {
    return false;
  }
  return obj === undefined;
}

/**
 * Returns an object from a query string
 * @param {string} qs query string to be converted to an object
 * @returns {object|undefined}
 */
export function queryStringToObject<T>(qs: string): T | any | undefined {
  if (!qs) {
    return;
  }
  const kvs = qs
    .replace("?", "")
    .split("&")
    .map(kv => kv.split("="));

  const result: any = Object.create(null);
  kvs.forEach(kv => {
    const decodedParam = decodeURI(kv[1]).split(",");

    result[kv[0]] =
      decodedParam.length > 1 ? new Set(decodedParam) : decodeURI(kv[1]);
  });

  return result;
}

/**
 * Returns an query string, takes an object as input
 * @param {object} obj - An object to be converted to query string
 * @returns {string|undefined} string or undefined
 */
export function objectToQueryString(obj: any): string | undefined {
  if (!obj) {
    return;
  }
  return Object.keys(obj)
    .map(k =>
      obj[k] !== undefined && obj[k] !== null && obj[k] !== ""
        ? `${k}=${obj[k]}`
        : ""
    )
    .filter(i => !!i)
    .join("&");
}

/**
 * Groups an array of objects into a object
 * @param {string} key object key to be used as grouping parameter
 * @param {Array<any>} array array of objects to be grouped by the key
 * @param {(any)=>any} transform optional - this function will transform each object being added to a group
 */
export function groupByValueInKey<T, K extends keyof T>(
  key: K,
  array: T[]
  // transform?: (obj: any) => any
) {
  return groupBy<T>(obj => (obj[key] || "null").toString(), array);
  const result: any = {};

  // if (array && array.length) {
  //   const id = array[0][key];
  //   if (transform) {
  //     result[id] = array.map(d => transform(d));
  //   } else {
  //     result[id] = array;
  //   }
  // }

  // return result;
}

/**
 * Combines two arrays resulting in an array with elements present
 * exclusively in arr1 and arr2
 * @param {Array<any>} arr1
 * @param {Array<any>} arr2
 */
export const diffArray = (arr1: any[], arr2: any[]) => {
  if (!arr1 && !arr2) {
    return [];
  }
  return arr1
    .filter(el => !arr2.includes(el))
    .concat(arr2.filter(el => !arr1.includes(el)));
};

/**
 * Takes an array of objects and tries to find the given object in the array
 * using a matcher function. If the object is found, the object in the array
 * is updated with the given object
 * @param array1 Array of objects
 * @param obj Object to be updated / merged
 * @param matcher Should return true if item1 and item2 are the same, else false
 */
export function updateObjectInArray<T>(
  array1: T[],
  obj: T,
  matcher: (item1: T, item2: T) => boolean
): T[] {
  return array1.map(item => {
    if (!matcher(item, obj)) {
      // This isn't the item we care about - keep it as-is
      return item;
    }
    // Otherwise, this is the one we want - return an updated value
    return {
      ...(item as any),
      ...(obj as any)
    };
  });
}

/**
 * Counts distinct values in an array of objects for an given object key
 * @example
 * // returns 1
 * const arr = [
 *      { id: 1, text: "item 1" },
 *      { id: 1, text: "item 2" },
 *      { id: 1, text: "item 3" }
 *    ];
 *
 * return countDistinctBy(arr, "id");
 * @param {Array<any>} a Array of objects
 * @param {string} key keyof object in the array
 */
export function countDistinctBy<T = any>(
  a: T[],
  key: string extends keyof T ? any : any
): number {
  if (!a || !a.length) {
    return 0;
  }
  let result = 0;
  const temp = new Set();
  for (const obj of a) {
    if (obj && (obj as any)[key]) {
      const val = (obj as any)[key];
      if (!temp.has(val)) {
        result += 1;
        temp.add(val);
      }
    }
  }
  return result;
}

/**
 * Takes an arrray of objects and returns unique values given a predicate function
 * If not predicate function is given, uses strict object comparision (===)
 * @param a Array of objects
 * @param pred Function to be used for uniqueness comparision
 */
export function distinct<T = object>(
  a: T[],
  pred: (x: T, y: T) => boolean = (x, y) => x === y
): T[] {
  if (!a || !a.length) {
    return a;
  }
  return uniqWith(pred, a);
}

/**
 * Takes a function and debounces calls to this function, waiting given milliseconds
 * to call the function
 * @param func Function to be debounced
 * @param wait Time for wait in millisseconds
 */
export function debounce(func: any, wait: number): any {
  let timeout: any;
  return function(this: any, ...args: any[]) {
    const context = this;
    const functionCall = () => func.apply(context, args);
    clearTimeout(timeout);
    timeout = setTimeout(functionCall, wait || 200);
  };
}

// TODO: add ftp ?
export function onlyNonEmptyValidURL(url: string) {
  return (
    !isEmpty(url) &&
    /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.\\]+$/.test(
      url
    )
  );
}

export function toCheckedSet(
  obj: Set<string> | string | undefined
): Set<string> | undefined {
  return typeof obj === "string" ? new Set().add(obj) : obj;
}

export function toCommaSeparatedString(
  obj: Set<string> | string | undefined
): string | undefined {
  if (typeof obj === "string") {
    return obj;
  } else {
    return obj ? Array.from(obj).join(",") : undefined;
  }
}

/**
 * We need to randomize this to force cache invalidations by adding a random guid to image URLs
 * so that HTML2Canvas will fetch a new version when it does the export to canvas.
 * removing this will cause the export to pdf functionality to break for images.
 * This function is also generating random key strings for the key prop of stored components.
 * This will retrigger componentDidMount()
 */
export function generateRandomGUIDForCacheInvalidation() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return (
    "?id=" +
    s4() +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    s4() +
    s4()
  );
}

export function getDiffOfObjects(obj1: any, obj2: any): any {
  const before = toPairs(obj1);
  const after = toPairs(obj2);
  const diff = differenceWith(after, before, isEqual);
  return fromPairs(diff);
}

export function to(promise: Promise<any>) {
  return promise
    .then((data: any) => {
      return [null, data];
    })
    .catch(err => [err]);
}

export function getHumanReadableTimeFromNow(
  timestamp: string | Date,
  lang?: string
) {
  const momentInstance = moment(timestamp).locale(lang || "en");

  return momentInstance.diff(timestamp, "days") < 7
    ? momentInstance.fromNow()
    : momentInstance.format("MM/DD/YYYY");
}
