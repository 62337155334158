import MomentUtils from "@date-io/moment";
import {
  CircularProgress,
  CssBaseline,
  Grid,
  MuiThemeProvider
} from "@material-ui/core";
import { BB8Alert, BB8Spinner } from "bb8";
import { BB8SpinnerSize } from "bb8/lib/components/BB8PageSpinner";
import { ConnectedRouter } from "connected-react-router";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import React, { Suspense, lazy } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, NavLink, Switch } from "react-router-dom";
import { Dispatch } from "redux";
import "./App.scss";
import BB8NavBar from "./components/BB8NavBar";
import ConnectedTopNav from "./components/ConnectedTopNav";
import { Authorizer } from "./features/auth/components/Authorizer";
import { IAuthState } from "./features/auth/types";
import Routes from "./Routes";
import { history } from "./setupRedux";
import {
  IRootState,
  IWithAuthDispatchToProps,
  IWithCommunicationProps,
  withAuthDispatchToProps,
  withAuthStateToProps,
  withCommunicationDispatchToProps,
  withCommunicationStateToProps
} from "./store";
import theme from "./theme";
import AlertContainer, {
  SnackbarContext
} from "./features/communication/AlertContainer";
import TargetAlertContainer from "./features/communication/TargetAlertContainer";

const UnsupportedBrowser = lazy(() =>
  import("./features/auth/pages/UnsupportedBrowser")
);

function PageProgressSpinner() {
  return (
    <Grid
      container={true}
      alignItems="center"
      justify="center"
      style={{ height: "100vh" }}
    >
      <CircularProgress size={200} />
    </Grid>
  );
}

const App: React.FunctionComponent<
  IWithCommunicationProps & IAuthState & IWithAuthDispatchToProps
> = ({ isLoading, isLoggedIn, user }) => {
  const isChrome = !!(window as any).chrome;

  if (!isChrome) {
    return (
      <AlertContainer>
        <Suspense fallback={<PageProgressSpinner />}>
          <UnsupportedBrowser />
        </Suspense>
      </AlertContainer>
    );
  }

  return (
    <ConnectedRouter history={history}>
      <BB8NavBar id="navbar__top" title="Offer Submission Tool" />

      <ConnectedTopNav
        showAuth={true}
        showHeader={Authorizer.Instance.isAssignedToRole(["UNASSIGNED"], user)}
      >
        {isLoggedIn && (
          <NavLink to="/offer-submission">Offer Submission</NavLink>
        )}

        {isLoggedIn &&
          Authorizer.Instance.checkPermissions(
            [
              "EDIT_USER",
              "DISABLE_USER",
              "CREATE_ROLE",
              "EDIT_ROLE",
              "DELETE_ROLE",
              "CREATE_GROUP",
              "EDIT_GROUP",
              "DELETE_GROUP"
            ],
            user
          ) && <NavLink to="/user-management">Administration</NavLink>}
      </ConnectedTopNav>
      <AlertContainer>
        <Suspense fallback={<PageProgressSpinner />}>
          <Switch children={Routes} />
        </Suspense>
      </AlertContainer>
      <BB8Spinner size={BB8SpinnerSize.Big} show={isLoading} />
    </ConnectedRouter>
  );
};

const mapStateToProps = (state: IRootState) => ({
  ...withAuthStateToProps(state),
  ...withCommunicationStateToProps(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...withAuthDispatchToProps(dispatch),
  ...withCommunicationDispatchToProps(dispatch)
});
const ConnectedApp = connect(
  mapStateToProps,
  mapDispatchToProps
)(App);

// TODO: define state management library
const AppContainer: React.FunctionComponent = () => (
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <ConnectedApp />
    </MuiPickersUtilsProvider>
    <AlertContainer />
    <TargetAlertContainer />
  </MuiThemeProvider>
);
export default AppContainer;
